@font-face {
    font-family: 'Athelas';
    src: url('../fonts/Athelas-Bold.woff2') format('woff2'),
        url('../fonts/Athelas-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Athelas';
    src: url('../fonts/Athelas-Regular.woff2') format('woff2'),
        url('../fonts/Athelas-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: url('../fonts/Lato-Light.woff2') format('woff2'),
        url('../fonts/Lato-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Lato';
    src: url('../fonts/Lato-Regular.woff2') format('woff2'),
        url('../fonts/Lato-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Lato';
    src: url('../fonts/Lato-Semibold.woff2') format('woff2'),
        url('../fonts/Lato-Semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'Lato';
    src: url('../fonts/Lato-Bold.woff2') format('woff2'),
        url('../fonts/Lato-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'Lato';
    src: url('../fonts/Lato-Heavy.woff2') format('woff2'),
        url('../fonts/Lato-Heavy.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}
@font-face {
    font-family: 'latoblack';
    src: url('../fonts/lato-black-webfont.woff2') format('woff2'),
         url('../fonts/lato-black-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td {border: 0;font-family: inherit;font-size: 100%;font-style: inherit;font-weight: inherit;margin: 0;outline: 0;padding: 0;vertical-align: baseline;}

html {
    -webkit-text-size-adjust: none;
    margin-right: 0 !important;
        -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;    
}
:focus {
    outline: 0;
}
ol,
ul {
    margin: 0; padding: 0 0 10px 30px;
}
table {
    border-collapse: separate;
    border-spacing: 0;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

embed,
iframe,
object {
    max-width: 100%;
}

body {
    color: #000; word-wrap: break-word; font-size: 16px; line-height: 1.3;overflow-x: hidden; 
    background:#fff;
    font-family: 'Lato';  font-weight: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
cite,
em,
i {
    font-style: italic;
}

input,
textarea {
    -webkit-border-radius: 0px;
}

input[type="text"],
input[type="email"],
input[type="search"],
input[type="password"],
textarea {
   -moz-appearance: none !important; -webkit-appearance: none !important; appearance: none !important;
}
input[type="text"],
input[type="email"],
input[type="password"],
input[type="tel"],
textarea,
select {
    padding: 12px 10px;
    border: 1px solid #cbcbcb;
    width: 100%;
    margin: 0 0 10px;
    background-color: #fff;
    border-radius: 4px;
    height: 48px;
    font-size: 15px;
    color: #555555;
    font-family: 'Lato'; 
}

input[type="reset"],
input[type="button"],
input[type="submit"],
.button,
button {
    color: #080808  ;
    border-radius: 4px;
    margin: 15px 0px 10px 0px;
    padding: 14px 20px 14px 20px;
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    appearance: none !important;
    cursor: pointer;
    background: #fff;
    border: 2px solid #523628;
    font-size: 16px;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    text-transform: uppercase;
    line-height: 1;
    text-align: center;
    overflow: hidden;
    position: relative;
    z-index: 2;
    font-family: 'Lato';
    font-weight: bold;
    display: inline-block;
    letter-spacing: 3px;
}

input[type="reset"]:hover,
input[type="button"]:hover,
input[type="submit"]:hover,
.button:hover,
button:hover {
    color: #fff;
    background: #523628;	
    border-color: #523628;
}
input[type="reset"]:active,
input[type="button"]:active,
input[type="submit"]:active {
    -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.3);
}
a {color: #523628; text-decoration: none; cursor: pointer;}
a:focus,
a:active,
a:hover {text-decoration: none;}
a:hover {color: #000;}
figure {margin: 0; text-align: center;}
img {max-width: 100%; height: auto; vertical-align: top;}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0px 0px 25px 0px;
    padding: 0px;
    font-weight: normal;
    line-height: 1.1; 
    font-family: 'Lato';
    font-weight: bold;
}

h1 {font-size: 41px;}
h2 {font-size: 35px;}
h3 {font-size: 30px;}
h4 {font-size: 26px;}
h5 {font-size: 21px;}
h6 {font-size: 18px;}

.show {display: block;}
.hidden {display: none;}
.invisible {visibility: hidden;}
.fl {float: left}
.fr {float: right}
.rel {position: relative}
.textcenter {text-align: center}
.textleft {text-align: left}
.textright {text-align: right}

*,
*:before,
*:after {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
}
.flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}
.clear,
.clearfix {
    clear: both;
}
.clearfix:after {
    clear: both;
    display: table;
    content: ""
}
strong, b {
    font-weight: 700;
}
.required_star {color: #f00;}

.container {
    max-width: 1340px; margin: 0 auto; padding-left: 20px; padding-right: 20px; width: 100%;
}
.slick-slider,
.slick-slider * {
    outline: none;
}
p {margin: 0 0 15px;}


table {width: 100%; max-width: 100%; margin-bottom: 20px;}
.table_overflow {overflow-x: auto; overflow-y: hidden; margin-bottom: 20px;}
.table_overflow .table {margin-bottom: 0;}
.dropdown-menu>li>a {padding: 7px 20px; border-top: 1px solid #e9ebf5;}
.dropdown-menu>li:first-child>a {border-top: 0;}
.form_grouptt {font-size: 16px; margin: 0 0 20px 2px; color: #000; text-transform: capitalize;}
.form-group {margin: 0 0 8px;}
.form_sec .row {margin-left: -4px; margin-right: -4px;}
.form_sec [class*="col-"] {padding: 0 4px;}
label {font-size: 15px; color: #333;}
label span {color: #f00; margin-left: 3px;}
.manitatory-select .Select-placeholder:after {
    content: '*'; color: red;
}
.form-control {
    height: 48px;
    border: 1px solid #cbcbcb;
    box-shadow: none;
    -webkit-box-shadow: none;
    padding: 6px 18px;
    background: #fff;
    font-size: 15px;
    border-radius: 0;
    color: #615f5f;
    width: 100%;
    display: block
}
textarea.form-control {
    height: 160px;
}
textarea.form-control.sm {
    height: 90px;
}
.form-control.error,
.Select-control.error {
    border-color: #FFBABA;
    color: #686a78;
}

.form-control:focus {
    box-shadow: none;
    -webkit-box-shadow: none;
    border-color: #000000;
}
.focus-out {
    position: relative;
}
.focus-out label {
    position: absolute;
    left: 18px;
    top: 14px;
    pointer-events: none;
    -webkit-transition: all 400ms;
    transition: all 400ms;
    z-index: 3;
    height: 16px;
    overflow: hidden;
    color: #333;
}

.focused label {
    padding: 0 6px;
    font-size: 11px;
    background: #fff;
    top: -7px;
    height: auto;
}

.clock_icon,
.ileft_ico {
    position: absolute;
    left: 0;
    top: 0;
    width: 40px;
    height: 100%;
}

.clock_icon img,
.ileft_ico img {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    display: block;
}

.profile-info-div {
    margin: 35px 0;
}

.other-add {
    margin: 30px 0 0 0
}

.acc-inform .form-group .row:after,
.filter_tabsec .nav-tabs:after {
    display: table;
    clear: both;
    content: ""
}

.acc-inform .form-group > .row {
    margin-right: -5px;
    margin-left: -5px;
}
.acc-inform .form-group > .row > .col-md-6 {padding-right: 5px; padding-left: 5px;}
/*
.acc-inform .form-group .row .col-md-6 {width: 49%; float: left; margin: 0 2% 0 0;}
.acc-inform .form-group .row .col-md-6:last-of-type {margin-right: 0;}
*/
.re_select input[type="text"] {
    margin: 0;
    height: 34px;
}
.acc-inform .form-group .row .button {
    width: 100%;
    display: block;
    margin: 0;
    height: 48px;
}
.re_select .Select-control {
    border: 1px solid #e2e2e2;
    border-radius: 0;
    height: 47px;
    outline: 0;
}
.re_select .Select-placeholder,
.re_select .Select--single > .Select-control .Select-value {
    padding: 0 22px;
    line-height: 45px;
    text-align: left;
    color: #72624f;
}

.re_select .Select-input {
    padding: 0 20px;
    height: 45px;
}

.re_select .Select-input > input {
    line-height: 27px;
    padding: 10px 0;
}

.re_select .Select.is-open > .Select-control {
    border-color: #d5d7e0;
}

.re_select .Select-arrow {
    background: url(../images/arrow-down.png) no-repeat center;
    border: 0;
    width: 18px;
    height: 10px;
}

.re_select .is-open .Select-arrow {
    background-image: url(../images/arrow-up.png);
}

.re_select .Select-menu-outer {
    z-index: 4;
    text-align: left;
}

.re_select .Select-menu-outer {
    background: #ecdfcc;
}

.re_select .Select-arrow-zone {
    padding: 0;
    width: 42px;
}

.re_select .Select--multi .Select-value {
    background-color: #ecdfcc;
    border-color: #dcd8d8;
    color: #181818;
    position: relative;
    padding-right: 19px;
    border-radius: 10px;
    overflow: hidden;
}

.re_select .Select--multi .Select-value-icon {
    border-color: #dcd8d8;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
}

.re_select .Select--multi .Select-value-icon:hover,
.re_select .Select--multi .Select-value-icon:focus {
    background-color: #1c1c1c;
    border-color: #1c1c1c;
    color: #fff;
}

.re_select .Select--multi .Select-value-icon:before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    height: 100%;
    width: 0;
    font-size: 0;
}

.re_select .Select--multi .Select-value-label {
    padding: 2px 5px 2px 9px;
}

.custom_select {
    position: relative;
}

.custom_select:after {
    content: "";
    background: #fff url(../images/arrow-down.png) no-repeat center;
    width: 45px;
    top: 1px;
    right: 1px;
    position: absolute;
    pointer-events: none;
    margin: auto;
    height: 46px;
}

.react-datepicker {
    font-family: 'Calibri' !important;
}

.react_datepicker .react-datepicker-wrapper,
.react_datepicker .react-datepicker__input-container {
    display: block;
}

.react_datepicker .react-datepicker {
    font-size: 1rem;
}

.react_datepicker .react-datepicker__current-month,
.react_datepicker .react-datepicker-time__header {
    font-size: 1.2rem;
    font-weight: bold
}

.react_datepicker .react-datepicker__day-name,
.react_datepicker .react-datepicker__day,
.react_datepicker .react-datepicker__time-name {
    width: 2.7rem;
}

.react_datepicker .react-datepicker__time-container {
    width: 120px;
}

.react_datepicker .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
    padding: 0;
}

.react_datepicker .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
    width: 120px;
}
.react-datepicker__time-container{ width: 110px !important}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box{width: 110px  !important}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected {
    background-color: #523628 !important
}

.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover {
    background-color: #523628 !important;
}

.react-datepicker__header {
    background-color: #e4e4e4 !important;
}

.react-datepicker__navigation {
    margin: 0;
    box-shadow: none;
    min-width: inherit;
}

.react-datepicker__navigation--previous {
    border-right-color: #000 !important;
}

.react-datepicker__navigation--next {
    border-left-color: #000 !important;
}

.react-datepicker__navigation--previous:hover,
.react-datepicker__navigation--next:hover {
    background: none;     border-color: #e4e4e4;
}

.react-datepicker__month-dropdown-container--select select,
.react-datepicker__year-dropdown-container--select select {
    height: 36px;
    padding: 5px 10px;
}
.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range{ border-radius: 50% !important;background-color: #523628 !important;  color: #fff !important;}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {background-color: #523628 !important; color: #fff !important;}


.signup-popup .custom_checkbox{ display: flex; justify-content: center; align-items: center; margin: 20px 0 0 0}




.checkmark {
    position: absolute;
    top: -12px;
    left: 0;
    height: 28px;
    width: 28px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    border: 1px solid #ecdfcc;
}

.ckbox_custmze:hover input ~ .checkmark {
    background-color: #fff;
}

.ckbox_custmze input:checked ~ .checkmark {
    background-color: #72624f;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.ckbox_custmze input:checked ~ .checkmark:after {
    display: block;
}

.ckbox_custmze .checkmark:after {
    left: 10px;
    top: 5px;
    width: 8px;
    height: 14px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.error {
    color: #f00;
    font-weight: normal;
}

span.error {
    background: #FFBABA;
    color: #D8000C;
    padding: 5px 10px;
    font-size: 11px;
    position: relative;
    display: inline-block;
    font-weight: normal;
    z-index: 4;
    line-height: 1.1;
}

span.error:before {
    content: "";
    border-bottom: 5px solid #ffbaba;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: -5px;
    width: 0;
}

.alert {
    padding: 10px 15px;
    border-radius: 0;
    -webkit-border-radius: 0;
    text-align: left;
    display: none;
    -webkit-transition: all 400ms;
    transition: all 400ms;
}

.alert_fixed {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 999;
    max-width: 1260px;
    margin: 0 auto;
    padding: 10px;
    line-height: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 13px;
    align-items: center;
}

.alert_success {
    color: #3c763d;
    background-color: #dff0d8;
    border-color: #d6e9c6;
}

.alert_danger {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
}

.alert_fixed p {
    margin: 0;
}

.alert_fixed a {
    font-size: 24px;
    line-height: 0;
    margin-right: 5px;
}

.display_inblock {
    display: inline-block;
}

.display_block {
    display: block;
}

.relative {
    position: relative;
}

.vtop {
    vertical-align: top;
}

.vmiddle {
    vertical-align: middle;
}

.vbottom {
    vertical-align: bottom;
}

.vmid_sec:before {
    content: "";
    height: 100%;
    display: inline-block;
    vertical-align: middle;
    font-size: 0;
}

.vmid_in {
    display: inline-block;
    vertical-align: middle;
    width: 98%;
}

.tab_mobtrigger {
    display: none;
}

.main_tabsec {margin: 0px 0px;}
.main_tabsec > .nav-tabs {
    border: 0;
    display: table;
    width: 100%;
    table-layout: fixed;
    border-radius: 50px;
    margin: 0 0 20px;
}
.main_tabsec > .nav-tabs>li {
    margin: 0;
    display: table-cell;
    float: none;
    text-align: center;
    width: 50%;
    vertical-align: middle;
    border-left: 2px solid transparent;
}
.main_tabsec > .nav-tabs>li:first-child {
    border-left: 0;
}
.main_tabsec > .nav-tabs>li>a {
    background: #b69a6b;
    margin: 0;
    border-radius: 0;
    border: 0;
    color: #fff;
    font-size: 16px;
    font-weight: normal;
    text-transform: uppercase;
    padding: 17.5px 15px;
}
.main_tabsec > .nav>li>a:focus {background: none;}
.main_tabsec > .nav-tabs>li.active>a,
.main_tabsec .nav-tabs>li.active>a:focus,
.main_tabsec .nav-tabs>li>a:hover {border: 0; background: #0b0405; color: #fff;}
.main_tabsec > .nav-tabs>li>a:after {
    content: "";
    border: 18px solid #0b0405;
    border-color: #0b0405 transparent transparent transparent;
    position: absolute;
    left: 50%;
    bottom: -36px;
    margin-left: -18px;
    opacity: 0;
    pointer-events: none;
    -webkit-transition: all 400ms;
    transition: all 400ms;
}
.main_tabsec .nav-tabs>li.active>a:after {
    opacity: 1;
}
.main_tabin {border-radius: 4px;}
.filter_tabsec {margin: 0 0 20px;}
.filter_tabsec .nav-tabs {margin: 0 0 30px;}
.filter_tabsec .nav-tabs>li {
    width: 17%;
    margin: 0;
    display: inline-block;
    float: none;
    text-align: center;
    vertical-align: middle;
}
.filter_tabsec .nav-tabs>li:first-child {
    border-left: 0;
}
.filter_tabsec .nav-tabs>li>a {
    margin: 0;
    border-radius: 0;
    border: 0;
    color: #000;
    font-size: 16px;
    font-weight: 900;
    text-transform: uppercase;
    padding: 0 0 5px;
    display: block; 
}
.filter_tabsec .nav>li>a:focus {background: none;}
.filter_tabsec .nav-tabs>li:hover>a,
.filter_tabsec .nav-tabs>li.active>a,
.filter_tabsec .nav-tabs>li.active>a:focus,
.filter_tabsec .nav-tabs>li.active>a:hover {
    border: 0; background: none; color: #000;
}
.filter_tabsec .nav-tabs>li>a:after {
    content: "";
    background: #523628;
    position: absolute;
    left: 0;
    right: 0;
    opacity: 0;
    pointer-events: none;
    -webkit-transition: all 400ms;
    transition: all 400ms;
}
.filter_tabsec .nav-tabs>li:hover>a:after,
.filter_tabsec .nav-tabs>li.active>a:after {
    opacity: 1;
}

.modal-dialog {margin: .5rem;}
.modal-dialog-centered {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    min-height: calc(100% - (.5rem * 2));
    min-height: -webkit-calc(100% - (.5rem * 2));
}
.modal-content {
    width: 100%;
    box-shadow: none;
    -webkit-box-shadow: none;
    border-radius: 14px;
    border:1px solid #b79a6c
}
.poup_wrap {width: 660px;}
.popup_in {padding: 28px 60px 54px;}
.close_popup {
    font-size: 38px;
    font-weight: normal;
    line-height: 32px;
    position: absolute;
    right: 10px;
    top: 10px;
    height: 32px;
    width: 32px;
    color: #231f20;
    text-align: center;
}

.chosen-container .chosen-results .highlighted {
    background: #ecdfcc !important;
}

button.mfp-close:hover,
button.mfp-arrow:hover {
    background: none;
}

.tgl {
    display: none;
}

.tgl,
.tgl:after,
.tgl:before,
.tgl *,
.tgl *:after,
.tgl *:before,
.tgl + .tgl-btn {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.tgl::-moz-selection,
.tgl:after::-moz-selection,
.tgl:before::-moz-selection,
.tgl *::-moz-selection,
.tgl *:after::-moz-selection,
.tgl *:before::-moz-selection,
.tgl + .tgl-btn::-moz-selection {
    background: none;
}

.tgl::-moz-selection,
.tgl:after::-moz-selection,
.tgl:before::-moz-selection,
.tgl *::-moz-selection,
.tgl *:after::-moz-selection,
.tgl *:before::-moz-selection,
.tgl + .tgl-btn::-moz-selection {
    background: none;
}

.tgl::selection,
.tgl:after::selection,
.tgl:before::selection,
.tgl *::selection,
.tgl *:after::selection,
.tgl *:before::selection,
.tgl + .tgl-btn::selection {
    background: none;
}

.tgl + .tgl-btn {
    outline: 0;
    display: block;
    width: 45px;
    height: 22px;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.tgl + .tgl-btn:after,
.tgl + .tgl-btn:before {
    position: relative;
    display: block;
    content: "";
    width: 50%;
    height: 100%;
}

.tgl + .tgl-btn:after {
    left: 1px;
}

.tgl + .tgl-btn:before {
    display: none;
}

.tgl:checked + .tgl-btn:after {
    left: 50%;
}

ul.tg-list {
    float: left;
    padding-top: 7px;
    list-style: none;
    padding-left: 0;
}

.tgl-light + .tgl-btn {
    background: #f9f9f9;
    padding: 0;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
    border-radius: 2em;
}

.tgl-light + .tgl-btn:after {
    border-radius: 50%;
    background: #b4b4b4;
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
    width: 22px;
    height: 22px;
}

.tgl-light:checked + .tgl-btn:after {
    background: #4dc93c;
}

.desktop_hmenu_list .submenu-arow {
    display: none
}

.mobile-menu-body .submenu-arow {
    display: block;
    width: 40px;
    background: none;
    position: absolute;
    right: 0;
    top: 0;
    height: 35px;
    color: #FFFFFF !important;
}

.mobile-menu-body {
    height: 100%;
    overflow: auto;
}
.submenu-arow:after {
    content: "\f107";
    font-family: FontAwesome;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
    top: 50%;
    line-height: 1;
    margin-top: -11px;
    font-size: 22px;
    -webkit-transition: 0.3s linear all;
    transition: 0.3s linear all;
    display: block !important;
}

.open-submenu .submenu-arow:after {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    display: block !important;
}

.hsearch_sec, .hsearch_sec_mbl {
    width: 380px;
    position: absolute;
    right: -20px;
    top: 55px;
    opacity: 0;
    pointer-events: none;
    -webkit-transition: all 400ms;
    transition: all 400ms;
    z-index: 3;
    visibility: hidden;
}

.hsearch_sec.open, .hsearch_sec_mbl.open {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
}

.hmenu_sec ul {
    padding: 0;
    list-style: none;
    text-align: center;
}

.hmenu_list {
    margin: 0;
}

.hmenu_list > li {
    display: inline-block;
    position: relative
}

.hmenu_list > li > a > span {
    position: relative;
}

.hmenu_actions > li {
    list-style: none;
    position: relative;
}

.hordertype_btn {
    padding: 14px 35px 14px 15px;
    color: #fff;
    background: #523628;
    text-transform: uppercase;
    font-family: 'Lato';
    font-size: 15px;
    position: relative;
    margin: 0 20px 0 0px;
    line-height: 1;
    font-weight: bold;
    text-align: center;
    display: flex; justify-content: center; align-items: center; 
}
.hordertype_btn:after{ 
  position: absolute;
    top: 10px;
    right: 15px;
    font-family: FontAwesome;
    content: "\f107";
    font-weight: normal;
    font-size: 20px;
}

.hordertype_btn:hover {
    color: #fff;
    background: #000;
}

.hmenu_sec .desktop_hmenu_list ul.submenu_list {
    margin: 0;
    padding: 0px;
    background: #fff;
    z-index: 99;
    width: 220px;
    position: absolute;
    left: 0px;
    top: 33px;
    display: none;
}

.hmenu_list.desktop_hmenu_list > li:hover > ul,
.hmenu_list.desktop_hmenu_list > li ul li:hover>ul {
    display: block;
}

.hmenu_sec .desktop_hmenu_list ul.submenu_list li a {
    
    font-size: 16px;
    color: #1b0000;
    text-transform: uppercase;
    padding: 10px;
    display: block;
    border-bottom: 1px solid rgba(0, 0, 0, 0.078)
}

.hmenu_sec .desktop_hmenu_list ul.submenu_list li a:hover {
    color: #ab2225;
}

.hmenu_sec .mobile_hmenu_list ul.submenu_list {
    display: none;
    background: #f7f7f7
}
.mobile-menu-body ul.submenu_list {
    display: none;
    background: #060606;
}
.mobile-menu-body ul.submenu_list li {
    padding: 0;
    list-style: none;
}
.mobile-menu-body ul.submenu_list li a {
    border-bottom: 0;
    color: #E4E4E4 !important;
    font-size: 14px  !important;
    text-transform: inherit;
    display: block;
    padding: 10px 15px;
}
.mobile-menu-body ul.submenu_list li a:hover {
    color: #ffa700;
}

.hsign_sec > a {
    display: inline-block;
    position: relative;
}
.hsign_sec > a.active{ font-weight: bold}

.hsearch_sec .search_result, .hsearch_sec_mbl .search_result_mbl, .product_search_result {
    margin: 0;
    padding: 0;
    list-style: none;
    background: #fff;
    max-height: 300px;
    overflow-y: auto;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    display: none;
}
.product_search_result{position: absolute; top: 100%; left: 0;z-index: 5;width: 100%;}

.search_result li, .search_result_mbl li, .product_search_result li {
    border-bottom: 1px solid rgba(0, 0, 0, 0.090);
}

.search_result li a,
.no-pro-found, .search_result_mbl li a, .product_search_result li a {
    color: #333;
    display: block;
    padding: 9px 15px
}
.search_result li a:hover, .search_result_mbl li a:hover, .product_search_result li a:hover {  background: #523628;    color: #fff;}
.hsearch_sec.open .input-sec, .hsearch_sec_mbl.open .input-sec { position: relative}
.hsearch_sec.open .input-sec input, .hsearch_sec_mbl.open .input-sec input  {
    padding-right: 40px;
    margin-bottom: 0;
    margin-bottom: 0;
    border-color: rgba(0, 0, 0, 0.3);;
}
.search_text_clear {
    position: absolute; right: 10px; top: 11px; display: none
}
.htico_search {position: relative;top: 3px}
.htico_search>a, .htico_search_mbl>a {
    position: relative; display: block;
}
.htico_search>a>img {width: 23px; height: 23px; display: block;}
.htico_cart {position: relative; }

.htico_cart > a {position: relative; display: block;padding-right: 25px;color: #525252;}
.hcartdd_trigger.active:before {opacity: 1; visibility: visible;}
body.cart-items-open .logo-main-section:before {
    position: fixed; content: ""; top: 0; bottom: 0; left: 0; right: 0; background: rgba(0, 0, 0, 0.68); z-index: 9;
}
.cart_info {display: inline-block; vertical-align: top; color: #3d3d3d;}
.cart_info h4 {font-size: 14px; margin: 0 0 5px; }
.cart_left .help-block {font-style: italic; color: #a7a4a4; margin: 5px 0 0; font-size: 14px;}

.cart_extrainfo {padding-left: 10px}
.cart_extrainfo p, .notes-block {margin: 0; font-size: 14px; color: #3d3d3d;}
.cart_extrainfo p{ font-weight: 300;}
.cart_price {position: relative; margin: 0; padding-right: 30px; margin-bottom: 10px;}
.cart_price p { margin: 1px 0 0 0;  font-size: 17px; font-weight: bold;line-height: 1; }
.redeemd-product-zero .cart_price p {
    visibility: hidden;
}
.cart_remove { background: #999; position: absolute; top: 15px; right: 12px; width: 18px; height: 18px; border-radius: 4px; }
.cart_remove:before{ background: url(../images/cross1.png) no-repeat; width: 10px; height: 10px; content:""; position: absolute; left: 0; right: 0; top: 0; bottom: 0; margin: auto;background-size: contain;}
.cart_remove:hover {background-color: #000;}
.cart_remove img{width: 8px;}

.qty_bx {  width: 128px; height: 38px; position: relative; padding: 0 42px; display: inline-block; }
.qty_bx span {
    position: absolute; width: 38px; height: 100%; display: block; top: 0%; bottom: 0; text-indent: 9999px;
    overflow: hidden; cursor: pointer; margin: auto; background: #fff; -webkit-transition: 0.3s linear all;
    transition: 0.3s linear all; border-radius:4px;border: 2px solid #000000;
}
.qty_bx span.qty_minus {left: 0px;}
.qty_bx span.qty_plus {right: 0px; }
.qty_bx input {
    height: 100%;
    border: none;
    color: #000;
    font-size: 16px;
    text-align: center;
    margin: 0;
    padding: 2px;
    background: #fff;
    border-radius: 0px;
    font-weight: bold;
    border-right: 0px solid transparent;
    border-left: 0px solid transparent;
}
.qty_bx span:after, .qty_bx span.qty_plus:before {
    position: absolute;
    width: 14px;
    height: 14px;
    margin:auto;
    content: "";
    display: block;
    top: 0;
    bottom: 0;
    background: #000;
    left: 0;
    right: 0;
}
.qty_bx span:hover{ background: #000; }
.qty_bx span:hover:after, .qty_bx span:hover:before{ background: #fff; }

.qty_bx span.qty_minus:after {
    height: 2px;
}
.qty_bx span.qty_plus:after {
    height: 2px
}
.qty_bx span.qty_plus:before {
    width: 2px;
}

.cart_footer { padding: 20px 15px; background: #f7f7f7;}
.cart_footer .cart_row { margin-bottom: 5px;position: relative}
.cart_footer .cart_row p, .cart_footer span{margin: 0; font-size:18px; text-align: center;}

.discount_info {margin-left: 15px;}
.discount_info p {margin: 0; font-size: 14px; font-weight: normal; color: #3d3d3d;}
.promo_delico_parent {position: relative;}
.promo_delico {
    position: absolute;
    top: 0;
    left: -21px;
    width: 21px;
    height: 21px;
    line-height: 21px;
    text-align: center;
    color: #3d3d3d;
}
.promo_delico:hover {background-color: #b69a6b; color: #fff;}
.cart_footer_totrow p {font-size: 25px;}
.cart_footer_totrow p.help-block {font-size: 12px; font-size: 14px; font-weight: 300;}
.cart_footer_totrow span {font-size: 25px;}
.indication_progress {
    height: 12px;
    overflow: hidden;
    border-radius: 10px;
    background: #333;
    position: relative
}
.progress_bar {
    content: "";
    height: 12px;
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    background:#faebca;
    border-radius: 10px;
    -webkit-transition: 0.4s linear all;
    transition: 0.4s linear all;
}
.progress_bar_min_amt .progress_bar{ background:#ba8b6d; }
.indication .help-block {
    font-size: 14px !important;
    font-weight: 300;
    margin: 7px 0 0 !important;
    color: #000;text-transform: uppercase;
}
.delivery_info {
    background: #f5f5f5;
    padding: 24px 35px;
}
.delivery_address {
    margin: 0 0 20px;
}
.delivery_info h4 {
    color: #838181;
    font-weight: 700;
    font-size: 16px;
    margin: 0;
    text-transform: uppercase;
}
.delivery_info p {
    color: #3d3d3d;
    font-weight: 700;
    font-size: 16px;
    margin: 0;
}
.delivery_link {
    color: #3d3d3d;
    line-height: 1;
    border-bottom: 1px solid #b4b4b4;
    margin: 10px 0 0 0;
    display: inline-block;
    vertical-align: top;
}
.delivery_link:hover {
    border-color: #b69a6b;
}

.delivery_time {
    margin: 0 0 16px;
}

.delivery_handle {
    border-top: 1px solid #d3d1d1;
    padding: 20px 0 0 0
}
.cart_action .btn_grey {margin: 0; border-color: #e6e3e3;}
.cartaction_price {font-size: 24px; width: 30%; cursor: default;}
.cartaction_price sup {font-size: 50%; left: -4px; top: -7px;}
.text-left {text-align: left}
.text-right {text-align: right}
header .cart_action{ padding: 20px 20px 0 20px}
.header_top {background: #181818; position: relative;}


.htopinfo {width: 100%; display: inline-block; vertical-align: middle; color: #fff; padding: 10px 0;}
.htopinfo p {margin: 0;}
.pop-alert-icon { padding: 0 0 15px;}
.header_bot { position: relative;}
.sticky .alert_fixed {top: 95px;}

.scrollToTop {
    background: rgba(0, 0, 0, 0.7);
    width: 40px;
    height: 40px;
    line-height: 36px;
    position: fixed;
    bottom: 40px;
    right: 20px;
    font-size: 24px;
    border-radius: 2px;
    text-align: center;
    color: #fff;
    z-index: 1;
}

.scrollToTop:hover,
.scrollToTop:focus {
    background: rgba(0, 0, 0, 1);
    color: #fff;
}

.viewmore_poplink {
    position: absolute;
    right: 15px;
    bottom: 15px;
}
.bakery_inner {
    padding-bottom: 15px;
}

.outlet {
    position: relative;
}

.outlet_inner {
    padding: 63px 35px 76px 35px;
    background: #fff;
    margin: 25px 0 39px 0;
}
.innerproduct > h3, .fproducts-title h3 {
    font-size: 34px;
    line-height: 0.9;
    font-family: 'Athelas';
    text-align: center;margin-bottom: 20px; font-weight: normal; color: #523628; text-transform: uppercase;
}
.innerproduct > p{ font-size: 16px;text-align: center; max-width: 920px; margin: 0 auto 40px; font-weight: 300}
.innerproduct > h4{ font-family: 'Athelas';    text-align: center;margin-bottom: 20px; font-weight: normal; color: #523628; text-transform: uppercase; font-size: 25px;  }

.innerproduct-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
.addcart_row {
    display: none;
    margin: 0 auto;
}
.addcart-row-child{     display: -webkit-box;
    display: -ms-flexbox;
    display: flex;  justify-content: center;    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;}
.innerproduct-item { position: relative;}

.innerproduct-item-image {
    background: #fff;
    text-align: center;
}

.innerproduct-item-image img {
    height: auto;
    width: auto;
}

.innerproduct-item-hei {
    height: 255px;
    line-height: 255px;
    text-align: center;
}

.innerproduct-item::after {
    content: "";
    top: 0px;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.86);
    width: 100%;
    position: absolute;
    z-index: 1;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    opacity: 0;
    -webkit-transition: 0.5s all ease-in-out;
    transition: 0.5s all ease-in-out;
}

.innerproduct-col:hover::after,
.innerproduct-col.open::after {
    opacity: 1;
}

.innerproduct-button {
    position: absolute;
    top: 0%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    z-index: 2;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
    opacity: 0;
    visibility: hidden;
    transition: 0.5s all ease-in-out;
    -webkit-transition: 0.5s all ease-in-out;
}

.innerproduct-col:hover .innerproduct-button,
.innerproduct-col.open .innerproduct-button {
    opacity: 1;
    visibility: visible;
    top: 50%;
}
.innerproduct-item-text {
    text-align: center;
}
.innerproduct-item-text h4 {
    font-size: 16px;
    color: #363636;
    padding: 10px 0 8px 0;
    margin-bottom: 0;
}
.innerproduct-item-text h4 a {color: #363636;}
.innerproduct-item-text p {font-weight: 300;}
.innerproduct-item-text p a {color: #5b5b5b;}
.innerproduct-item-text h5 {font-size: 20px; color: #363636; margin-bottom: 0;}
.innerproduct-item-text h5 a {color: #363636;}
.price_none {
    display: inline-block; font-size: 13px; font-weight: 300; color: #999; padding-right: 10px;
}
.price_offer .price_none {width: 50%; text-align: left;}
.price_offer .price_nor {width: 50%; text-align: right; display: inline-block; margin-left: -4px;}
.modal-lg {width: 740px;}
.inn-product-popup {background: #fff; width: 100%; display: flex; padding: 20px 20px 60px 20px; flex-wrap: wrap;border-radius: 6px; border:2px solid #f4f3f3; }

.inn-product-popup .container { padding: 0;}
.prodet_banleft {width: 100%;}
.prodet_baninfo {width: 100%; padding:0px 0 20px 0; position: relative; text-align: left;}
.prodet_baninfo .product-tags-list {position: relative; margin: 0 0 20px 0;}
.prodet_baninfo .product-tags-list ul {
    padding: 0; margin: 0; list-style: none; display: -webkit-box; display: -ms-flexbox;
    display: flex; -ms-flex-wrap: wrap; flex-wrap: wrap; justify-content: center;
}
.prodet_baninfo .product-tags-list ul li{margin: 0 5px}
.inn-product-popup-bg {position: relative;}
.inn-product-popup-background {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 345px;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    -webkit-filter: blur(10px);
    -moz-filter: blur(10px);
    -o-filter: blur(10px);
    -ms-filter: blur(10px);
    filter: blur(10px);
}
.inn-product-popup-inner {position: relative; padding-top: 0px; line-height: 1;}
.inner_product_slide {outline: 0;}
.inn-product-popup-inner .slick-next,
.inn-product-popup-inner .slick-prev {
    display: none !important
}
.inn-product-popup-inner .custom-dots {
    padding: 0;
    position: absolute;
    bottom: 12px;
    left: 0;
    right: 0;
    margin: 0;
    text-align: center;
}
.inn-product-popup-inner .custom-dots li {
    display: inline-block;
    width: 20px;
    height: 20px;
    position: relative;
    z-index: 1;
}
.inn-product-popup-inner .custom-dots li button {padding: 0; margin: 0;}
.inn-product-popup-inner .custom-dots li button:before {
    content: "";
    background: #fff;
    width: 12px;
    height: 12px;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 50%;
    border: 1px solid #c3c3c3;
}
.inn-product-popup-inner .custom-dots li.slick-active button:before {
    background: #ffa700;
}
.inn-product-popup-inner .custom-dots li button {
    text-indent: -9999px;
    background: none;
    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    width: 100%;
    height: 100%;
}
.inner_product_slide {position: relative;}
.pthumb_slide {
    margin: 5px;
    border: 1px solid #a2a6ac;
    -webkit-transition: all 400ms;
    transition: all 400ms;
    width: auto !important;
}

.pthumb_slide:hover,
.slick-current .pthumb_slide {
    -webkit-box-shadow: 0 0 3px 2px rgba(228, 121, 17, .5);
    box-shadow: 0 0 3px 2px rgba(228, 121, 17, .5);
    border-color: #e77600;
}

.popup_social {
    position: absolute;
    bottom: 5px;
    z-index: 1;
    right: 0;
    right: 5px;
}
.popup_social span {
    color: #373535;
    font-size: 12px;
    font-weight: 700;
}
.popup_social_icon {
    list-style: none;
    margin-bottom: 0;
    background: #fff;
    padding: 4px 0px 4px 4px;
}
.popup_social_icon li {
    float: left;
}
.popup_social_icon li a {
    color: #2a2a2a;
    padding: 0 10px;
    display: inline-block;
}
.popup_social_icon li a:hover {
    color: #ccc;
}
.popup_social_inner {
    float: right;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.popup_social_left {padding-right: 10px;}
.copied {
    position: absolute;
    right: 10px;
    top: -21px;
    background: #000;
    padding: 3px 7px;
    color: #fff;
    font-weight: normal;
    font-size: 11px;
    border-radius: 2px;
    pointer-events: none;
}

.copied:before {
    content: "";
    border: 7px solid #000;
    border-color: #000 transparent transparent transparent;
    position: absolute;
    left: 50%;
    bottom: -11px;
    margin-left: -7px;
}
.inn_product_row {margin: 0 auto;}
.inn_product_hea p {color: #5b5b5b; font-size: 16px; font-weight: 300; margin-bottom: 20px;padding-top: 10px}
.inn_product_hea_left h3 {font-size:35px; margin: 0 0 15px 0; text-transform: capitalize;line-height:1; color: #523628;font-family: 'Athelas'; font-weight: normal}
.product_chosen_inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
.product_chosen_col {width: 100%;}
.product_chosen_col.product_chosen_col_right {margin-right: 0;}
.product_chosen_col_inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 30px;
}
.product_chosen_col.product_chosen_col_right .product_chosen_col_inner {margin-bottom: 0;}
.form-group-modifier { margin-top: 23px;}
.product_chosen_item_left.product_chosen_item_left_full {
    width: 100%; margin-bottom: 10px
}
.product_chosen_item_left:nth-of-type(2n+2) {margin-right: 0;}
.product_chosen_item_left_second {float: left; width: 47%; margin-right: 3.928%;}

.product_chosen_item_left_second:nth-of-type(2n+2) {
    margin-right: 0;
}
.select_full {width: 100%;}
.product_chosen_hea h6 {
    font-size: 15px;
    color: #000000;
    text-transform: uppercase;
    position: relative;
    margin-bottom: 0;
    z-index: 1;
    padding-right: 10px;
	text-align: left;
    font-weight: 900;
}
.product_chosen_hea {position: relative; padding-bottom: 10px;}
.product_chosen_item_left .chosen-container-single .chosen-single {
    width: 100%;
    height: 47px;
    line-height: 40px;
    border: 1px solid #e6e5e5;
    font-weight: 700;
}
.product_chosen_item_left .chosen-single div b {
    background: url(../images/spirite_whole.png) no-repeat center;
    background-position: -246px -62px;
    width: 28px;
    height: 28px;
}

.product_chosen_item_left .chosen-container-active.chosen-with-drop .chosen-single div b {
    background: url(../images/spirite_whole.png) no-repeat center;
    width: 28px;
    height: 28px;
    background-position: -287px -62px;
}

.product_chosen_item_left .chosen-container-single .chosen-single div {
    top: 10px;
    right: 0px;
}

.product_chosen_item_left .chosen-container .chosen-results .highlighted {
    background: #ecdfcc;
}
.product_chosen_addons {
    width: 100%;
}

.chosen_adn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 100%;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0 0 10px 0;
}
.chosen_adn_right {line-height: 0;}
.chosen_adn_left span {
    font-size: 16px; position: relative; display: inline-block; margin-bottom: 0;
    z-index: 1; padding-right: 10px;
}
.radio.chosen_adn_right label {padding-left: 0;}
.radio .qty_bx label {font-weight: 700;}
.product_chosen_col_right .custom_checkbox {width: 100%;}
.product_chosen_col_right .custom_checkbox span {
    font-size: 16px;
    font-weight: 700;
    color: #72624f;
    position: relative;
    display: block;
    margin-bottom: 0;
    background: #fbfbfb;
    z-index: 1;
    padding-right: 30px;
    padding-left: 0;
}
.product_chosen_col_right .custom_checkbox span:before {right: 0; left: auto; top: -5px;}
.product_chosen_col_right .chosen_adn {margin-bottom: 16px;}
.text-box {width: 100%;}
.text-box em {text-transform: uppercase; font-size: 13px; color: #181818; text-align: right; float: right;}
.text-box textarea {width: 100%; height: 120px; padding: 15px 15px;}
textarea {resize: none;}
.chosen_upload {float: right; text-align: right; width: 100%;}
.inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
    outline: 0;
}
.inputfile + label {
    font-size: 16px;
    font-weight: 700;
    white-space: nowrap;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    padding: 11.5px 15px;
    outline: 0;
    background: #ecdfcc;
    max-width: 160px;
    border: 1px solid #e6e5e5;
    text-overflow: ellipsis;
}

.inputfile + label:hover {
    background: #333;
}

.inputfile + label:hover span {
    color: #fff;
}

.no-js .inputfile + label {
    display: none;
}

.inputfile + label svg {
    width: 1em;
    height: 1em;
    vertical-align: middle;
    fill: currentColor;
    margin-top: -0.25em;
    margin-right: 0.25em;
}

.chosen_upload_inner label span {
    color: #72624f;
}
.inputfile-4 + label {
    color: #d3394c;
}
.inputfile-4:focus + label,
.inputfile-4.has-focus + label,
.inputfile-4 + label:hover {
    color: #722040;
}
.inputfile-4 + label figure {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    background-color: #d3394c;
    display: block;
    padding: 20px;
    margin: 0 auto 10px;
}
.inputfile-4:focus + label figure,
.inputfile-4.has-focus + label figure,
.inputfile-4 + label:hover figure {
    background-color: #722040;
}
.inputfile-4 + label svg {
    width: 100%; height: 100%; fill: #f1e5e6;
}
.inputfile-6:focus + label strong,
.inputfile-6.has-focus + label strong,
.inputfile-6 + label:hover strong {
    background-color: 722040;
}
.prd_chosen_sub_row {    text-align: center;
    padding: 15px 0px 30px;
    border-top: 3px solid #f5f4f4;
    margin: 30px 0 0 0;
    display: flex;
    justify-content: space-between; align-items: flex-end; }
.prd_chosen_sub_row > p {font-size: 34px; margin-bottom: 15px; }
.prd_chosen_sub_row p > sup {font-size: 20px;}
.prd_chosen_sub_col {
    display: flex;
        flex-wrap: wrap;
    align-items: flex-end;
    width: calc( 100% - 150px ); justify-content: space-between;
}
.prd_chosen_sub_item_left p{margin: 0 0 5px 0;}

.prd_chosen_sub_col .alert { margin: 0; width: 100%;}
.prd_chosen_sub_item_left { display: flex;width: 180px; margin: 0; flex-flow: column}
.prd_chosen_sub_item_right.cart_update_div { width: calc( 100% - 190px );}
.prd_chosen_sub_item_right button { width: 100%; margin: 0;    font-size: 16px; padding: 18px 10px 18px 10px;min-width: inherit; background: #523628; border-color: #523628;color: #fff;}
.prd_chosen_sub_item_right button:hover{ background: #000; border-color: #000; color: #fff; }
.inn_product_row .addcart_row .qty_bx {
    width: 100%;
    border: 2px solid #e4e4e4;
    height: 56px;
    padding: 0 54px;
    border-radius: 6px;
}
.inn_product_row .addcart_row .qty_bx span{  width: 54px; height: 56px;}
.inn_product_row .addcart_row .qty_bx span.qty_minus { left: 0px;  border-left: 0; border-color: #e4e4e4 }
.inn_product_row .addcart_row .qty_bx span.qty_plus { right: 0px; border-right: 0; border-color: #e4e4e4}
.inn_product_row .addcart_row .qty_bx span:hover{ background: none;}
.inn_product_row .addcart_row .qty_bx span:hover:after, .inn_product_row .addcart_row .qty_bx span:hover:before{ background: #000 }

.prd_chosen_sub_item_right.prd_chosen_item_full {
    width: 100%; margin-top:15px;
}


.skip {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 700;
    background: #dbd9d9;
    max-width: 90px;
    width: 100%;
    display: block;
    text-align: center;
    padding: 7px 15px;
    color: #181818;
}
.skip:hover {
    color: #fff;
    background: #333;
}
.prd-sub-bg {
    background: #fff;
    max-width: 1000px;
    margin: 0 auto;
    padding: 40px 0;
    border-radius: 10px 0 10px 10px;
    -webkit-border-radius: 10px 0 10px 10px;
    box-shadow: 1px 0px 24px 0px rgba(0, 0, 0, 0.14);
    -webkit-box-shadow: 1px 0px 24px 0px rgba(0, 0, 0, 0.14);
    position: relative;
}
.prd-sub {
    max-width: 862px;
    position: relative;
    margin: 0 auto;
}
.prd-sub h2 {
    text-align: center;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 700;
}
.prd-sub .slick-slide {
    padding: 0 5px;
}
.prd-sub .slick-next {
    font-size: 0;
    border: 0;
    width: 62px;
    height: 77px;
    outline: 0;
    position: absolute;
    right: -69px;
    top: 23%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    background: inherit;
}
.prd-sub .slick-next::after {
    content: "";
    width: 39px;
    height: 39px;
    background: url(../images/spirite_whole.png) no-repeat;
    position: absolute;
    background-position: -27px -123px;
    left: 0px;
    top: 30%;
    margin: auto;
    right: 0;
    text-align: center;
}

.prd-sub .slick-next:hover {
    background: #f5f5f5;
}

.prd-sub .slick-next:hover::after {
    background-position: -154px -123px;
}

.prd-sub .slick-prev {
    font-size: 0;
    border: 0;
    width: 62px;
    height: 77px;
    outline: 0;
    position: absolute;
    left: -69px;
    top: 23%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    background: inherit;
}

.prd-sub .slick-prev::after {
    content: "";
    width: 39px;
    height: 39px;
    background: url(../images/spirite_whole.png) no-repeat;
    position: absolute;
    background-position: -86px -123px;
    left: 0px;
    top: 30%;
    margin: auto;
    right: 0;
    text-align: center;
}

.prd-sub .slick-prev:hover {
    background: #f5f5f5;
}

.prd-sub .slick-prev:hover::after {
    background-position: -213px -123px;
}

.prd-sub-slide-content {
    text-align: center;
    margin-top: 10px;
}

.prd-sub-slide-content h5 {
    font-size: 14px;
    font-weight: 700;
    color: #363636;
    margin-bottom: 8px;
}

.prd-sub-slide-content h6 {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 20px;
}

.prd-sub-slide-qty .qty_bx .qty_minus {
    background: #f4b56b;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    width: 40px;
}

.prd-sub-slide-qty .qty_bx .qty_plus {
    background: #e48f2c;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    width: 40px;
}

.prd-sub-slide-qty .qty_bx .qty_minus:hover,
.prd-sub-slide-qty .qty_bx .qty_plus:hover {
    background: #333;
}

.prd-sub-slide-qty .qty_bx input {
    background: #fff;
    width: 62px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
}

.prd-sub-slide-qty {
    background: #ecdfcc;
    padding: 4px 0 0px 0;
    border: 1px solid #f9f9f9;
}

.prd-sub-slide-qty .qty_bx {
    width: 146px;
    height: 40px;
}

.prd-sub-slide-qty .qty_bx span {
    height: 40px;
}

.prd-sub-slide-submit {
    background: #181818;
}

.prd-sub-slide-submit a {
    font-size: 18px;
    padding: 13px 0;
    display: block;
    text-transform: uppercase;
    color: #fff;
    border-top: 1px solid #f9f9f9;
}

.prd-sub-slide-submit a:hover {
    background: #e48f2c;
}

.slick-slide {
    outline: 0;
}

.prd-sub-slide-content .btn {
    width: 100%;
}

.checkout_hea {
    position: relative;
}

.checkout_hea::after,
.check_pg::after {
    display: block;
    content: "";
    clear: both;
}

.checkout_hea h3 {
    font-size: 27px;
    margin-bottom: 5px;
}

.checkout_hea p {
    margin-bottom: 25px;
    color: #5b5b5b;
}

.checkout_hea_inner {
    margin-top: 8px;
}

.check_pg {
    position: relative;
}

.check_pg_inner {
    background: #fff;
    padding: 75px 0px 29px 0px;
    margin-bottom: 14px;
}

.check-pg-slider .slick-slider {
    padding: 0 75px;
}

.check_pg_inner .innerproduct-col {
    width: auto;
    margin-right: 0;
}

.check_pg_inner .slick-slide img {
    text-align: center;
    margin: auto;
}

.check_pg_inner .slick-slide {
    padding: 0 7.5px;
}

.check_pg_inner .slick-next {
    font-size: 0;
    border: 0;
    width: 62px;
    height: 77px;
    outline: 0;
    position: absolute;
    right: 0;
    top: 32.5%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    background: inherit;
    z-index: 1;
}

.check_pg_inner .slick-next::after {
    content: "";
    width: 39px;
    height: 39px;
    background: url(../images/spirite_whole.png) no-repeat;
    position: absolute;
    background-position: -27px -123px;
    left: 0px;
    top: 30%;
    margin: auto;
    right: 0;
    text-align: center;
}
.check_pg_inner .slick-next:hover {
    background: #f5f5f5;
}
.check_pg_inner .slick-next:hover::after {
    background-position: -154px -123px;
}
.check_pg_inner .slick-prev {
    font-size: 0;
    border: 0;
    width: 62px;
    height: 77px;
    outline: 0;
    position: absolute;
    left: 0;
    top: 32.5%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    background: inherit;
    z-index: 1;
}
.check_pg_inner .slick-prev::after {
    content: "";
    width: 39px;
    height: 39px;
    background: url(../images/spirite_whole.png) no-repeat;
    position: absolute;
    background-position: -86px -123px;
    left: 0px;
    top: 30%;
    margin: auto;
    right: 0;
    text-align: center;
}
.check_pg_inner .slick-prev:hover {background: #f5f5f5;}
.check_pg_inner .slick-prev:hover::after {background-position: -213px -123px;}
.checkoutpage_form_outer .form-group {margin-bottom: 15px;}
.check_out_fourcommon {width: 100%; margin: 0 auto;}
.checkoutpage_form_outer {width: 100%; margin: 0 auto;}

.cdd-details{display: -webkit-box; display: -ms-flexbox; display: flex; -ms-flex-wrap: wrap;flex-wrap: wrap;margin-bottom:20px; -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between;}
.cdd-details .ttsplitter{margin-bottom: 0;}
.checkout-total {width: 100%;margin: 25px auto 0px auto;}
.checkout-total::after {display: block;content: "";clear: both;}
.checkout-total .cdd-details-lhs h4,
.checkout-total .cart-header h4 {
	padding: 14px 20px;position: relative;background: #87311d; border-bottom: 0px;
	text-align: center;font-size: 24px;width: 100%;margin-bottom: 0;
}
.checkout-total .cart-header {padding: 0;background: inherit;border: 0;margin: 0}
.checkout-total .cdd-details-rhs .cart-header h4 {margin: 0;line-height: normal}
.checkout-total .cart-header:after {display: none}
 .checkout-total .cdd-details-rhs { width:49%;position: relative;padding: 0;background: #fff; border-radius: 6px; border: 2px solid #f4f3f3;}
.checkout-total .cdd-details-lhs{ width:49%;position: relative;padding: 0; }

.checkout-total .cdd-details-rhs .chekout_cart_bar {height: 100%;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;}
.checkout-total .cdd-details-rhs .chekout_cart_bar .checkout-right-body-section {height: 100%;}
.checkout-total .cdd-details-rhs .chekout_cart_bar .checkout-right-body-section .cart_table {height: 100%;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;}
.checkout-total .cdd-details-rhs .chekout_cart_bar .checkout-right-body-section .cart_table .cart_footer {flex-grow: 1;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;-webkit-box-pack: end;-ms-flex-pack: end;justify-content: flex-end;}
.checkout-total .cdd-details-rhs .checkout-right-body-section{padding: 10px 15px 20px 15px; }    
.checkout-body-section{ padding: 10px 20px;}

.chk_hea {
font-size: 17px;
padding: 12px 10px 12px 15px;
display: -webkit-box;
display: -ms-flexbox;
display: flex;
margin: 0 0 15px 0;
line-height: 1;
-webkit-box-pack: justify;
-ms-flex-pack: justify;
justify-content: space-between;
-webkit-box-align: center;
-ms-flex-align: center;
align-items: center;
text-transform: uppercase;    
color: #000;
position: relative;font-weight: bold;background: #f7f7f7 }

.chk_hea > span {
    color: #000000;
    font-size: 13px;
    font-weight: normal;
    display: flex;
    align-items: center;
    text-transform: capitalize;
    position: relative;
    padding-right: 0px;
}
.chk_hea > span input[type=checkbox]{ margin-right: 0; margin-left: 8px;border-radius: 50%}
.form-group-dt {
    padding: 10px 0 0 0;
}
.checkout-total .chosen-container .chosen-results .highlighted {
    background: #ecdfcc;
}

.checkout-total .checkout-control-group-top a {
    font-size: 14px;
    color: #000;
    line-height: 1;
    border-bottom: 1px solid #b4b4b4;
    margin: 5px 0 0 0px;
    display: inline-block;
    vertical-align: top;
    -webkit-transition: 0.3s linear all;
    transition: 0.3s linear all;
}

.checkout-total .checkout-control-group-top a:hover {color: #523628;border-color: #000;}
.checkout-total .checkout-control-group-top { padding: 0px 0 10px 0; border-bottom: 1px solid transparent; }
.checkout-control-group-topband {border-bottom: 1px solid #939292; padding: 0 0 35px 0;}
.checkout-control-group-topband span {float: right; font-weight: normal; font-size: 15px;}
.checkout-total .checkout-control-group-middle {margin: 0px 0 15px 0; padding: 25px 20px 20px 20px; width: 100%; border-bottom: 1px solid #eeeded;}
.checkout-total .checkout-control-group-bottom {padding: 0;}
.checkout-billing-address {padding: 10px 0 5px 0; width: 100%;}
.checkout-billing-address.send-as-gift .custom_checkbox span:before{ top: -5px; }
.checkout-total .checkout-control-group-middle .form-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.checkout-total .checkout-control-group-middle .form-group .react_datepicker {
    width: 48%
}

.checkout-total .checkout-control-group-middle .form-group .input_area {
    width: 49%
}

.checkout-total .checkout-control-group-bottom textarea {
    padding: 15px;
    height: 150px;
    outline: 0;
}
.form-group.controls-two {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.checkout-total .form-group.controls-two > div input{margin-bottom: 0}
.checkout-total .form-group.controls-two > div{width: 49%}
.controls-single textarea{height: 100px;}

.checkout-total textarea::-webkit-input-placeholder {
    color: #595656;
}

.checkout-total textarea:-moz-placeholder {
    color: #595656;
}

.checkout-total textarea::-moz-placeholder {
    color: #595656;
}

.checkout-total .cart_body {
    padding: 0;
    box-shadow: none;
}

.checkout-total .form-group.controls-three {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.checkout-total .form-group.controls-three > div { width: 32%;}
.checkout-total .form-group.controls-three > div input {  margin-bottom: 0;}

.order-detail-inner {
    padding: 82px 0 0 0
}

.order-detail-inner-icon {
    position: relative;
    max-width: 604px;
}

.order-detail-inner-icon-left {
    position: absolute;
    left: 0;
    top: 2px;
}

.order-detail-scotter {
    position: relative;
    z-index: 2;
}

.order-detail-scotter::after {
    content: "";
    background: #fff url(../images/spirite_whole.png) no-repeat;
    background-position: -283px -126px;
    width: 41px;
    height: 24px;
    left: 0;
    top: -2px;
    position: absolute;
}

.order-detail-scotter::before {
    content: "";
    background: #fff url(../images/spirite_whole.png) no-repeat;
    background-position: -347px -126px;
    width: 22px;
    height: 22px;
    right: 0;
    top: -2px;
    position: absolute;
}

.order-detail-inner-icon-left.order-detail-right {
    position: absolute;
    right: 0;
    top: 3px;
    left: inherit;
}

.order-detail-inner-icon-left.order-detail-inner-center {
    position: static;
    left: inherit;
    top: inherit;
    width: 100%;
    text-align: center;
    display: inline-block;
}

.order-detail-inner-icon-left.order-detail-inner-center::after {
    display: block;
    clear: both;
    content: "";
}

.order-detail-inner-icon-left.order-detail-inner-center::before {
    position: absolute;
    content: "";
    top: 0;
    width: 100%;
    max-width: 497px;
    left: 0;
    right: 0;
    height: 10px;
    margin: auto;
    top: 50%;
}

.order-detail-inner-icon-left.order-detail-inner-center img {
    vertical-align: middle
}

.delivery_total::after {
    display: block;
    content: "";
    clear: both;
}

.delivery_total {
    margin: 10px 0 0px 0;
}

.delivery_total_left {
    float: left;
    text-align: left;
    width: 50%;
}

.delivery_total_left h3 {
    text-transform: uppercase;
    margin-bottom: 0;
    font-weight: 700;
    color: #1c1b1b;
    line-height: 22px;
    font-size: 17px
}

.delivery_total_left span {
    display: block;
    color: #000;
    line-height: 20px;
    font-size: 14px
}
.delivery_total_left.delivery_total_right {float: right; text-align: right;}
.delivery_total.delivery_total_number {margin-bottom: 0; padding: 10px 0 0 0;}
.delivery_total.delivery_total_number .delivery_total_left h2 {
    font-size: 17px;font-weight: 600; color: #060203; margin-bottom: 5px; text-transform: uppercase;
}
.delivery_total.delivery_total_number .delivery_total_left h4 { font-size: 17px; margin-bottom: 0;}

.pickup-order-div:before {display: none;}
.pickup-order-div .delivery_total_left {width: 100%; text-align: center;}
.pickup-order-div .delivery_total_left img {width: 25px;}

.cart-product-desc-inner {padding-left: 20px;}
.cart-product-desc-inner span {display: block; color: #3d3d3d; line-height: 19px; font-size: 14px;}

.chekout_cart_bar .cart_footer {padding: 25px 15px 10px 5px; margin: 0; background: #fff;}
.chekout_cart_bar .cart_left {padding-left: 0;}
.chk_hea .custom_checkbox span{ margin-left: 0}
.chk-payment-btn-row .button {
    width: 100%;
    display: block;
    margin: 0;
    font-size: 16px;
    padding: 15px 10px;
    background: #523628; border-color:#523628; color:#fff; }
.chk-payment-btn-row.add-tocart-cls,
.chk-payment-btn-row.checkout-continue-cls .row-replace,
#PackageEditMdl .chk-payment-btn-row .row-replace {display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-pack: justify;-ms-flex-pack: justify; justify-content: space-between;}

.chk-payment-btn-row.checkout-continue-cls .row-replace .col-md-cls, #PackageEditMdl .chk-payment-btn-row .row-replace .col-md-cls { width: 49%}

.dwn_quotion {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
}

.dwn_quotion a {
    background: #e6e3e3;
    font-size: 16px;
    font-weight: 700;
    display: block;
    padding: 10px 0;
    color: #0d0d0d;
    text-transform: uppercase;
    margin-bottom: 0;
}

.dwn_quotion a:hover {
    background: #333;
    color: #fff;
}

.redeem {position: relative;  border: 2px solid #f4f3f3; border-radius: 6px; background: #f9f9f9; padding:40px 18px; width: 49%; order: 2;}
.redeem::after {display: block; clear: both; content: "";}
.redeem-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}
.redeem-col {
    background: #fff;
    padding: 35px 20px;
    border: 2px solid #f4f3f3;
    border-radius: 6px;
    width: 100%;
}
.redeem-col-right{margin-top: 10px}
.redeem-col-full{width: 100%}
.redeem-col-full .redeem-item{ max-width: 660px; margin: 0 auto;}
.redeem-item-hea { position: relative;}
.redeem-item-hea h4 {font-size: 22px; text-transform: uppercase;  margin-bottom: 0;font-weight: 900;}
.redeem-item-hea span {font-size: 15px; font-weight: 700;text-transform: capitalize}
.redeem-item-hea .points {
    position: absolute;
    right: 0;
    padding: 8px 0;
    max-width: 280px;
    display: block;
    width: 100%;
    text-align: center;
    top: 8px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    font-weight:bold;
    color: #000;
}
.redeem-item-hea .points b {color: #e7ac1e; font-weight:bold;}
.redeem_apply {position: relative; margin-top: 5px;}
.redeem_apply .btn { position: absolute; right: 0; top: 0; margin: 0;  padding: 16px 22px;  width: 200px; height: 100%;}
.redeem_apply .btn:hover {background: #523628; color: #fff;}
.redeem_apply input{ height: 56px; }
.redeem_apply .focus-out { padding-right: 170px;}
.ourrest_info > div > div { padding-top: 10px;}
.redeem-item-hea-inner {width: 100%;position: relative;padding-left: 50px;    min-height: 40px;
    display: flex;  align-items: center;}
.chk-payment-main {background: #f9f9f9;  border-radius:6px; border: 2px solid #523628; padding: 40px 30px 30px 30px; text-align: center; margin: 0; width: 49%;    order: 1; }
.redeem-item-hea-inner img {
    position: absolute;
    left: 5px;
}
.chk-payment-col-radio {
    padding: 0;
    list-style: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
   justify-content: center; max-width: 580px; margin: 0 auto 10px auto;
}
.chk-payment-col-radio li {
    max-width: 100%;
    padding:22px 20px 22px 20px;
    border: 2px solid #c3c3c3;
    border-radius: 8px;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
     justify-content: center; text-align: center;
    margin-bottom: 5px;
    opacity: 0.2; box-shadow:         0px 2px 10px 0px rgba(50, 50, 50, 0);
}
.chk-payment-col-radio li input[type=radio]{ opacity: 0; visibility: hidden; display: none}
.chk-payment-col-radio li.active{ border-color: #523628; opacity: 1; box-shadow:0px 2px 10px 0px rgba(50, 50, 50, 0.08);}
.chk-payment .chk-payment-col-radio li .radio_con{ 	font-size: 15px; text-transform: uppercase;  margin-left: 0 !important}
.chk-payment .chk-payment-col-radio li .radio_con span{display: block;margin: 0 0 15px 0; color: #000;}
.payment-cod .radio_con, .payment-paynow .radio_con { display: flex; align-items: center;}
.payment-cod .radio_con span, .payment-paynow .radio_con span{ margin-bottom: 0 !important; order: 2 }
.payment-cod .radio_con img, .payment-paynow .radio_con img{ order: 1; margin-right: 15px; }
.chk-payment-col-radio li.payment-cod, .chk-payment-col-radio li.payment-paynow{ padding: 10px 10px 10px 10px; }
.redeem-payment-merge{display: flex; justify-content: space-between; flex-wrap: wrap;}
.fixit {
    border: 2px solid #523628;
    border-radius: 4px;
}
.checkout-tab ul {
    display: flex;
    padding: 0;
    list-style: none;
}

.checkout-tab ul li {
    width: 50%;
}

.checkout-tab ul li a {
    text-align: center;
    font-family: 'Athelas';
    font-size: 19px;
    text-transform: uppercase;
    padding: 10px;
    border: 2px solid #f4f3f3;
    border-bottom: 0;
    color: #a8a7a7;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px 4px 0 0 ;
    height: 55px
}
.checkout-tab ul li:first-child a{ border-right: 0; }
.checkout-tab ul li.active a{ background: #523628; border-color: #523628; color: #fff; }
.checkout-tab ul li a img{margin-right:10px;}
.checkout-tab ul li a .checkactive{ display: none}
.checkout-tab ul li.active a .checkactive{ display: block}
.checkout-tab ul li.active a .checknormal{ display: none}

/* Custom Radio Check Box*/
@supports (-webkit-appearance: none) or (-moz-appearance: none) {
  input[type=checkbox],
input[type=radio] {
    --active: #523628;
    --active-inner: #fff;
    --focus: 2px rgba(0, 0, 0, 0);
    --border: #c3c3c3;
    --border-hover: #523628;
    --background: #fff;
    --disabled: #F6F8FF;
    --disabled-inner: #999;
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 22px;
    outline: none;
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin: 0;
    cursor: pointer;
    border: 1px solid var(--bc, var(--border));
    background: var(--b, var(--background));
    transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
    top: 0;
	margin-right:8px;
  }
  input[type=checkbox]:after,
input[type=radio]:after {
    content: "";
    display: block;
    left: 0px;
    top: 0px;
    position: absolute;
    transition: transform var(--d-t, 0.3s) var(--d-t-e, ease), opacity var(--d-o, 0.2s);
  }
  input[type=checkbox]:checked,
input[type=radio]:checked {
    --b: var(--active);
    --bc: var(--active);
    --d-o: .3s;
    --d-t: .6s;
    --d-t-e: cubic-bezier(.2, .85, .32, 1.2);
  }
  input[type=checkbox]:disabled,
input[type=radio]:disabled {
    --b: var(--disabled);
    cursor: not-allowed;
    opacity: 0.9;
  }
  input[type=checkbox]:disabled:checked,
input[type=radio]:disabled:checked {
    --b: var(--disabled-inner);
    --bc: var(--border);
  }
  input[type=checkbox]:disabled + label,
input[type=radio]:disabled + label {
    cursor: not-allowed;
  }
  input[type=checkbox]:hover:not(:checked):not(:disabled),
input[type=radio]:hover:not(:checked):not(:disabled) {
    --bc: var(--border-hover);
  }
  input[type=checkbox]:focus,
input[type=radio]:focus {
    box-shadow: 0 0 0 var(--focus);
  }
  input[type=checkbox]:not(.switch),
input[type=radio]:not(.switch) {
    width: 22px;
  }
  input[type=checkbox]:not(.switch):after,
input[type=radio]:not(.switch):after {
    opacity: var(--o, 0);
  }
  input[type=checkbox]:not(.switch):checked,
input[type=radio]:not(.switch):checked {
    --o: 1;
  }
  input[type=checkbox] + label,
input[type=radio] + label {
    line-height: 21px;
    display: inline-block;
    cursor: pointer;
    margin-left: 5px !important;
  }

  input[type=checkbox]:not(.switch) {
    border-radius: 3px;
  }
  input[type=checkbox]:not(.switch):after {
    width: 6px;
    height: 11px;
    border: 2px solid var(--active-inner);
    border-top: 0;
    border-left: 0;
    left: 7px;
    top: 4px;
    transform: rotate(var(--r, 20deg));
  }
  input[type=checkbox]:not(.switch):checked {
    --r: 43deg;
  }
  input[type=checkbox].switch {
    width: 38px;
    border-radius: 11px;
  }
  input[type=checkbox].switch:after {
    left: 2px;
    top: 2px;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    background: var(--ab, var(--border));
    transform: translateX(var(--x, 0));
  }
  input[type=checkbox].switch:checked {
    --ab: var(--active-inner);
    --x: 17px;
  }
  input[type=checkbox].switch:disabled:not(:checked):after {
    opacity: 0.6;
  }

  input[type=radio] {
    border-radius: 50%;
  }
  input[type=radio]:after {
    width: 6px;
    height: 12px;
    border: 2px solid var(--active-inner);
    border-top: 0;
    border-left: 0;
    left: 7px;
    top: 3px;
    transform: rotate(var(--r, 25deg));
  }
  input[type=radio]:checked {
    --s: .5; --r: 43deg;
  }
}


.chosen_radio_inner {width: 100%;}
.expand {
    background: #eaeaea;
    padding: 10px 15px;
    margin-bottom: 5px;
    cursor: pointer;
    position: relative;
}
.expand h3 {
    color: #000;
    font-size: 18px;
    margin-bottom: 0;
    font-weight: 600;
    text-transform: uppercase;
    position: relative;
    width: 100%;
}
.expand::after {
    content: "\f107";
    right: 10px;
    display: block;
    top: 3px;
    font-family: "FontAwesome";
    position: absolute;
    color: #000;
    font-size: 26px;
}
.expand.active::after {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
}
.expand_sub h1 {
    font-size: 16px;
    font-weight: 700;
    color: #72624f;
    position: relative;
    margin-bottom: 0;
    background: #fbfbfb;
    z-index: 1;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 10px 10px;
}
.expand_sub h1 span {width: 80%;}
.panel-heading.expand_hea {cursor: pointer;}
.expand_sub h4.panel-title {
    font-weight: 700;
    text-transform: uppercase;
    position: relative;
}
.expand_sub_body {
    width: 100%;
}
.expand_sub h4::after {
    content: "\f107";
    right: 0;
    display: block;
    top: -7px;
    font-family: "FontAwesome";
    position: absolute;
    color: #000;
    font-size: 26px;
}

.expand_hea.active h4.panel-title::after {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

.chk-payment-btn {margin-bottom: 0px;max-width: 580px;margin-left: auto; margin-right: auto}
.chk-payment-btn-row .btn { width: 100%; border: 0;}
.chk-payment-btn-row .row {margin-right: 0; margin-left: 0;}

.tnk-you {position: relative; padding: 80px 0px 100px;}
.tnk-you::after {display: block; clear: both; content: "";}
.tnk-header {max-width: 683px; margin: 0 auto; text-align: center;}
.tnk-header .tick {padding: 0 15px;}
.tick h2 {font-size: 35px; margin: 20px 0 0 0; text-transform: uppercase;}
.tick span {font-size: 18px; color: #0b0405;}

.tnk-detail h2 {   padding: 20px 10px 20px; color: #523628; text-transform: uppercase;  margin-bottom: 0;  font-size: 21px;font-family: 'Athelas'; font-weight: normal;}
.Viewreceipt .tnk-detail h2{ font-size: 18px;    font-family: 'Lato';font-weight: 500; color: #000}

.tnk-order {background: #523628; padding: 25px 15px;}
.tnk-order h3 {margin-bottom: 5px; font-size: 30px; text-transform: uppercase; color: #fff;font-weight: 900;}
.tnk-order p {font-size: 18px; color: #fff; margin-bottom: 0; }

.Viewreceipt .tnk-order{ background: #523628;}
.Viewreceipt .tnk-order h3 {color: #fff;}
.Viewreceipt .tnk-order p {color: #fff;}
.tnk-delivery .order-detail-inner {padding-top: 0px;}
.tnk-delivery {padding: 30px 15px; background: #fff;}

.cart_row.tnkorder-first {display: flex; justify-content: space-between; flex-wrap: wrap;}
.cart_row.tnkorder-first > div {width: 49%;}
.cart_row.tnkorder-first > div h5{margin-bottom: 5px; font-size: 16px;}
.cart_row.tnkorder-first > div p{margin: 0;font-size: 15px;}

.tnk-delivery .order-detail-inner-icon-left.order-detail-inner-center::before {display: none;}
.tnk-delivery .order-detail-inner-icon {margin: 0 auto;}
.tnk-delivery .delivery_total {margin: 6px 0 8px 0;}
.tnk-delivery .cart_left .help-block {text-align: left;}
.tnk-delivery .cart_info h4 {text-align: left;}
.tnk-delivery .cart_extrainfo {text-align: left;}
.tnk-delivery .cart_footer.tnk_cart_footer {padding: 20px 20px 5px 20px; margin: 0;}
.tnk-delivery .tnk_cart_footer .cart_row {text-align: left; padding: 0px 0px 5px 0px;}
.tnk-chk-order .btn {width: 100%; border: 0;}

.delivery_datetime_div {padding: 10px 0 10px 0 !important; margin: 10px 0 0px 0 !important;}
.orderitem_body_div {padding: 10px;}

.remark_notesec {margin: 20px 0 0; padding: 0 7px;}
.remark_notesec h4 {margin: 0 0 3px; font-weight: 700; color: #3d3d3d; font-size: 16px;}
.remark_notesec p {margin: 0;}

.mfp-bg {z-index: 10042;}
.mfp-wrap {z-index: 10043;}

.white-popup {max-width: 1026px; padding: 0; margin: 30px auto;}
.order_popup{ max-width: 580px }
.pouup_in {padding: 50px 50px 40px;}

.alert_popup,
.warining_popup,
.warning_popup,
.pay-conf-popup {max-width: 450px;    background: #fff;}

.warning_popup .warning-popup-img {width: 75px; margin: 0 0 10px 0;}

button.mfp-close,
button.mfp-arrow {margin: 0; padding: 0;}
.alert_popup .mfp-close {display: none;}
.alert_header {
    background:#523628;
    color: #fff;
    font-size: 22px;
    padding: 14px 14px;
    position: relative;
    text-align: center;
    text-transform: uppercase;     font-family: 'latoblack';
}
.alert_body {text-align: center; padding: 20px;}
.alt_btns a {margin: 0 5px; padding: 11px 20px 11px 20px; }

#warning-popup .alt_btns a.button-left{ background: #fff; color: #523628;  }
#warning-popup .button-right{ background: #000; color: #fff; border-color: #000; }
.custom_alert .alt_btns a:hover{ color: #fff !important; border-color: #523628 !important; background: #523628 !important;}


.popup_equalrw {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
.popup_ttsec {
    width: 42%;
    min-height: 310px;
    padding: 20px 20px;
    min-height: 310px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.pop_title {
    position: relative;
    color: #fff;
}
.pop_title h2 {
    font-size: 40px;
    margin: 0;
    color: #fff;
}
.pop_title small {
    font-size: 17px;
    font-weight: normal;
}
.popup_right {
    width: 58%;
    padding: 20px 60px 20px 68px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.innervmid_in {
    width: 100%
}
.delevery_popup .popup_ttsec {  background:#523628;}
.delevery_popup .popup_ttsec { padding-left: 38px; padding-right: 28px;}

.delevery_popup .popup_right .form-group .form-control {
    padding: 10px 10px;
    border: 1px solid #cbcbcb;
    margin: 0 0 25px;
    color: #181818;
}

.delevery_popup_datetime .popup_ttsec {
    width: 100%;
    min-height: 110px;
    padding: 25px 20px;
}
.delevery_popup_datetime .popup_right {
    width: 100%;
    padding: 40px 50px 40px 50px;
}
.delevery_popup_datetime .innervmid_in {
    max-width: 440px;
    margin: auto;
}
.delevery_popup_datetime .pop_title h2 {
    font-size: 45px;
    text-transform: uppercase;
    margin-bottom: 5px;
}
.delevery_popup_datetime .pop_title p {
    font-size: 16px;
    margin-bottom: 0px;
    font-weight: 500;
}
.delevery_popup_datetime .popup_right .pop_title p{text-transform: capitalize;}
.delevery_popup_datetime .innervmid_in {
    text-align: center;
}
.delevery_popup_datetime img.pop-scooter-img {
    margin-bottom: 5px;
}
.delevery_popup_datetime .awesome_del {
    margin-top: 30px;
}
.delevery_popup_datetime .awesome_del h2 {
    font-size: 17px;
    line-height: 1.3;
	margin: 0px 0px 25px 0px;
}
.delevery_popup_datetime .datetime_selt_lbl {
    padding-top: 10px;
    color: #181818;
    font-size: 15px;
    text-transform: uppercase;
}
.delevery_popup_datetime input[type="text"]{
    padding: 10px 10px;
    border: 1px solid #cbcbcb;
    margin: 0 0 25px;
    color: #181818;
}
.delevery_popup_datetime .popup_right .pop_title h2, .delevery_popup_datetime .popup_right .pop_title p, 
.delevery_popup_datetime .popup_right .awesome_del h3, .delevery_popup_datetime .popup_right .awesome_del h2 {
    color: #181818;
}


.login-popup, .signup-popup, .forgot-password-popup{
    max-width: 1020px;
    margin: 15px auto;
    width: 100%;
    background: #fff;
    position: relative;
}
.full-login-new {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.full-login-lhs{    
    padding: 30px 40px;
    text-align: center;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center; width: 380px; background: #ece4e0; }
.jb-logo{position: relative; }
.full-login-lhs img{margin-bottom: 20px;  }
.jb-normal1{display: none}
.full-login-lhs .button{ margin: 5px 0; width: 100%; padding: 15px 10px;}
.full-login-rhs-header{padding: 0 0 25px 0;}
.full-login-rhs-header h3, .msc-form-header h3 {
    font-size: 33px;
    margin-bottom: 5px;
    line-height: 1;
    color: #523628;
    text-transform: uppercase;     font-weight: normal; font-family: 'Athelas';
}

.full-login-rhs-header p, .msc-form-header p {
    margin-bottom: 0;
    font-size: 17px;
    font-weight: bold;
}
.full-login-rhs {
    margin: 0 auto;
    padding: 60px 60px;
    text-align: center;
    width: calc( 100% - 380px );
}
.full-login-rhs h4{display: none}
.full-login-rhs .custom_checkbox_content{  display: flex; align-items: center;}
.login_pop_sub .login_submit, .login_pop_sub .signup_submit, .login_in_guest button, .forgot-password-popup .forgotpassword-cls{ background: #523628; color: #fff;padding: 15px 10px;margin-bottom: 0px;width: 100%; font-size: 16px;  }
.full-login-rhs .open-popup-link{  text-transform: uppercase; color: #000;font-size: 17px; }
.login_pop_sub .login_submit:hover, .full-login-lhs .button:hover, .login_pop_sub .signup_submit:hover, .login_in_guest button:hover, .forgot-password-popup .forgotpassword-cls:hover{ background: #523628; color: #fff; border-color:#523628  }
.sign_reg p{margin-bottom: 0}
.full-login-rhs .sign_reg a {
    color: #595656;
    text-decoration: underline;
}
.full-login-rhs .sign_reg a:hover {
    color: #825a46;
}
.two-button-row {
        display: flex;
    max-width: 476px;
    justify-content: space-between;
    margin: 0 auto;
}

.two-button-row > div .button {
    display: block;
    margin: 0;
    width: 100%;
}

.two-button-row > div { width: 49%}
.go_second a { background: #523628; color: #fff; border-color: #523628;}
.error_postal_popup .popup_right .button { margin: 0}
img.pop-scooter-img { max-width: 90px; margin-bottom: 15px}
.receipt_popup, .receipt_cateringpopup {  max-width: 620px;}
.receipt_popup .pouup_in, .receipt_cateringpopup .pouup_in { padding: 0;}
.receipt_popup .tnk-you, .receipt_cateringpopup .tnk-you { padding: 0;}

.receipt_popup .tnk-detail,
.receipt_cateringpopup .tnk-detail {
    margin-top: 0
}

.receipt_popup .Viewreceipt,
.receipt_cateringpopup .Viewreceipt {
    margin: 30px 0
}

.error_postal_popup .popup_ttsec {
    background: #ffa700;
}

.error_postal_popup h4 {
    color: #fff;
    font-size: 16px;
}

.poptt_icontop {
    padding-left: 0;
}

.poptt_icontop i {
    position: static;
    margin: 0 auto 17px;
}

.news_postal_popup .popup_ttsec {
    height: 309px;
}

.news_postal_popup .popup_ttsec {
    background: #b79a6c;
    width: 51.46198830409356%;
}

.news_postal_popup .popup_right {
    background: url(../images/news_popupright.png) no-repeat;
    background-size: cover;
    width: 48.53801169590643%;
}

.news_postal_popup .pop_title p {
    font-size: 16px;
    margin: 6px 0 0 0;
}

.news_subscribe {
    position: relative;
    margin-top: 14px;
}

.news_subscribe .btn {
    position: absolute;
    right: 0;
    top: 0;
    border: 0;
    z-index: 11;
    border: 1px solid #f9f9f9;
}

.news_subscribe .btn:hover {
    background: #54362c;
    color: #fff;
}

.news_subscribe .form-control {
    height: 51px;
    border: 1px solid #fff;
}

.news_subscribe p {
    font-size: 16px;
    color: #fff;
    margin-top: 12px;
    margin-bottom: 0;
}

.mfp-close-btn-in .news_postal_popup .mfp-close {
    background: rgba(24, 24, 24, 0.5);
    color: #fff;
    font-size: 0;
    width: 30px;
    height: 32px;
    line-height: 30px;
    opacity: 1;
}

.mfp-close-btn-in .news_postal_popup .mfp-close::after {
    content: "";
    background: url(../images/spirite_whole.png) no-repeat;
    width: 15px;
    height: 15px;
    background-position: -83px -190px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 1;
}

.mfp-close-btn-in .news_postal_popup .mfp-close:hover {
    background: rgba(24, 24, 24, 0.9);
}

.order-body { text-align: center; padding: 64px 0; background: #fff;}
.order-body h2 { font-size:36px; margin-bottom: 0;font-family: 'latoblack';}
.order-body p { color: #333; font-size: 16px; margin: 0 0 20px 0;}

.order_delivery_item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    list-style: none;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 0;
    padding: 10px 30px 0 30px;
}

.order_delivery_item li {
    width: 48%;
}

.order_delivery_item li a {
    display: block;
    position: relative;
    background: #f5f5f5;
    padding: 25px 10px;
    border-radius: 10px;
    -webkit-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.08);
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.08);
    border: 2px solid #f5f5f5;
}
.order_delivery_item li a.active, .order_delivery_item li a:hover{ border-color:#523628;}

.header-ordernow-single-img{-webkit-transition: 0.3s linear all;transition: 0.3s linear all;}
.order_delivery_item h3 {
    font-size: 20px;
    margin: 15px 0 0 0;
    color: #523628;
    text-transform: uppercase;
}

.order_icon {
    display: block;
    text-align: center;
    margin: auto;
}


.order_delivery_item .tooltip {
    font-size: 16px;
}

.order_delivery_item .tooltip-inner {
    max-width: 260px;
}

.ordelivery_tooltip {
    position: relative;
}

.order_delivery_mobile_tooltip {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    position: absolute;
    left: -270px;
    top: 23px;
    background: #181818;
    padding: 10px;
    border-radius: 3px;
    width: 260px;
    margin: 0 auto 5px;
    z-index: 1;
    -webkit-transition: all 400ms;
    transition: all 400ms;
}

.order_delivery_item li:hover .order_delivery_mobile_tooltip {
    opacity: 1;
    visibility: visible;
}

.order_delivery_mobile_tooltip:before {
    content: "";
    position: absolute;
    right: -16px;
    top: 50%;
    margin-top: -7px;
    border: 10px solid #181818;
    border-color: transparent transparent transparent #181818;
    border-width: 7px 10px 7px 10px;
}

.order_delivery_mobile_tooltip p {
    margin: 0;
    color: #fff;
    font-size: 16px;
}

.selfcollect_tooltip .order_delivery_mobile_tooltip {
    left: auto;
    right: -270px;
}

.selfcollect_tooltip .order_delivery_mobile_tooltip:before {
    border-color: transparent #181818 transparent transparent;
    left: -16px;
    right: auto;
}

.order_delivery_item li.disable .ordericon_link {
    display: none;
}

.order_delivery_item li.disable:hover {
    background: #f9f9f9;
    border-color: #e1e1e2;
}

.order_delivery_item li.disable .order_delivery_mobile_tooltip {
    display: none;
}

.orderpop_address {
    position: relative;
    height: 100%;
    padding-top: 35px;
}

.orderpop_address p {
    font-size: 12px;
    margin: 0;
}

.orderpop_continue_shop {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}

.orderpop_continue_shop .btn {
    font-size: 11px;
    height: 30px;
    padding: 7px 15px;
    margin: 0;
}

.orderpop_change_address {
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
}

.orderpop_change_address:hover {
    color: #000;
}

.vouchergift-popup .mfp-close {color: #fff;opacity: 1; min-width: inherit}
.mfp-close-btn-in .order_popup .mfp-close,
.mfp-close-btn-in .awesome_popup .mfp-close,
.mfp-close-btn-in .self_popup .mfp-close,
.mfp-close-btn-in .delevery_popup .mfp-close,
.mfp-close-btn-in .error_postal_popup .mfp-close,
.mfp-close-btn-in .receipt_popup .mfp-close,
.mfp-close-btn-in .receipt_cateringpopup .mfp-close,
.changepw_popup .mfp-close,
.redeem_popsec .mfp-close,
.pay-conf-popup .mfp-close,
.warning_popup .mfp-close {
    background: #333;
    color: #fff;
    font-size: 0;
    width: 30px;
    height: 30px;
    line-height: 30px;
    opacity: 1;
    top: 5px;
    right: 5px;
    padding: 0;
    margin: 0;
    border: 1px solid #333;
    min-width: inherit;
}

.mfp-close-btn-in .order_popup .mfp-close::after,
.mfp-close-btn-in .awesome_popup .mfp-close::after,
.mfp-close-btn-in .self_popup .mfp-close::after,
.mfp-close-btn-in .delevery_popup .mfp-close::after,
.mfp-close-btn-in .error_postal_popup .mfp-close::after,
.mfp-close-btn-in .receipt_popup .mfp-close:after,
.mfp-close-btn-in .receipt_cateringpopup .mfp-close:after,
.changepw_popup .mfp-close:after,
.redeem_popsec .mfp-close:after,
.pay-conf-popup .mfp-close:after,
.warning_popup .mfp-close:after, .venue_popup .close-icon-but:after {
    content: "";
    background: url(../images/spirite_whole.png) no-repeat;
    width: 15px;
    height: 15px;
    background-position: -83px -190px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 1;
}

.mfp-close-btn-in .order_popup .mfp-close:hover,
.mfp-close-btn-in .awesome_popup .mfp-close:hover,
.mfp-close-btn-in .delevery_popup .mfp-close:hover,
.mfp-close-btn-in .error_postal_popup .mfp-close:hover,
.changepw_popup .mfp-close:hover,
.redeem_popsec .mfp-close:hover,
.pay-conf-popup .mfp-close:hover,
.warning_popup .mfp-close:hover,
.venue_popup .close-icon-but:hover{
    background: #282828;
}

.mfp-close-btn-in .receipt_popup .mfp-close,
.mfp-close-btn-in .receipt_cateringpopup .mfp-close {
    background: rgba(1, 1, 1, 0.9);
}

.mfp-close-btn-in .receipt_popup .mfp-close:hover,
.mfp-close-btn-in .receipt_cateringpopup .mfp-close:hover {
    background: rgba(0, 0, 0, 0.9);
}
.video_popup .order-body {
    padding: 60px 30px 40px 30px;
}

.awesome_popup .popup_ttsec {
    width: 100%; background: #000000; padding: 30px 20px;
}
.awesome_popup .btn_sec {
    max-width: 500px; margin: 10px auto;
}
.awesome_popup .popup_ttsec .button {
    margin-bottom: 0; margin-top: 0; background: #fff; color: #000
}
.awesome_popup .popup_ttsec .button:hover {
    background: #ffa700; color: #fff
}
.awesome_del {
    margin-top: 12px; text-align: center;
}
.awesome_del h3 {
    font-size: 24px; color: #fff; margin: 0px 0px 10px 0px;
}
.awesome_del h5 {
    font-size: 18px; margin-bottom: 0px; color: #fff;
}
.awesome_del h2 {
    color: #fff;
    font-size: 14px;
    
    line-height: 19px;
}

.awesome_right h4 {
    font-size: 16px;
}

.awesome_popup {
    max-width: 640px;
}

.datetime_selt_lbl{ font-weight: 700; padding-bottom: 10px; color: #383838; font-size: 16px}
.delevery_popup_datetime .btn_sec .button{ display: block; margin: 0; width: 100%; background: #523628; border-color: #523628; color: #fff; }

.white-popup.self_popup {
    max-width: 560px;
}

.self-back {
    position: absolute;
    top: 13px;
    font-size: 18px;
    color: #fff;
    z-index: 1;
    left: 40px;
    text-transform: uppercase;
}

.self-back:hover {
    color: #000;
}

.self_popup .order-body {
    padding: 0 0 0px 0;
    background: #fff;
}

.self_popup_hea_row {
    background: #523628;
    padding: 20px 15px;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.self_popup_hea_col {
    text-align: left;
}

.order-body .self_popup_hea_row h2 { color: #fff;}
.order-body .self_popup_hea_row p { margin-bottom: 0; font-size: 16px; color: #fff;}

.self_popup_hea_col_left {
    padding-right: 15px;
}
.self_popup_hea_col_left img{width: 50px }

.self_pop_row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 21px 15px;
}

.self_pop_col.self_pop_col_right {
    width: 100%;
    margin: 0 auto;
}

.self_pop_col {
    width: 50%;
}

.self_pop_item_loc {
    position: relative;
}

.self_pop_item_map {
    box-shadow: 0px 0px 10px 0px rgba(1, 1, 1, 0.17);
    -webkit-box-shadow: 0px 0px 10px 0px rgba(1, 1, 1, 0.17);
    border: 1px solid #bba075;
    height: 100%;
}

.selfpop_mapouter {
    height: 100% !important;
}

.or-seperator-row {
    position: absolute;
    right: -9px;
    z-index: 1;
    top: 6px;
}

.or-seperator-row .or-seperator {
    position: relative;
    padding: 25px 0px 7px 0;
    font-size: 16px;
    font-weight: 300;
}

.or-seperator-row .or-seperator span {
    padding: 7px 0px;
}

.or-seperator-row .or-seperator:after {
    height: 82px;
    width: 1px;
    background: #e5cda6;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.self_pop_item_loc p {
    color: #595656;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 8px;
}

.self_pop_item_loc .loc_btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    max-width: 170px;
    margin: 0 auto;
    min-width: 288px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 23px;
    border-radius: 10px;
    height: 62px;
}

.self_pop_col_right h4 {
    font-size: 16px;
    font-weight: 700;
    color: #595656;
    text-align: left;
    margin-left: 30px;
}

.self_outlet {
    margin-top: 15px;
    height: calc(100% - 105px);
}

.self_outlet h2 {
    color: #000000;
    font-size: 17px;
    text-align: left;
    margin: 0px 0 10px 5px;
    font-weight: 500;
    font-family: 'Lato';
}

.self_outlet_inner {
    padding-left: 0;
    list-style: none;
    text-align: left;
    overflow-y: auto;
    margin: 0 0 14px;
    max-height: 190px;
}

.self_outlet_inner li {
    margin-bottom: 12px;
}

.self_outlet_inner li:last-child {
    margin-bottom: 0;
}
.self_outlet_inner li a {
    background: #f8f8f8;
    padding: 10px 15px;
    display: block;
    font-size: 15px;
    color: #595656;
    position: relative;
    overflow: hidden;
    border-radius: 3px;
}
.self_outlet_inner li a:hover,
.self_outlet_inner li.active a {
        background: #a99c95;
        color: #fff;
}

.self_outlet .btn.cnt_btn {
    width: 100%;
    background: #72624f;
    margin: 0;
}

.self_outlet .btn.cnt_btn:hover {
    background: #333;
}

.self_pop_locbx .form-group .form-control {
    width: 100%
}

.self_outlet .button {
    margin: 0;
    width: 100%;
    display: block;
    
}

.self_pop_locbx {
    margin: 0 0 20px 0
}

.white-popup.login-popup,
.white-popup.signup-popup,
.white-popup.signup-corparateone-popup,
.white-popup.signup-corparatetwo-popup,
.white-popup.guest-popup {
    background: inherit;
    max-width: 1086px;
}

.pop-whole-lhs-inner {
    text-align: center;
    max-width: 318px;
}

.pop-whole {
    position: relative
}

.login-popup .mfp-close,
.signup-popup .mfp-close, .forgot-password-popup .mfp-close,
.signup-corparateone-popup .mfp-close,
.signup-corparatetwo-popup .mfp-close,
.guest-popup .mfp-close, .msc-pop .mfp-close  {
    width: 30px;
    height: 30px;
    line-height: 30px;
    right: 49px;
    top: 0;
    font-size: 0;
    color: #afafaf;
    margin: 0;
    opacity: 1;
}
.login-popup .mfp-close, .signup-popup .mfp-close, .forgot-password-popup .mfp-close, 
.mfp-close-btn-in .delevery_popup .mfp-close, .msc-pop .mfp-close{
    background: #000; right:5px; top: 5px; border-radius:5px;min-width: inherit;
}
.login-popup .mfp-close:hover,
.signup-popup .mfp-close:hover, .forgot-password-popup .mfp-close:hover,
.signup-corparateone-popup .mfp-close:hover,
.signup-corparatetwo-popup .mfp-close:hover,
.guest-popup .mfp-close:hover, .msc-pop .mfp-close:hover {
    background: rgba(24, 24, 24, 0.9);
}

.login-popup .mfp-close:after,
.signup-popup .mfp-close:after, .forgot-password-popup .mfp-close:after,
.signup-corparateone-popup .mfp-close:after,
.signup-corparatetwo-popup .mfp-close:after,
.guest-popup .mfp-close:after, .msc-pop .mfp-close:after {
    content: "";
    background: url(../images/crosslg.png) no-repeat;
    width: 14px;
    height: 14px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 1;
}


.popup-footer .btn {
    display: block;
    max-width: 271px;
    margin: 0 auto;
    background: #2d4486;
    text-transform: inherit;
    border-radius: 7px;
    border: 0;
}

.popup-footer .btn:hover {
    background: #333;
    color: #fff;
}

.popup-footer .btn i {
    padding-right: 15px;
}

.popup-footer span {
    color: #000;
    font-size: 16px;
    margin: 0 0 3px;
}

.popup-footer .guest_btn {
    background: #70afa7;
    color: #fff;
}

.popup-footer .guest_btn:hover,
.popup-footer .guest_btn:focus {
    background: #333333;
    color: #fff;
}

.pop-whole:after {
    display: table;
    clear: both;
    content: ""
}



.loginsep-popup .mfp-close:before,
.loginsep-popup .mfp-close:after {
    height: 20px;
    background-color: #000
}

.loginsep-popup .mfp-close {
    right: 20px;
    top: -6px
}



.white-popup.processing {
    max-width: 768px;
}

.process_inner {
    position: relative;
}

.process_col {
    position: relative;
    margin-bottom: 15px;
}

.process_left {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
}

.process_right {
    padding-left: 60px;
}

.process_right h5 {
    color: #b0aeae;
    margin-bottom: 5px;
}

.process_right p {
    margin-bottom: 0;
    font-size: 18px;
}

.redeem_popsec {
    max-width: 600px;
}

.redeem_bansec {
    position: relative;
    overflow: hidden;
}

.redeem_bansec .innerproduct-item-image {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 300px;
}

.redeem_popsec .pouup_in {
    padding: 40px 50px 40px;
}

.redeem_popsec .title3 {
    font-size: 24px;
}

@-webkit-keyframes bouncein {
    0% {
        opacity: 0;
        -webkit-transform: translate(0px, -25px);
    }
    100% {
        opacity: 1;
        -webkit-transform: translate(0px, 0px);
    }
}

@keyframes bouncein {
    0% {
        opacity: 0;
        -webkit-transform: translate(0px, -25px);
        transform: translate(0px, -25px);
    }
    100% {
        opacity: 1;
        -webkit-transform: translate(0px, 0px);
        transform: translate(0px, 0px);
    }
}

.outlet-list {
    max-height: 551px;
    overflow-y: auto;
}

.outlet-list > ul {
    padding: 20px 15px;
}

.outlet-list > ul > li {
    margin-bottom: 20px;
}

.outlet-list li {
    list-style: none;
    padding-left: 38px;
}

.outlet-list li span {
    display: block;
    font-weight: 700;
}

.outlet-list li .submenu {
    padding: 0;
}

.outlet-list li .submenu li {
    margin-top: 10px;
    list-style: none;
    padding: 0;
    line-height: normal;
    padding-left: 3px;
}

.outlet-list li .submenu li a {
    font-weight: 700;
    display: block;
    color: #272626;
    position: relative;
    padding-left: 14px;
    font-size: 11px;
}

.outlet-list li .submenu li a:hover {
    color: #ecdfcc
}

.outlet-list .custom_checkbox {
    position: absolute;
    left: 0;
    font-size: 0;
}

.outlet-list .custom_checkbox span:before {
    width: 16px;
    height: 17px;
    border-radius: 2px;
    -webkit-border-radius: 2px;
    border: 1px solid #e6e6e6;
}

.outlet-list .custom_checkbox input[type=checkbox]:checked + span:before {
    background-position: -216px -189px;
}

.outlet-list li .submenu li .mapcanvas_close {
    position: absolute;
    padding: 0;
}
.outlet-list li .submenu li .mapcanvas_close:before {
    display: none;
}

/*--My Account--*/
.mainacc_menusec {
    margin: 0 0 40px;
    display: flex;
	flex-wrap: wrap;
}
.mainacc_menuout {
    width: 215px;
    display: inline-block;
    position: -webkit-sticky;
    position: sticky;
    top: 112px;
    height: 100%;
}
.mainacc_menucontent {
    width: calc(100% - 215px);
    width: -webkit-calc(100% - 215px);
    display: inline-block;
    min-height: 100vh;
    padding: 35px 40px;
	background:#fff;
	box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.1);
}
.mainacc_menulist {padding: 0; list-style: none; margin: 0; box-shadow:-4px 8px 16px -2px rgb(0 0 0 / 18%);
}
.mainacc_menulist > li {position: relative;}
.mainacc_menulist > li > a {
    background: #f3f2f2;
    color: #000;
    font-size: 15px;
    padding: 14px 15px 14px;
    display: block;
    position: relative;
    text-align: left;
    font-weight: normal;
    text-transform: uppercase;
}
.mainacc_menulist > li:hover > a, .mainacc_menulist > li.active > a { background: #523628; color: #fff;}
.mainacc_menulist > li:hover #masterCount, .mainacc_menulist > li.active #masterCount {
    background: #fff;
    height: 20px;
    display: inline-block;
    border-radius: 50%;
    text-align: center;
    color: #000;
}	
/*
.mainacc_menulist > li:before {
    content: ""; position: absolute; width: 100%; height: 4px; bottom: -4px; left: 0; background: #ffa700;
    visibility: hidden; transform: scaleX(0); transition: .25s linear;
}
.mainacc_menulist > li.active:before,
.mainacc_menulist > li:hover:before {visibility: visible; transform: scaleX(1);}
*/
.mbacc_mslidersec {display: none;}
.mainacc_moblink {display: none;}
.myacc_hea {position: relative; margin: 0 0 14px;}
.myacc_hea::after,
.myacc_pg::after {
    display: block; content: ""; clear: both;
}
.myacc_hea h3 {font-size: 27px; margin-bottom: 5px;}
.myacc_hea p {margin-bottom: 25px; color: #5b5b5b;}
.myacc_hea_inner {margin-top: 8px;}

.account_sec {display: -webkit-box; display: -ms-flexbox; display: flex;}
.accmenu_sec {background: #e8eaec; width: 265px; position: relative; color: #fff;}
.accprofile {padding: 26px 10px 0px;}
.accprofile_img {
    width: 140px; height: 140px; border: 2px solid rgba(0, 0, 0, 0.2); line-height: 134px; border-radius: 50%;
    margin: 0 auto 0; overflow: hidden;
}
.accprofile_img img {vertical-align: middle;}
.accprofile_info p {font-size: 15px; color: #000; font-weight: normal; margin: 0;}
.accpoints_sec {padding: 15px 7px 0px;}
.accpoint_tt span {background: #fff; padding: 0 4px; position: relative; display: inline-block;}
.accearn_points {background: rgba(0, 0, 0, 0.5); padding: 15px 15px 10px;}
.accearn_pointtop {position: relative; padding-left: 52px;}
.accearn_pointtop i {position: absolute; left: 0; top: 7px;}
.accearn_pointtop h4 {font-size: 15px; margin: 0; color: #fff;}
.accearn_pointtop span {font-size: 45px; display: inline-block; line-height: 0.8; color: #ffc800;}
.accearn_pointtop sub {font-size: 60%; bottom: 0;}
.accearn_points p {font-size: 13px; margin: 0;}
.accpoint_ec {position: absolute; right: 13px; bottom: 8px;}
.accsec_right {
    width: calc(100% - 0px); width: -webkit-calc(100% - 0px); display: inline-block; padding: 0px 10px;
}
.accsec_right h1 {font-size: 32px; color: #040507; margin-bottom: 15px; text-transform: uppercase}
.accsec_right .welcome-title h1 {line-height: 1; display: flex; justify-content: center; align-items: center; margin:0 0px 20px; font-weight: bold}
.accsec_right .welcome-title h1 img{margin: 0px 0 0 15px; position: relative; top: 2px;}
.accsec_right .welcome-title h1 > div {text-align: center;}
.accsec_right .welcome-title h1 > div span {color: #282828; font-size: 20px; font-weight: bold; text-transform: capitalize }
.accsec_right p {color: #000000; font-size: 17px; text-align: left;}
.acc-inform .form-group {margin-bottom: 10px;}
.other-add-body {
    height: 175px; border: 1px solid #f1efef; border-radius: 7px; padding: 16px 14px 0px 14px; background: #fff;
}
.other-add-row {
    display: -webkit-box; display: -ms-flexbox; display: flex; -ms-flex-wrap: wrap; flex-wrap: wrap;
    -webkit-box-align: center; -ms-flex-align: center; align-items: center; list-style: none;
    width: 100%; padding-left: 0; margin-bottom: 0;
}
.other-add-row li {
    width: 32.1875%; margin-right: 1.6666666666666666%; position: relative; margin-bottom: 10px;
}
.other-add-row li:nth-of-type(3n+3) {
    margin-right: 0;
}
.other-add-row li a {
    background: #f9f9f9;
    padding: 15px 15px;
    display: block;
    border: 1px solid #f1efef;
    color: #252525;
    font-size: 13px;
    border-radius: 7px;
    cursor: default;
}
.other-add-row span {
    position: absolute;
    top: 6px;
    right: 8px;
    background: url(../images/spirite_whole.png) no-repeat;
    width: 10px;
    height: 10px;
    background-position: -35px -315px;
    cursor: pointer;
    -webkit-transition: all 400ms;
    transition: all 400ms;
}
.other-add-row span:hover {opacity: 0.5;}
.setting_menu_list {position: absolute; left: 0; right: 0; bottom: 0;}
.setting_menu_list > ul {list-style: none; padding: 0; margin-bottom: 0;}
.setting_menu_list > ul li {position: relative;}
.setting_menu_list > ul > li > a {
    padding: 12px 10px 12px 20px;
    background: #e8eaec;
    color: #151515;
    display: block;
    text-transform: uppercase;
    border-bottom: 2px solid #fff;
    margin-bottom: 0;
    
}
.setting_menu_list > ul li a:hover {
    background: #6e1d24;
    color: #fff;
}
.setting_menu_list > ul li:last-child a {
    border-bottom: 0;
}
.setting_menu_list > ul li:hover .myacc_dropmenu {
    opacity: 1;
    visibility: visible;
}
.myacc_dropmenu {
    position: absolute;
    left: 105%;
    top: -20px;
    list-style: none;
    padding-left: 0;
    background: rgba(0, 0, 0, 0.77);
    padding: 30px 35px;
    z-index: 1;
    width: 284px;
    border-radius: 7px;
    box-shadow: 0px 0px 35px 0px rgba(0, 0, 0, 0.35);
    -webkit-box-shadow: 0px 0px 35px 0px rgba(0, 0, 0, 0.35);
    opacity: 0;
    visibility: hidden;
}
.myacc_dropmenu::after {
    position: absolute;
    content: "";
    border-right: 14px solid #3b3b3b;
    border-top: 14px solid transparent;
    border-bottom: 14px solid transparent;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    left: -7px;
}
.myacc_dropmenu::before {
    content: "";
    position: absolute;
    left: -13px;
    top: 0;
    background: #ccc;
    width: 20px;
    height: 100%;
    opacity: 0;
}
.myacc_dropmenu li a {color: #fff;}
.myacc_dropmenu .tg-list {position: absolute; top: 0; right: -12px; padding-top: 0;}
.main_tabsec_inner> .nav-tabs>li {width: 33.17%; margin-right: .241545%; position: relative;}
.main_tabsec_inner> .nav-tabs>li:last-child {margin-right: 0;}
.main_tabsec_inner> .nav-tabs {text-align: center;}
.main_tabsec_inner> .nav-tabs>li> a {
    background: #bfbebe;
    margin: 0;
    border-radius: 0;
    border: 0;
    color: #fff;
    font-size: 16px;
    font-weight: normal;
    text-transform: uppercase;
    padding: 17.5px 91px 17.5px 15px;
}
.main_tabsec_inner> .nav-tabs>li.active>a {border: 0; background: #0b0405; color: #fff;}
.main_tabsec_inner> .nav-tabs>li> a > span > b {font-weight: normal;}

.count_num_t {
    background: #a7a7a7;
    width: 70px;
    height: 100%;
    text-align: center;
    font-style: normal;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: absolute;
    right: 0;
    top: 0;
    color: #0b0405;
    font-size: 20px;
    border-top-left-radius: 3.2em 5em;
    border-bottom-left-radius: 3.2em 5em;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.btn.spirite_image {
    height: 56px;
}

.spirite_image {
    display: inline-block;
    vertical-align: middle;
    position: relative;
}

.spirite_image i {
    margin-right: 10px;
    vertical-align: middle;
    display: inline-block;
}

.filter_tabsec .nav-tabs {
    width: 100%;
    padding: 0;
    border-bottom: 0;
}

.filter_tabsec .nav-tabs>li {
    width: 50%;
    float: left;
    border-bottom: 5px solid #f0f0f0;
    position: relative;
}

.filter_tabsec .nav-tabs>li>a span {
    display: inline;
}

.filter_tabsec .nav-tabs>li>a:after {
    height: 5px;
    bottom: -5px;
    max-width: 253px;
    margin: auto;
    width: 100%;
}

.tab-pane {
    display: none
}

.tab-pane.active {
    display: block
}

.ord-body::after {
    display: block;
    clear: both;
    content: "";
}

.ord-pagination-col {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    margin: 0 0 10px 0;
}

.ord-pagination-col::after {
    display: block;
    content: "";
    clear: both;
}

.ord-pagination-col span {
    font-size: 12px;
    text-transform: uppercase;
    font-weight:bold;
}

.ord-pagination-col p {
    font-size: 12px;
    text-transform: uppercase;
    margin-bottom: 0;
    font-weight:bold;
}

.ord-pagination-col .form-group {
    margin-bottom: 0;
    padding-right: 5px;
}

.ord-pagination-col .form-group select {
    background: #d7d4d8;
    border: 0;
    font-weight:bold;
}

.cur-order-body {
    width: 100%;
    clear: both;
}

.cur-order-body::after {
    display: block;
    clear: both;
    content: "";
}

.cur-order {
    padding: 10px 0;
    border-bottom: 1px solid #efefee;
    position: relative;
}

.click_view {
    position: absolute;
    right: 21px;
    color: #474747;
    padding: 0;
    font-weight: 700;
    font-size: 12px;
}

.cur-order-del {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    max-width: 450px;
    list-style: none;
    padding-left: 0;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0;
}

.cur-order-del li {
    font-weight: 700;
    font-size: 16px;
    position: relative;
    padding-left: 20px;
}

.cur-order-del li a.btn {
    min-width: 132px;
    height: 35px;
    border-radius: 10px;
    font-size: 16px;
    padding: 8px 5px;
    color: #0b0405;
    line-height: normal;
    margin-bottom: 0;
}
.cur-order-del li a.btn:hover {
    color: #fff;
}
.cur-order-del li span {
    padding-left: 5px;
}
.cur-order-del-icon {
    background: url(../images/spirite_whole.png) no-repeat;
    width: 16px;
    height: 16px;
    display: inline-block;
}
.calender-icon {
    background-position: -215px -258px;
}
.time-icon {
    background-position: -249px -257px;
}
.cur-ord-rec {
    list-style: none;
    float: right;
    max-width: 400px;
    padding-right: 19px;
    margin-bottom: 0;
    padding: 8px 19px 0 0;
}
.cur-ord-rec li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
.cur-ord-rec li a {
    font-size: 14px; text-transform: uppercase; text-decoration: underline; color: #2e2e2c;
}
.cur-ord-rec li a:hover {color: #909090;}
.cur-ord-rec li span {
    display: inline-block;
    width: 1px;
    height: 100%;
    background: #2e2e2c;
    margin: 0 20px;
    position: relative;
}
.cur-ord-rec li span::after {
    content: "";
    background: #2e2e2c;
    height: 14px;
    width: 1px;
    display: block;
    position: absolute;
    top: 2px;
}
.order-cat-hea {background: #fff; padding: 8px 35px; max-width: 331px; margin-bottom: 10px;}
.order-cat h2 {font-size: 20px; color: #0b0405; margin-bottom: 0;}
.order-cat-table {padding: 0 35px;}
.order-cat-table h4 {color: #474747; font-size: 16px; font-weight: 700; margin-bottom: 11px;}
.order-cat-table table {border: 0; max-width: 364px; margin-bottom: 10px;}
.order-cat-table table td {font-weight: 300; font-size: 16px; color: #3d3d3d; padding: 0 5px;}
.order-cat-table table td.p_name {padding-left: 0;}
.p_name {width: 247px;}
.p_val {width: 80px;}
.ord-amount {padding: 10px 0 0 35px; border-top: 1px solid #efefee;}
.ord-amount .row {margin-right: -20px;}
.ord-amount-item {
    max-width: 364px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}
.ord-amount h2 {
    font-size: 27px;
    color: #202020;
}
.ord-rec-sec {
    list-style: none;
    padding-left: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    text-align: right;
    float: right;
    width: 649px;
}
.ord-rec-sec li {
    color: #aba9a9;
    margin-bottom: 0;
    height: 39px;
    padding: 10px 15px;
    max-width: 184px;
    display: block;
    text-align: center;
    text-transform: uppercase;
    font-size: 14px;
    width: 25%;
    line-height: 23px;
    overflow: hidden;
}
.ord-rec-sec li.completed {
    background: #ecdfcc;
    color: #3d3d3d;
}
.ord-number {
    width: 22px;
    height: 22px;
    line-height: 22px;
    display: inline-block;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    font-weight: 700;
    margin-right: 6px;
}
.ord-rec-sec li.completed .ord-number {
    color: #fff;
    background: #0b0405;
}
.rewards-tab-section .main_tabsec_inner {
    padding: 6px 72px 50px 72px;
}
.congrats::after {display: block; content: ""; clear: both;}
.congrats-inner, .promo-form {margin: 0px auto 40px; text-align: center; padding: 40px 15px; background:#ece4e0;border:1px solid #e5e4e4}
.congrats-inner h3 {font-size: 16px; color: #000; margin-bottom: 5px; letter-spacing: 3px; text-transform: capitalize; font-family: 'latoblack';text-transform: uppercase;}
.congrats-inner h2 {    font-size: 36px;  color: #000; margin-bottom: 0; font-weight: bold; text-transform: uppercase;font-family: 'latoblack';}

.rewards-tab-section .ord-amount {border-top: inherit; padding-top: 0;}
.rewards-tab-section .order-cat-hea {margin-bottom: 7px;}
.earned_points {max-width: 300px; padding-left: 0; list-style: none; float: right; padding-right: 24px;}
.earned_points li span {
    color: #bfbebe;
    text-transform: uppercase;
    font-size: 18px;
    display: inline-block;
    margin-right: 5px;
}
.earned_points li h2 {
    color: #b69a6b;
    font-size: 24px;
    text-transform: uppercase;
    margin-bottom: 8px;
}
.redeem_popsec {
    max-width: 700px;
    border-radius: 0px;
    -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.34);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.34);
    margin: 50px auto;
}
.redeem_popsec .promo-earned-content {
    width: 75%; padding: 50px 10px 40px;
}

.redeem_bansec {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}
.redeem_popsec .redeem_bansec .promo-popup-image {
    width: 240px;
    height: 240px;
    margin: 0 auto;
}
.redeem_popsec .redeem_bansec .promo-earned-content {width: 100%; padding: 30px 30px 30px; text-align: center;margin: 0 auto; }
.redeem_popsec .redeem_bansec .promo-popup-image img { height: 100%; width: 100%; object-fit: cover; object-position: center;}

.redeem_popsec .promo-earned-col-center .innerproduct-item-image {width: 25%; height: 270px;}
.redeem_popsec .promo-earned-content h4 {font-size: 32px;max-height: inherit;font-weight: bold}
.redeem_popsec .promo-earned-content h2 {font-size: 42px; color: #3c3c3c;}
.redeem_popsec .promo-earned-content p {margin: 0 0 15px;}
.redeem_popsec .abc_code {padding: 20px 0 0px;}
.redeem_popsec .promo-earned-content h2 span sup {font-size: 26px;}
.redeem_pop_image img {width: 100%; -o-object-fit: cover; object-fit: cover;}
.redeem_popsec .pouup_in {padding: 30px 55px 30px;}

.redeem_popsec .pouup_in p {
    font-size: 16px;
    color: #000;
    line-height: 24px;
}
.redeem_popsec .button {
    color: #fff;
    display: block;
    max-width: 220px;
    margin: 0 auto; background: #523628
}
.redeem_popsec .button:hover {
    background: #000;
    color: #fff;
}


.input_area {
    position: relative;
}

.input_area {
    position: relative;
}

.cal_icon {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    height: 100%;
    width: 45px;
    pointer-events: none;
}

.cal_icon b {
    display: block;
    width: 100%;
    height: 100%;
    background: url(../images/arrow-down.png) no-repeat center;
}

.pop-whole .kep-login-facebook.metro, .loginBtn--google {
    display: block;
    margin: 0 auto;
    background: #4064b0;
    text-transform: inherit;
    border-radius: 5px;
    width: 100%;
    padding: 13px 15px;
    font-size: 17px;
    font-weight: normal;
        display: flex;
    justify-content: center;
    align-items: center;
    border:1px solid #4064b0;
    letter-spacing: 0; color: #fff;font-family: 'Lato';
}
.pop-whole .kep-login-facebook.metro i, .loginBtn--google i{ font-size: 25px;margin-right: 25px; }
.loginBtn--google{ background: #df3412; border-color: #df3412; margin-bottom: 5px; }

.pop-whole .kep-login-facebook.metro:hover, .loginBtn--google:hover { background: #523628; border-color: #523628}

.combo-input-label {
    height: 100%;
    border: none;
    color: #000;
    font-size: 16px;
    text-align: center;
    margin: 0;
    padding: 0px;
    background: #fff;
    border-radius: 0px;
    border-right: 0px solid transparent;
    border-left: 0px solid transparent;
    line-height: 45px;
}

.compo_acc_innerdiv {
    padding: 10px 20px 0px 20px;
}

.gloading_img {
    background: rgba(255, 255, 255, 0.8);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 9;
}

.gloading_img:after {
    content: "";
    width: 35px;
    height: 35px;
    border: 4px solid rgba(30, 29, 29, 0.49);
    border-top-color: #1e1d1d;
    border-radius: 50%;
    position: relative;
    -webkit-animation: loader-rotate 1s linear infinite;
    animation: loader-rotate 1s linear infinite;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
}

@-webkit-keyframes loader-rotate {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@-webkit-keyframes loader-rotate {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@keyframes loader-rotate {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

.load_warpper {
    position: relative;
}

.load_warpper #dvLoading {
    position: absolute;
}

#dvLoading {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 10000;
    left: 0;
    top: 0;
    margin: 0;
    background: #fff url(../images/loading.gif) no-repeat center;
    background-size: 60px;
    -webkit-background-size: 60px;
    bottom: 0;
    right: 0;
}

#inner_loading {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 10000;
    left: 0;
    top: 0;
    margin: 0;
    background: rgba(255, 255, 255, 0.9) url(../images/loading_inner.gif) no-repeat center;
    bottom: 0;
    right: 0;
}

.popup_close {
    position: absolute;
    z-index: 1;
    top: 10px;
    right: 10px;
    cursor: pointer;
    background: #fff;
    opacity: 1;
    padding: 10px;
    border-radius: 5px;
    width: 40px;
    z-index: 1
}

.back_arrow {
    position: absolute;
    top: 0;
    left: 0;
    background: #fff;
    width: 43px;
    height: 49px;
    text-align: center;
    line-height: 49px;
    cursor: pointer;
    z-index: 4;
}

.back_arrow:after {
    content: "";
    background: url(../images/spirite_whole.png) no-repeat;
    width: 26px;
    height: 26px;
    background-position: -160px -16px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
}


.react-datepicker-popper {
    z-index: 11 !important;
}

.scrollup {
    width: 40px;
    height: 40px;
    position: fixed;
    bottom: 50px;
    right: 15px;
    background: #523429;
    color: #fff;
    display: inline-block;
    text-align: center;
    line-height: 40px;
    font-size: 12px;
    transition: all 0.4s ease 0s;
    -webkit-transition: all 0.4s ease 0s;
    cursor: pointer;
    z-index: 111;
}

.scrollup:hover {
    background: #000;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

.scrollup:hover .fa {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

.scrollup .fa {
    transition: all 0.4s ease 0s;
    -webkit-transition: all 0.4s ease 0s;
}

.cms-page {
    margin: 0px 0 30px;
}

.cms-page .container {
    background: #fff;
    padding: 40px 20px 30px;
}

.cms-page h3 {
    font-size: 27px;
    color: #040507;
}

.contactus_wrapper form.form_sec {display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-orient: horizontal;-webkit-box-direction: normal;-ms-flex-flow: row wrap;flex-flow: row wrap;}

.contact_left p a {
    color: inherit;
}

.contact_left p a:hover {
    color: #b69a6b;
}

.contact_left p.open-hours {
    border-top: 1px solid #eaeaea;
    padding-top: 25px;
    margin-top: 26px;
}

.contactus_pg .contact_form h3 {
    margin: 0 0 5px;
}

.contact_form .help-block {
    margin: 0 0 24px;
}

.product_tag {
    list-style: none;
    padding-left: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 5px 0 10px;
}

.product_tag li {
    padding-right: 10px;
}

.product_tag li b {
    margin-right: 10px;
}

.product_tag li a {
    display: inline-block;
    color: #5b5b5b;
    font-weight: 700;
}

.catogry_row_fixed .bakery_slider {
    position: fixed;
    top: 96px;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 9;
    margin: auto;
    text-align: center;
    -webkit-transition: all 400ms;
    transition: all 400ms;
    max-width: 1260px;
    padding-top: 0 !important;
}

.tbars .bakery_row_fixed {
    top: 171px;
}

.bakery_row.bakery_row_fixed .slick-next {
    right: 0;
}

.chosen_file {
    position: relative;
    display: inline-block;
}

.chosen_file input[type="file"] {
    position: absolute;
    right: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
}

.chosen_file label {
    font-size: 16px;
    font-weight: 700;
    white-space: nowrap;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    padding: 11.5px 15px;
    outline: 0;
    background: #ecdfcc;
    max-width: 160px;
    border: 1px solid #e6e5e5;
    text-overflow: ellipsis;
    margin: 0;
    pointer-events: none;
}

.catering .ttsplitter span {
    background: #fff;
}

.catering .ttsplitter {
    margin: 0 auto 20px;
}

.order_nav {
    position: relative;
}


.order_nav > .nav-tabs {
    position: relative;
    border: 0;
    padding: 0;
    list-style: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    z-index: 1
}

.order_nav > .nav-tabs>li {
    margin: 0;
    text-align: center;
    border-left: 2px solid transparent;
}

.order_nav > .nav-tabs>li.active>a,
.order_nav > .nav-tabs>li.active>a:focus,
.order_nav > .nav-tabs>li.active>a:hover {
    border: 0;
    background: #ffa700;
    color: #fff;
}

.order_nav > .nav-tabs>li>a {
    background: #eeeeed;
    margin: 0px 1px;
    border-radius: 0;
    border: 0;
    color: #181818;
    font-size: 18px;
    text-transform: uppercase;
    padding: 15px 23px;
    
    display: flex;
    align-items: center;
}

.nos {
    background: #473f3c;
    border-radius: 100%;
    padding: 2px 2px;
    margin-right: 7px;
    color: #fff;
    border: 3px solid #0b0405;
    width: 32px;
    height: 32px;
    display: inline-block;
    font-size: 14px;
    vertical-align: middle;
    font-weight: bold;
    line-height: 22px;
}

.order_nav .tb-padding {
    padding: 60px 0;
}

.container_720 {
    margin: 0 auto;
    max-width: 720px;
}

.choose_location {
    position: relative;
    border: 2px solid #dddddd;
    border-radius: 5px;
    padding: 50px 40px 30px;
    margin-bottom: 50px;
    background: #fff;
}

.chosen-alert-cnt {
    position: absolute;
    bottom: -27px;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
}

.chosen-alert-cnt span {
    background: #fb0a37;
    padding: 6px 15px;
    text-align: center;
    color: #fff;
    font-size: 13px;
    font-weight: 700;
    box-shadow: 0px 3px 4px rgba(1, 1, 1, 0.17);
    -webkit-box-shadow: 0px 3px 4px rgba(1, 1, 1, 0.17);
}

.choose_location h4 {
    color: #3d3d3d;
    font-size: 19px;
    font-weight:bold;
    margin: 0 0 5px 0
}





.divide {
    width: 90%;
    background: #c7c3c3;
    height: 2px;
    margin: 10px 0px 10px 40px;
}

.date_list {
    margin-bottom: 30px;
    position: relative;
    padding: 40px 120px 30px;
    border: 2px solid #dddddd;
    background: #fff;
    border-radius: 6px;
}

.date_list:after {
    content: "";
    display: table;
    clear: both;
}

.datelist_bracktime h3,
h3.choose-eventtype {
    text-align: center;
    font-size: 26px;
    margin: 0 0 20px 0;
    
}

.ordering-more {
    text-align: center
}

.ordering-more .button.no {
    background: #000;
}

.ordering-more .button.yes {
    background: #ffa700;
}
.more-time-slots{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 476px;
    margin: 0 auto;
}
.more-time-slots a{
    width: 49%;
    margin: 0;
    padding: 17px 5px 16px;
    border-radius: 5px;
        font-size: 19px;
}

#nobtn_id {
    margin-right: 5px
}

.ordering-more .button.yes:hover,
.ordering-more .button.no:hover {
    background: #333;
}

.ordering-more h3 {
    color: #3d3d3d;
    font-size: 16px;
    font-weight:bold;
    margin: 0 0 15px 0;
    
    
}

.date_list h4 {
    color: #3d3d3d;
    font-size: 16px;
    font-weight:bold;
    margin: 0 0 15px 0;
     
}

.venue-details-inner h3 {
    display: block;
    font-size: 14px;
    color: #3c3a3a;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 5px;
    padding-left: 5px;
}

.catering_days_ul:after {
    content: "";
    display: table;
    clear: both;
}

.catering_days_ul {
    list-style: none;
    margin: 0 -2px;
    padding-left: 0;
    padding-bottom: 0;
}

.catering_days_ul li {
    padding: 0 2px;
    width: 20%;
    float: left;
    margin-bottom: 5px;
    height: 70px;
}

.catering_days_ul li dl {
    padding: 12px 5px;
    background: #fff;
    border: 2px solid #d9d9d9;
    border-radius: 5px;
    display: block;
    text-align: center;
    cursor: pointer;
    height: 70px;
    -webkit-transition: all 0.1s ease-in;
    transition: all 0.1s ease-in;
    position: relative;
    overflow: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column wrap;
    flex-flow: column wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.catering_days_ul li dl.active {
    background: #98684a;
    border-color: #000000;
}

.catering_days_ul li dl.active dt {
    color: #fff;
}

.catering_days_ul li dl.active dd {
    color: #fff;
}

.catering_days_ul li dl:hover {
    background: #98684a;
}

.catering_days_ul li dl:hover dt {
    color: #fff;
}

.catering_days_ul li dl:hover dd {
    color: #fff;
}

.catering_days_ul li dl.cl-next-days:hover span {
    color: #000;
}

.catering_days_ul li dl.cl-next-days {
    background: #a1a1a1;
}

.catering_days_ul li dl.cl-next-days span {
    color: #fff;
    font-size: 14px;
    text-transform: uppercase;
    
    text-transform: uppercase;
    display: block
}

.catering_days_ul li dl dt {
    font-size: 14px;
    text-transform: uppercase;
    color: #9d9d9d;
    line-height: 14px;
    
    margin: 0 0 5px 0
}

.catering_days_ul li dl dd {
    font-weight: normal;
    font-size: 18px;
    text-transform: uppercase;
    color: #3c3a3a;
    line-height: 1;
    
}

.catering_days_ul .tag {
    color: #fff;
    background: #060606;
    padding: 5px 5px;
    position: absolute;
    left: -10px;
    top: 20px;
    transform: rotate(-18deg);
    -webkit-transform: rotate(-18deg);
    width: 120%;
    line-height: normal;
    text-align: center;
    height: 27px;
    font-size: 12px;
    
    text-transform: uppercase
}

.catering_days_ul li dl.time_inner dd {
    font-size: 18px;
}

.res-datetime-picker p {
    margin: 15px 0px 40px;
    font-style: italic;
    font-size: 12px;
    font-weight: bold;
}

.res-datetime-picker .tag span {
    display: inline-block;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 14px;
    text-transform: uppercase;
}

.time_slot {
    margin-bottom: 10px;
    position: relative;
    padding: 30px 120px 30px;
    border: 2px solid #ecdfcc;
    background: #ffffff;
    text-align: center;
    border-radius: 6px;
}

.time_slot .order-btn {
    padding: 20px 0px 30px;
}

.time_slot .order-btn .no {
    background: #626161;
    height: 55px;
    font-size: 22px;
    padding: 10px 80px;
    border-radius: 4px;
}

.time_slot .order-btn .yes {
    background: #72624f;
    height: 55px;
    font-size: 22px;
    padding: 10px 80px;
    border-radius: 4px;
}

.time_slot h4 {
    font-weight: bold;
    color: #3d3d3d;
    font-size: 17px;
    margin: 0px;
}

.time_slot .add_time .label {
    color: #3d3d3d;
    font-size: 18px;
    margin: 0px;
    padding: 0px 0px;
    width: 240px;
    float: left;
    text-align: right;
    line-height: 38px;
    height: 38px;
}

.add_time {
    margin: 10px 30px 45px 0px;
}

.time_slot .time {
    padding: 0px 0px 20px 0px;
}

.time_slot .time:after {
    content: "";
    display: table;
    clear: both;
}

.time_slot .time label {
    font-weight: bold;
    color: #3d3d3d;
    font-size: 17px;
    padding: 0px 0px 0px;
    text-transform: uppercase;
    line-height: 40px;
    margin: 0px;
}

.time_slot .time .form-control,
.order-select-brktime ul .time-cls .breaktime_listcls {
    height: 38px;
    padding: 6px 0px;
    border: 0
}

.order-select-brktime ul .custom-select-inner {
    padding: 0 10px 0 40px;
    background: #fff;
    width: 180px;
    height: 38px;
}

.order-select-brktime ul .time-cls .form-group:after {
    height: 38px;
    top: 0;
    right: 0
}

.order-break {
    padding: 30px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.order-break .label {
    font-size: 17px;
    font-weight:bold;
    margin: 0 10px 0 0
}

.catering_hall_list {
    padding: 30px 0 10px
}

.catering_hall_list p {
    font-size: 17px;
    margin: 0px;
}

.catering_hall_list .form-group {
    margin: 25px 0 25px;
}

.function-rooms ul {
    margin: 0;
    padding: 20px 0 0 0;
    list-style: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.function-rooms ul li {
    width: 32%;
    margin: 0 2% 20px 0;
    text-align: center
}

.function-rooms ul li:nth-child(3n) {
    margin-right: 0
}

.view-rooms h4 {
    color: #000;
    
    font-size: 15px
}

.function-rooms ul li a:hover h4,
.function-rooms ul li.active h4 {
    color: #fff
}

.view-rooms span {
    color: #4e4e4e;
    display: block;
    clear: both;
    position: relative
}

.function-rooms ul li a:hover span,
.function-rooms ul li.active span {
    color: #fff
}

.function-rooms ul li a {
    height: 100%;
    padding: 5px 5px 15px;
    background: #e0e0e0;
    display: block
}

.function-rooms ul li a:hover,
.function-rooms ul li.active a {
    background: #ffa700
}

a.view-rooms img {
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    margin: 0 0 15px 0;
}

.show {
    display: block;
}

.cedele-location label {
    font-weight: bold;
    color: #3d3d3d;
    font-size: 16px;
    line-height: 35px;
}

.location-list {
    height: 270px;
    margin: 0px -15px 0px 0px;
}

.location-list ul {
    list-style: none;
    margin: 0px 15px 0px 0px;
    padding: 0px;
}

.location-list ul li {
    font-weight: bold;
    color: #595656;
    font-size: 16px;
    background: #f8f8f8;
    border-radius: 7px;
    margin-bottom: 5px;
    height: 50px;
    padding: 0px 20px;
}

.location-list ul li:hover {
    color: #ffffff;
    background: #72624f;
}

.location-list .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    background-color: #ffa700;
}

.no-padding {
    padding: 0px;
}

.continue a {
    width: 100%;
    display: block;
    margin: 0;
    font-size: 20px
}

.ownvenue-inner-div {
    max-width: 350px;
    margin: 40px auto 0;
}

.errorInfo-div {
    display: none;
    background: #ef1616;
    padding: 5px;
    color: #fff;
}

.event-type-maindiv.two-button-row a {
    border-radius: 4px;
    font-size: 19px;
    padding: 17px 10px 16px;
    background: #ffa700;
}
.event-type-maindiv.two-button-row .active a{ background: #000;}

.hall-select-popup {
    background: #000;
    padding: 60px 50px 50px;
    width: auto;
    margin: 20px auto;
    border: 1px solid #ffa700;
    -webkit-box-shadow: 0 5px 13px 0 rgba(0, 0, 0, .23);
    box-shadow: 0 5px 13px 0 rgba(0, 0, 0, .23);
    text-align: center;
    color: #fff;
    max-width: 580px;
    position: relative;
}

.hall-select-popup .pop-title {
    font-size: 26px;
    text-transform: uppercase;
    line-height: 1;
    margin: 5px 0 10px;
    color: #fff
}

.hall-select-popup p {
    margin: 0;
    font-weight:bold;
}

.hall-select-popup .button {
    width: 100%;
    display: block;
    margin: 20px 0 0 0;
    background: #ffa700;
    
}
.hall-select-popup .button:hover{
    background: #fff;
    color: #000;
}

.hall-description {
    padding: 10px 0
}

.hall-select-popup .mfp-close-btn-in .mfp-close {
    color: #fff;
    opacity: 1
}

h3.event-datetime-string {
    font-size: 20px;
    font-weight:bold;
    margin: 10px 0 0 0;
}

.ccategories_tabsec {
    padding: 26px 0 330px;
}

.number-break {
    padding: 28px 28px 28px 94px;
    margin: 0px 0px 40px;
    position: relative;
    background: #f9f7f8;
}

.number-break .back_bx {
position: absolute;
    left: 20px;
    top: 20px;
    bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
}

.back_bx {
    background: #181818;
    width: 68px;
    text-align: center;
    color: #fff;
    text-transform: uppercase;
    font-size: 18px;
    line-height: 1;
    position: relative;
    border-radius: 5px;
    padding: 10px 10px 12px;
    margin: 0;
    cursor: pointer;
    
}

.back_bx:before {
    font-family: FontAwesome;
    font-size: 40px;
    content: "\f104";
    width: 100%;
    line-height: 1;
    display: block;
}

.back_bx:hover {
    background: #ffa700;
    color: #fff
}

.number-break h2 a {
    margin: 0;
    position: absolute;
    bottom: 5px;
    content: "";
    left: 0;
    right: 0;
    color: #ffa700
}

.break-time {
    width: 31%;
    padding: 5px;
    border-right: 1px solid #cfbfaa;
    text-align: center
}

.number-break {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.break-time-removed {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 19px
}

.break-time .title4,
.making-catering .title4 {
    margin-bottom: 10px
}

.making-catering {
    width: 36%;
    float: left;
    padding: 5px 20px;
    border-right: 1px solid rgba(0, 0, 0, 0.17)
}

.making-catering p {
    margin: 0;
}

.making-place {
    font-weight:bold;
    font-size: 16px;
}

.making-place.making-place-padding {
    display: block;
}

.change-location a {
    text-decoration: underline;
    font-size: 17px;
    color: #000;
}

.change-location a:hover {
    color: #ffa700;
}

.event-time {
    width: 33%;
    padding: 5px 0px 5px 20px;
}

.event-time .title4 {
    margin-bottom: 3px;
}

.event-time .row {
    margin: 0 -4px;
}

.event-time [class*="col-"] {
    padding: 0 4px;
}

.event-time .form-group {
    margin: 0;
}

.event-time .form-control {
    height: 57px;
}

.order-select-brktime ul {
    margin: 0;
    padding: 0;
    list-style: none
}

.order-select-brktime ul .time-cls {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0 0 10px 0;
}

.order-select-brktime ul .time-cls .form-group {
    margin: 0;
    position: relative;
    border: 1px solid #e4e4e4;
}

.order-select-brktime ul .time-cls label {
    width: calc( 100% - 200px);
    font-size: 18px;
    font-weight:bold;
    color: #020202;
    text-transform: uppercase
}

.viewmenu-top-div {
    margin: 10px 0 0 0;
}

.viewmenu-top-div .back_bx {
    padding: 36px 10px 12px;
}

.viewmenu-top-div .back_bx:after {
    font-size: 30px;
    top: 5px;
}

.break-time-removed .event-time .title4,
.break-time-removed .making-catering .title4,
.break-time-removed h3.event-datetime-string {
    color: #000;
    font-size: 20px;
}

.break-time-removed .making-catering {
    width: 50%
}

.break-time-removed .event-time {
    width: 50%
}

.row-pgk-list {
    padding: 0;
    margin: 0;
    list-style: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.row-pgk-list > li {
    width: 33.33333333%;
    border: 7px solid #fff;
    position: relative;
    overflow: hidden
}

.pkselect_tabsec > .nav-tabs {
    border-bottom: 4px solid #000000;
    margin: 0;
    padding: 0;
    list-style: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.pkselect_tabsec > .nav-tabs>li {
    margin: 0;
}

.pkselect_tabsec > .nav-tabs>li>a {
    background: #d2d0d1;
    margin: 0;
    border-radius: 0;
    border: 0;
    color: #000000;
    font-size: 18px;
    font-weight: normal;
    text-transform: uppercase;
    padding: 13px 38px;
    display: block
}

.pkselect_tabsec > .nav-tabs>li.active>a {
    border-radius: 5px 5px 0 0;
}

.pkselect_tabsec > .nav>li>a:focus {
    background: none;
}

.pkselect_tabsec > .nav-tabs>li.active>a,
.pkselect_tabsec .nav-tabs>li.active>a:focus,
.pkselect_tabsec .nav-tabs>li.active>a:hover {
    border: 0;
    background: #000;
    color: #fff;
}

.pkselect_tabin {
    position: relative;
    padding: 20px 0 0;
}

.pkselected_filtersec {
    margin: 0 0 15px;
}

.pkselected_filtersec label {
    display: inline-block;
    font-size: 18px;
    color: #010101;
    padding-right: 16px;
    vertical-align: middle;
    margin: 0;
    font-weight:bold;
    text-transform: uppercase
}

.pkselected_filtersec .form-group {
    display: inline-block;
    margin: 0;
    vertical-align: middle;
}

.pkselected_filtersec .form-control {
    height: 48px;
    margin-bottom: 0
}

.pkselected_package label {
    position: relative;
    padding: 15.5px 26px 15.5px 0px;
}

.pkselected_package label i {
    font-size: 45px;
    position: absolute;
    left: 20px;
    top: 11px;
    line-height: 0.83;
}

.pkselected_package .form-group {
    min-width: 360px;
}

.pkselected_package .chosen-container-single .chosen-single {
    height: 56px;
    line-height: 56px;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding: 0 0 0 25px;
}

.pkselected_package .chosen-container-single .chosen-single span {
    margin-right: 64px;
}

.pkselected_package .chosen-container-single .chosen-single div {
    width: 64px;
}

.pkselected_break .form-group {
    min-width: 245px;
}

.pkg_gimg,
.pkg_gitem {
    position: relative;
    height: 100%;
}

.pkg_gimg img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover
}

.pkg_ginfo {
    background: rgba(0, 0, 0, 0.25);
    padding: 45px 15px 45px;
    color: #fff;
    line-height: 1.4;
    font-size: 14px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: all 0.35s ease-in-out;
    transition: all 0.35s ease-in-out;
}

.pkg_ginfo.catbox-bottom-info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
}

.row-pgk-list > li:hover .pkg_ginfo,
.pkg_gitem.category-active .pkg_ginfo {
    visibility: visible;
    opacity: 1;
}

.pkg_ginfo h4 {
    font-size: 18px;
    text-transform: uppercase;
    margin: 0 0 9px;
    color: #fff;
    text-shadow: 0px 1px 4px rgba(0, 0, 0, 1);
}

.row-pgk-list > li:hover .pkg_ginfo h4,
.pkg_gitem.category-active .pkg_ginfo h4 {
    -webkit-transform: translateX(0);
    transform: translateX(0);
}

.pkg_ginfo p {
    margin: 0;
}

.row-pgk-list > li:hover .pkg_ginfo p,
.pkg_gitem.category-active .pkg_ginfo p {
    -webkit-transform: translateX(0);
    transform: translateX(0);
}

.pkselected_catgrytext {
    text-align: center
}

.pkselected_catgrytext .title4 {
        font-size: 14px;
    text-transform: inherit;
}

.pkgselect_dd {
    text-align: left;
    display: none;
}

.pkgselect_dd .chosen-container {
    font-size: 18px;
}

.pkgselect_dd .chosen-container-single .chosen-single {
    background: #ecdfcc;
    border-color: #d0cfcb;
    border-radius: 2px;
    box-shadow: none;
    -webkit-box-shadow: none;
    font-size: 18px;
}

.pkgselect_dd .chosen-container-single .chosen-single span {
    font-weight: 300;
    color: #41403e;
}

.pkgselect_dd .chosen-container-single .chosen-drop {
    box-shadow: none;
    -webkit-box-shadow: none;
    border-color: #f1ece6;
    background: #f6f4f4;
}

.pkgselect_dd .chosen-container .chosen-results li {
    padding: 15px 18px;
    border-top: 1px solid #f1ece6;
}

.pkgselect_dd .chosen-container .chosen-results li:first-child {
    border-top: 0;
}

.pkgselect_dd .chosen-container .chosen-results .highlighted {
    color: #41403e;
}

.pkg_gbtsec .button {
    font-size: 17px;
    width: 100%;
    margin: 0;
    padding: 14px 5px;
    background: #98684a;
        
}

.pkg_gbtsec .button:hover {
    background: #ffa700
}

.pkg_gitem.highlighted .pkg_ginfo {
    background: rgba(11, 4, 5, 0.82);
}

.pkg_gitem.highlighted .pkg_ginfo .btn {
    display: none;
}

.pkg_gitem.highlighted .pkg_ginfo .pkgselect_dd {
    display: block;
}

.pkg_gitem.highlighted .pkg_ginfo .btn {
    opacity: 0;
    pointer-events: none;
}

.catbox-bottom {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
    -webkit-transition: 0.3s linear all;
    transition: 0.3s linear all;
}

.row-pgk-list > li:hover .catbox-bottom,
.pkg_gitem.category-active .catbox-bottom {
    -webkit-transform: translateY(0);
    transform: translateY(0)
}

.row-pgk-list > li.category-active .pkg_ginfo {
    background: rgba(0, 0, 0, 0.65);
}

.pkg-detail-top-row,
.goto-chkpage-cls,
.viewmenu-btn-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.pkg_gcol {
    width: 25%;
    border: 4px solid #fff;
}

.pkg_gridsec .row-replace {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 0;
    list-style: none;
}

.pkg_gbtsec.text-center {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    -webkit-transform: translateY(0);
    transform: translateY(0);
}

.pkg_gcol .choose-single-btn {
    background: #ffa700;
    display: block;
    padding: 11px 10px;
    color: #fff;
    
    text-transform: uppercase;
}

.pkg-intuil-list .pkg_ginfo {
    opacity: 1;
    visibility: visible;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column nowrap;
    flex-flow: column nowrap;
    color: #fff
}

.package-overlay-header h3 {
    color: #fff;
    font-size: 22px;
    margin: 0 0 10px 0
}

.package-overlay-header span {
    font-weight:bold;
}

.package-overlay-body {
    font-weight:bold;
    font-size: 22px;
}

.package-overlay-body sup {
    font-size: 15px;
    text-transform: uppercase;
    top: -6px;
    position: relative;
}

.pkg_gcol .related-tags {
    z-index: 5;
    position: static
}

.pkg-intuil-list .pkg_ginfo p {
    -webkit-transform: none;
    transform: none;
    margin: 10px 0
}

.package-overlay-header {
    margin: 0 0
}
ul li .cat-product-info{
    background: rgba(0, 0, 0, 0.60);
    padding: 45px 15px 45px;
    color: #fff;
    line-height: 1.4;
    font-size: 14px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: all 0.35s ease-in-out;
        display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
}
ul li .cat-product-info h4{color: #ffffff;margin: 0 0 5px 0; }
.cat-product-button{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}
.cat-product-button a{
    margin: 0;
    display: block;
    height: 45px;
    padding: 15px 10px;
}
.pkg_gridsec > .row-replace > li {
    width: 32%;
    margin: 0 2% 20px 0;
}

.pkg_gridsec > .row-replace > li:nth-child(3n) {
    margin-right: 0;
}
.pkg_gridsec > .row-replace > li .innerproduct-itemimage-info img{
    max-height: 370px;
    min-height: 370px;
    height: 100%;
    object-fit: cover;
    object-position: center;
    width: 100%;
}

#our-signature-dish span {
    position: relative;
    display: inline-block;
    margin: 0 0 0 15px;
    top: -2px;
    cursor: pointer;
    background: #000;
    padding: 6px 13px;
    border-radius: 5px;
    -webkit-transition: 0.3s linear all;
    transition: 0.3s linear all;
    line-height: 1;
}

#our-signature-dish span:hover {
    background: #ffa700;
}

#our-signature-dish span img {
    height: 17px;
    vertical-align: middle;
}

.upkg_tabsec {
    padding: 26px 0 8px;
}

.pkselect_tabin .bx_out {
    margin: 0 0 5px;
}

.bx_in {
    background: #fff;
    padding: 50px 70px 40px;
    border: 2px solid #d2d0d0;
    border-radius: 6px;
}

.pkselect_tabin .pkg_listsec {
    margin: 0 0 8px;
}

.pkg_listsec .bx_in {
    padding: 10px 0 10px 17px;
}

.pkglist_item {
    color: #989898;
}

.pkglist_itemtable {
    display: table;
    width: 100%;
}

.pkglist_itemtable > div {
    display: table-cell;
    vertical-align: top;
}

.pkglist_img {
    width: 33%;
    padding: 13px 0;
}

.pkglist_info {
    width: 50%;
    padding: 20px 22px 0;
}

.pkglist_infott h3 {
    margin: 0 0 7px;
    color: #010101;
    font-size: 18px;
    height: 22px;
    overflow: hidden;
}

.pkglist_infott p {
    font-size: 16px;
}

.pkglist_pricebx {
    width: 17%;
    border-left: 1px solid #e4e4e4;
    padding: 0 10px;
}

.pkglist_itemtable .pkglist_pricebx {
    vertical-align: middle;
}

.pkglist_pricebx > span {
    font-size: 18px;
}

.pkglist_pricebx > b {
    font-size: 22px;
    font-weight: 700;
    color: #1a1a1a;
    margin: 0 0 4px;
}

.pkglist_pricebx > div > span {
    font-size: 18px;
}

.pkglist_pricebx > div > b {
    font-size: 22px;
    font-weight: 700;
    color: #1a1a1a;
    margin: 0 0 4px;
}
.package_formtt {
    font-size: 18px;
    color: #020202;
    margin: 0 0 20px 0px;
}

.package_formtt1 {
    margin: 0 0 15px;
    position: relative;
}

.package_formtt span {
    display: block;
    margin: 0 0 5px;
    text-transform: uppercase;
}

.package_formtt small {
    display: block;
    color: #333;
    font-size: 13px;
    font-style: italic;
}

.package_checkrow {
    margin-bottom: 40px;
}

.package_checkitem {
    border: 2px solid #f5f5f5;
    padding: 32px 38px;
}

.package_checkitem .package_formtt {
    border-bottom: 1px solid #f5f5f5;
    padding: 0 0 10px;
}

.package_formchecklist {
    list-style: none;
    padding: 0;
    margin: 0;
}

.package_formchecklist li {
    margin: 0 0 11px;
}

.package_formchecklist li.modfr_valuecls span {
	text-transform: capitalize;
}

.package_formcheck_inline {
    padding-left: 29px;
}

.package_formcheck_inline:after {
    content: "";
    display: block;
    clear: both;
}

.package_formcheck_inline li {
    width: 50%;
    float: left;
    padding: 1px 15px 0 0;
    margin: 0 0 1px;
    height: 40px;
    overflow: hidden;
}

.package_formbx .form-group.last {
    border: 0;
}

.package_formchecklist .custom_checkbox span {
    font-size: 18px;
    font-weight: 700;
    color: #363636;
    padding: 5px 0 0 43px;
}

.package_addonbx .custom_checkbox span b {
    font-weight: 700 !important;
    height: 22px;
    overflow: hidden;
}

.package_formchecklist .custom_checkbox span:before {
    border-width: 2px;
    width: 30px;
    height: 30px;
    border-radius: 5px;
    border-color: #b1b1b1;
}

.package_formchecklist .custom_checkbox input[type=checkbox]:checked + span:before {
    content: "\f00c";
    font-family: FontAwesome;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 14px;
    line-height: 26px;
    color: #fff;
    text-align: center;
    text-align: center;
    background: #333333;
    border-color: #77905d;
}

.package_formchecklist .custom_checkbox span b {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.3;
    display: block;
}

.pkgerror_out {
    position: relative;
    border: 2px solid #ff1313;
}

.pkgerror_out .pkgerror_txt {
    background: #ff1313;
    padding: 5px 10px;
    position: absolute;
    bottom: -27px;
    left: 0;
    right: 0;
    font-size: 13px;
    color: #fff;
    max-width: 220px;
    text-align: center;
    margin: 0 auto;
    line-height: normal;
    z-index: 11;
}

.pkgerror_out.up .pkgerror_txt {
    top: -30px;
    bottom: auto;
}

.pkgaddon_checklist .pkgerror_out.custom_checkbox {
    border-color: #ecdfcc;
}

.pkgaddon_checklist {
    margin: 0 -3.5px;
}

.pkgaddon_checklist li {
    width: 25%;
    float: left;
    margin: 0 0 7px;
    padding: 0 3.5px;
}

.pkgaddon_checklist .custom_checkbox {
    border: 3px solid #f5f5f5;
    display: block;
    border-radius: 6px;
}

.pkgaddon_checklist li.active .custom_checkbox {
    background: #98684a;
    border-color: #ecdfcc;
}

.package_addonbx .custom_checkbox input {
    height: calc(100% + 6px);
    height: -webkit-calc(100% + 6px);
    width: calc(100% + 6px);
    width: -webkit-calc(100% + 6px);
}

.pkgaddon_checklist .custom_checkbox span {
    padding: 18.5px 15px 18.5px 72px;
}

.pkgaddon_checklist .custom_checkbox span:before {
    border-color: #98684a;
    color: #98684a;
    font-size: 16px;
    top: 0;
    bottom: 0;
    margin: auto 0;
    left: 21px;
    border-radius: 50%;
    text-align: center;
    line-height: 27px;
    content: "\f067";
    font-family: FontAwesome;
    font-weight: 100;
}

.pkgaddon_checklist .custom_checkbox input[type=checkbox]:checked + span {
    color: #fff;
}

.pkgaddon_checklist .custom_checkbox input[type=checkbox]:checked + span:before {
    content: "\f00c";
    background: #334845;
    border-color: #bdbfbf;
    font-size: 17px;
}

.pkgsetup_listrw {
    padding: 0 0 12px;
    margin-bottom: 11px;
    border-bottom: 1px solid #dfdddd;
}

.pkgsetup_listrw:last-child {
    border: 0;
    margin: 0;
    padding: 0;
}

.package_controltt .custom_radio span {
    font-size: 18px;
    font-weight: 700;
    color: #363636;
    padding: 5px 0 0 43px;
}

.package_controltt .custom_radio span:before {
    width: 30px;
    height: 30px;
    border-width: 2px;
}

.package_controltt .custom_radio input[type=radio]:checked + span:before {
    border-color: #637550;
    border-width: 2px;
    background: #333333;
}

.pkgsetup_list .package_formchecklist {
    padding-left: 41px;
    margin: 14px 0 0;
}

.pkgsetup_list .package_formchecklist li {
    margin: 0 0 16px;
}

.pkgsetup_list .package_formchecklist .custom_checkbox span {
    padding: 0 0 0 30px;
    font-size: 16px;
    font-weight: 300;
}

.pkgsetup_list .package_formchecklist .custom_checkbox span:before {
    width: 23px;
    height: 23px;
}

.pkgsetup_list .package_formchecklist .custom_checkbox input[type=checkbox]:checked + span:before {
    line-height: 20px;
}

.package_form_helptext {
    font-style: italic;
    font-size: 16px;
}

.package_descbx textarea {
    height: 115px;
    padding-top: 14px;
}

.upkg_tabsec .chk-payment-btn {
    margin: 10px 0 0;
}

.btn_download {
    font-size: 18px;
    color: #fff;
    padding: 8px 20px 7px;
    height: 38px;
    border: 0;
    border-radius: 30px;
    background: #ffa700;
    -webkit-box-shadow: 0 2px 0px 0px #0b0405;
    box-shadow: 0 2px 0px 0px #0b0405;
    margin: 6px 0px 12px;
}

.btn_download:hover {
    background: #ecdfcc;
    color: #0b0405;
    -webkit-box-shadow: 0 2px 0px 0px #b1b1b1;
    box-shadow: 0 2px 0px 0px #b1b1b1;
}

.product_modifiers_listinner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.product_modifiers_listinner .modifiers_main_list {
    width: 48%;
    margin: 0 0 20px 0
}

.package_checkitem {
    border-radius: 8px
}

.upkg_tabsec .chk-payment-btn .chk-payment-btn-row a {
    width: 49%
}

.upkg_tabsec .chk-payment-btn .chk-payment-btn-row a:first-of-type,
#PackageEditMdl .chk-payment-btn .col-md-cls:first-of-type a,
.goto-chkpage-cls a:first-of-type,
.viewmenu-btn-row a:first-child {
    background: #000000
}

.upkg_tabsec .chk-payment-btn .chk-payment-btn-row a:hover,
#PackageEditMdl .chk-payment-btn .chk-payment-btn-row a:hover,
.goto-chkpage-cls a:first-of-type:hover,
.viewmenu-btn-row a:first-child:hover {
    background: #333333
}

.pkg_total_box .pkg_total_row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    font-weight:bold;
    font-size: 20px
}

.pkg_total_box .pkg_total_row.grand_total {
    font-size: 28px;
}

.pkg_total_box .cc-addon-subtotal-lhs {
    width: 140px;
}

.pkg_total_box .cc-addon-subtotal-rhs {
    width: 180px;
}

.pkselected_break .form-group .custom-select-inner select {
    border: 0;
    height: 46px;
    position: relative;
    top: 1px;
    right: 1px;
}

.pkselected_break .form-group .custom-select-inner {
    background: #fff;
    padding-left: 40px;
}

.checkout_time .custom-select-inner {
    background: #fff;
    padding-left: 40px;
}

.checkout_time .custom-select-inner select {
    padding: 6px 0;
    border: 0;
    height: 46px;
    position: relative;
    top: 1px;
    right: 1px;
}

.goto-chkpage-cls a,
.viewmenu-btn-row a {
    width: 49%;
    margin: 0
}

.goto-chkpage-cls,
.viewmenu-btn-row {
    margin: 10px 0 0 0;
}

.addon_popup {
    max-width: 846px;
    border: 1px solid #000000;
}

.addon_popup .pouup_in {
    padding: 45px 9px 30px;
}

.addon_poptop {
    padding: 0 49px;
    margin: 0 0 30px;
}

.addon_listrw {
    width: 100%;
    margin: 0 0 15px;
    padding: 0 0 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.addon_linfo {
    width: 55%;
    color: #020202;
}

.addon_linfo h4 {
    font-size: 15.5px;
    color: #060606;
    margin: 0 0 5px;
}

.addon_linfo p {
    font-size: 15.5px;
    line-height: 1.2;
    margin: 0;
}

.addon_lright {
    width: 45%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.cross_multi {
    font-size: 16px;
    color: #fff;
    margin: 0 9px 0 0;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    padding: 10px 3px;
    width: 42px;
    z-index: 1;
    text-align: center;
}

.cross_multi:before {
    content: "";
    background: #000000;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    border-radius: 3px;
    -webkit-transform: skewX(-10deg);
    transform: skewX(-10deg);
    -webkit-transform: -webkit-skewX(-10deg);
    transform: -webkit-skewX(-10deg);
}

.addon_price {
    font-size: 19px;
    color: #000000;
    margin: 0 20px 0 0;
    display: inline-block;
    vertical-align: middle;
    font-weight:bold;
}

.addon_lright .btn {
    border-radius: 4px;
    font-size: 17px;
    margin-bottom: 0;
    vertical-align: middle;
}

.addon_lright .qty_bx {
    width: 109px;
    height: 33px;
    margin: 0 5px 0 0;
    vertical-align: middle;
}

.addon_lright .qty_bx span {
    background: #fff;
    width: 35px;
    height: 33px;
    line-height: 31px;
    letter-spacing: -1px;
    font-size: 20px;
}

.addon_lright .qty_bx input {
    font-size: 16px;
}

.addon_lright .btn_grey {
    background: #4c4c4c;
    color: #fff;
}

.addon_lright .btn_grey:hover {
    background: #1c1c1c;
}

.addon_popbot .btn {
    min-width: 140px;
    font-size: 17px;
    margin: 0;
}

.addon_popbot .btn_grey {
    background: #5a5a5a;
    color: #fff;
}

.addon_popbot .btn_grey:hover {
    background: #1c1c1c;
}

.addon_lright .button {
    margin: 0;
    font-size: 15px;
    padding: 14px 20px 13px;
}

.addon_poptop_header {
    border-bottom: 2px solid #efefef;
    margin: 0 0 40px 0;
}

.addon_poptop_header h3 {
    margin: 0 0 15px 0;
    font-size: 24px;
}

.addon_poptop_header h3 small {
    display: block;
    color: #333;
    font-size: 12px;
    font-style: italic;
    padding-top: 10px;
}

.addon_popbot .row-replace {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end
}

.addon_popbot .row-replace .button {
    margin: 0 0 0 10px
}

.addon_popbot {
    border-top: 1px solid #e4e4e4;
    padding: 30px 0 0 0;
    margin: 0 49px;
}

.addon_popbot .cal-md-cls:first-of-type .common-mfd-closefun {
    background: #000000
}

.addon_popbot .row-replace .button:hover {
    background: #333333
}

.catering_checout .checkout-total {
    width: 100%;
}

.catering_checout .checkout-total .fc-half {
    width: 45%;
}

.catering_checout .checkout-total .controls-single input[type="text"] {
    background: #ffffff;
}

.catering_checout .checkout-control-group-bottom {
    margin: 30px 0 0 0
}

.catering_checout .checkout-control-group-middle .col-md-cls {
    padding: 0
}

.catering_checout .checkout-control-group-middle .form-group .form-control {
    margin: 0
}

.order_details .panel-group .panel+.panel {
    margin-top: 0px;
}

.order_details .panel-group {
    padding: 30px 0px 0px;
    margin-bottom: 0px;
}

.order_details .panel-default {
    border-color: transparent;
}

.order_details .panel {
    background-color: #f6f6f6;
    border-radius: 0px
}

.order_details .panel-default>.panel-heading {
    color: #fff;
    background-color: #ffa700;
    border-color: #ffa700;
    padding: 12px 15px 12px 15px;
    border-radius: 0px;
}

.order_details .panel-title>a {
    display: block;
    font-size: 18px;
    text-transform: uppercase;
}

.order_details .panel-title>a .clck {
    text-align: right;
    font-weight: 300;
    font-style: italic;
    float: right;
    font-size: 14px;
    margin-right: 30px;
    margin-top: 2px;
}

.order_details .panel-title>a:hover {
    color: #fff;
}

.order_details .panel-title {
    position: relative;
}

.order_details .panel-title:after {
    content: '';
    position: absolute;
    right: -9px;
    background: #fff;
    height: 2px;
    top: 0;
    width: 18px;
    -webkit-transform: rotate(90deg) translate(10px, 10px);
    transform: rotate(90deg) translate(10px, 10px);
}

.order_details .panel-title:before {
    content: '';
    position: absolute;
    right: 7px;
    background: #fff;
    height: 2px;
    top: 0;
    width: 18px;
    -webkit-transform: rotate(0deg) translate(6px, 10px);
    transform: rotate(0deg) translate(6px, 10px);
}

.order_details .act .panel-title:after {
    opacity: 0;
}

.order_details .panel-collapse {
    height: 670px;
}

.checkout_time {
    padding: 0px 0px 20px;
}

.checkout_time label {
    color: #000000;
    line-height: 40px;
    padding: 4px 0px;
    font-size: 17px;
    text-transform: uppercase;
    font-weight:bold;
}

.order_details .edit {
    background: #98684a;
    border-radius: 3px;
    margin-right: 5px;
    display: inline-block;
    width: 26px;
    height: 26px;
    text-align: center;
    line-height: 30px;
    cursor: pointer;
    position: absolute;
}

.order_details .edit .fa-pencil-square-o {
    font-size: 18px;
    color: #fff
}

.package {
    padding: 0px 0px 5px 0px;
}

.package .title {
    font-weight:bold;
    color: #000000;
    font-size: 20px;
    padding-left: 30px;
}

.package a {
    line-height: 1.6;
}

.package .catqty_bx .qty_minus {
    background: #fff;
}

.package .catqty_bx .qty_plus {
    background: #fff;
}

.package .package_cnt {
    padding: 0px 0px 25px;
}

.package .package-list {
    padding: 0px 0px 15px 40px;
    color: #000;
}

.package .package-list .one {
    font-weight: 700;
    font-size: 16px;
    margin: 0px;
}

.package .package-list .two {
    font-weight: 300;
    font-size: 16px;
    margin: 0px;
}

.addon {
    padding: 20px 0px 15px 40px;
}

.addon h4 {
    margin: 0px 0px 10px 0px;
    color: #000;
    font-size: 18px;
    font-weight:bold;
}

.addon_list h5 {
    margin: 10px 0px 10px 0px;
    line-height: 1.6;
}

.addon_list p {
    margin: 0px 0px 5px 40px;
    font-size: 16px;
    color: #000;
}

.addon_list h5 .title {
    color: #000;
    font-size: 17px;
    padding-left: 35px;
    display: block;
    font-weight:bold;
}

.amt {
    text-align: right;
    margin-top: 10px;
}

.amt .remove {
    background: #333;
    border-radius: 3px;
    margin-left: 10px;
    width: 21px;
    height: 21px;
    line-height: 20px;
    display: inline-block;
    text-align: center;
    color: #fff
}

.amt span {
    font-weight:bold;
    color: #000;
}

.buffet_setup {
    padding: 20px 0px 30px;
}

.buffet_setup h5 {
    margin: 10px 0px 5px 0px;
    line-height: 1.6;
}

.buffet_setup h5 .title {
    color: #000000;
    font-size: 20px;
    padding-left: 35px;
    font-weight:bold;
}

.buffet_setup p {
    margin: 0px 0px 7px 40px;
    font-size: 16px;
}

.total_amt {
    background: #1c1c1c;
    padding: 35px 70px 30px 50px;
}

.total_amt .subtotal {
    font-size: 16px;
    color: #fff;
    font-weight:bold;
}

.total_amt .total {
    font-size: 25px;
    color: #fff;
    font-weight:bold;
    text-transform: uppercase;
}

.total_amt .rt {
    text-align: right;
}

.brline {
    background: #f5f5f5;
    height: 2px;
    margin-left: 40px;
}

.download-quote {
    padding: 20px 0px 0px;
    text-align: center;
}

.download-quote .btn {
    font-size: 18px;
    color: #fff;
    padding: 7px 20px;
    border: 0;
    background: #ffa700;
}

.catering_thak_you .tnk-header {
    text-align: unset;
}

.catering_thak_you .tnk-detail {
    text-align: center;
}

.catering_thak_you .tnk-header .tick {
    text-align: center;
}

.catering_thak_you .order_details .qtybxs {
    font-weight: 700;
    color: #000000;
    font-size: 16px;
    line-height: 38px;
    text-align: right;
}

.catering_thak_you .package .package-list {
    padding: 0px 0px 15px 0px;
}

.catering_thak_you .package .title {
    border-bottom: 1px solid #000;
}

.catering_thak_you .brline {
    margin-left: 0px;
}

.promotion_popup {
    font-size: 16px;
    max-width: 650px;
}

.promo_poptt {
    margin: 0 0 30px;
}

.promo_poptt .title1 {
    margin-bottom: 8px;
}

.popoff_bar {
    padding: 10px 0 15px;
    border: 1px solid #ddd;
    border-right: 0;
    border-left: 0;
    margin: 0 0 35px;
}

.popoff_bar span {
    display: block;
    font-size: 36px;
    font-weight: 700;
    color: #000;
}

.popoff_bar b {
    display: block;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 400;
}

.popwel_formsec h4 {
    margin: 0 0 10px;
}

.popwel_inputbx {
    position: relative
}

.popwel_inputbx .form-control {
    height: 51px;
    font-size: 16px;
}

.popwel_inputbx .btn {
    position: absolute;
    right: 0;
    top: 0;
    border-color: #ecdfcc;
}

.choose_location.pkgerror_out .pkgerror_txt {
    max-width: 290px;
}

.sub_tab_mobtrigger {
    display: none;
}

.rating-option {
    position: relative;
    display: inline-block;
}

.rating-option input {
    opacity: 0;
    filter: alpha(opacity=0);
    height: 100%;
    left: 0;
    top: 0;
    margin: 0;
    width: 100%;
    position: absolute;
    cursor: pointer;
}

.rating-option span {
    padding: 5px 0 0 40px;
}

.rating-option span:before {
    content: "";
    background: #fff;
    position: absolute;
    width: 30px;
    height: 30px;
    border: 2px solid #aba8a3;
    left: 0;
    top: 3px;
    pointer-events: none;
    line-height: 21px;
    border-radius: 50%;
}

.rating-option input[type=radio]:checked + span:before {
    content: "\f00c";
    font-family: FontAwesome;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 14px;
    line-height: 26px;
    color: #fff;
    text-align: center;
    text-align: center;
    background: #72624f;
    border-color: #ecdfcc;
}

.contact_left p {
    margin: 0 0 10px;
}

.react-datepicker__time-list-item--disabled {
    display: none;
}

#pay-conf-popup .process_inner {
    text-align: center;
}

#pay-conf-popup .process_inner .btn-block {
    display: inherit;
    width: auto;
}

.loader-main-cls {
    position: relative;
    pointer-events: none;
}

.loader-sub-div, .loader-product-sub-div {
    background: rgba(255, 255, 255, 0.8);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 99;
}

.loader-sub-div:after, .loader-product-sub-div:after {
    width: 35px;
    height: 35px;
    border: 4px solid rgba(30, 29, 29, 0.49);
    border-top-color: #1e1d1d;
    border-radius: 50%;
    position: relative;
    -webkit-animation: loader-rotate 0.3s linear infinite;
    animation: loader-rotate 0.3s linear infinite;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto
}

@-webkit-keyframes loader-rotate {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes loader-rotate {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.header_middle .container {
    position: relative;
}

.htico_search > a, .htico_search_mbl > a {
    position: relative;
    display: block; top: -4px;    margin-right: 20px;}

.header_bot {
    display: inline-block;
}

.header-top {
    background: #000;
    text-align: center;
    clear: both;
    padding-top: 10px;
    padding-bottom: 10px;
}
.header-top p {color: #fff; font-size: 15px;  margin-bottom: 0px;}
.header-top-cls {padding: 15px 0 0; position: relative;  transition: 0.3s linear all;}
.logo img {
    width: 160px;
}
.logo-main-section {
    padding-bottom: 15px;
    position: relative;
    display: flex;
    justify-content: space-between;
    z-index: 9;
    align-items: center;
}
.menu_icon.trigger_menunav_act {display: none;}
.mbl_search_liblk{display: none}
.slick-slide {position: relative;}



footer {background:#a99c95; position: relative; padding: 45px 0 0 0;margin: 70px 0 0 0; color: #fff;}
.home-main-div footer{ margin: 40px 0 0 0; }

.footer-bottom { padding-top: 30px; padding-bottom: 15px;}
.footer-bottom .container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.footer-top {
    text-align: center;
    position: relative;
    z-index: 1;
    margin-bottom: 25px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.footer-top-inner {
    display: flex;
    flex-wrap: wrap;
    width: 74%
}
.location-details {
    max-width: 220px;
    width: 100%;
    padding: 0 10px;
    
}
.location-details a:hover{ opacity: 0.7 }
.location-details .addr-dtls {
    margin: auto;
}

.location-icon {
    margin-bottom: 15px;
}
.addr-dtls h5 {
    font-size: 17px;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0px 0px 5px 0px;
}
.footer-top a {
    display: inline-block;
	color: #fff;
}
.f-address-def p {
    font-size: 15px;
}
.footer-contact a,
.footer-bottom-right h3 {
    font-size: 15px;
    text-decoration: none;
    color: #000;
}

.footer-bottom-middle ul,
.footer-bottom-right ul {
    list-style: none;
    padding: 0;
}
.footer-bottom-middle ul li a {
    color: #000;
    text-decoration: none;
    font-size: 17px;
    -webkit-transition: 0.3s linear all;
    transition: 0.3s linear all;
    text-transform: uppercase;
    
    position: relative;
    padding: 1px 0;
}
.footer-bottom-middle ul li a::after, .footer-bottom-middle ul li a::before {
    position: absolute;
    content: "";
    opacity: 1;
    width: 0;
    height: 1px;
    background-color: #ffa700;
    -webkit-transition: width 0.4s 0.1s;
    transition: width 0.4s 0.1s;
}
.footer-bottom-middle ul li a::before {
    top: 0; right: 0;
}
.footer-bottom-middle ul li a::after {
    top: 94%; left: 0;
}
.footer-bottom-middle ul li a:hover::after, .footer-bottom-middle ul li a:hover::before {
    width: 100%;
}
.footer-bottom-middle ul li a:hover{ color: #ffa700; }
.footer-bottom-middle ul li {display: inline-block; margin: 0 7px}
.footer-bottom-right ul li a {color: #000; font-size: 22px;}
.footer-bottom-right ul li a:hover {color: #ffa700;}

.copyright-section p {text-align: center; margin-bottom: 0px;font-size: 15px;}



.container-full{ max-width: 1340px; margin: 0 auto; padding: 0 20px; width: 100%;}
.f-nav {  width: 100%}
.f-nav > ul {display: flex; flex-wrap: wrap; justify-content: space-between; padding: 0;}
.f-nav > ul > li{ list-style: none;  padding-right:10px  }
.c-email{ width: 32% }
.c-nav{width: 22%;}
.c-address { width: 27%; font-weight: 300; }
.f-nav > ul > li.c-socail{padding-right: 0}
.f-nav > ul > li ul, .f-socail ul{ list-style: none; padding: 0;}
.f-nav > ul > li ul li a{ color: #fff;font-size: 15px;font-weight: 300; text-transform: uppercase;}
.f-nav > ul > li ul li a:hover{ color: #000}
.f-nav > ul > li ul li {margin-bottom: 15px}
.f-nav h5{margin-bottom: 20px; font-size: 17px;font-weight: 900;text-transform: uppercase;}
.f-socail {display: flex; flex-flow: column wrap;}
.f-socail ul li a img:hover {opacity: 0.7}
.f-socail-top {display: flex; align-items: center; margin-bottom: 50px; text-transform: uppercase;}
.f-socail-top a:hover{ opacity: 0.8 }
.f-socail-top img {margin-left: 10px;}
.f-socail li {display: inline-block; margin: 0 10px 0 0;}
.mailform .button {margin: 0; letter-spacing: 0; background: #523628; color: #fff; border-color: #160e0b; border-radius: 0; position: absolute; right: 0; top: 0;}
.mailform{position: relative;}
.mailform input[type="text"] { padding-right: 140px;    border-color: #141414;}
.c-email p{ margin: 0; text-transform: uppercase; font-weight: 300;font-size: 14px;}
.footer-logo > a{ display: block; margin-bottom: 30px}
.footer-logo p {
    line-height: 1.6; margin-bottom: 0;
}
.footer-logo a{color: #fff;}
.footer-logo a:hover {
    color: #000;
}
.home-options-tab {padding: 30px 0 30px 0;}
.home-options-tab ul {
    padding: 0; list-style: none; display: flex; justify-content: center;
    text-align: center; border-bottom:4px solid #e3e3e3;
}
.home-options-tab ul li {
    width: 33.3333%; color: #bebebe; text-transform: capitalize; font-size: 19px; 
	position: relative; padding-bottom:7px; cursor: pointer;
}
.home-options-tab ul li:hover, .home-options-tab ul li.active{ color: #000; }
.home-options-tab ul li:hover:before, .home-options-tab ul li.active:before {
    visibility: visible; transform: scaleX(1);
}
.home-options-tab ul li:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 4px;
    bottom: -4px;
    left: 0;
    background: #ffa700;
    visibility: hidden;
    transform: scaleX(0);
    transition: .25s linear;
}

.home-options-inner > ul > li:hover .choose-option-hover{  opacity: 1;
    visibility: visible;
    pointer-events: auto; z-index: 9 }
.home-options-inner > ul > li:nth-child(3n) .choose-option-hover {
    left: inherit; right: 100%;
}
.imp-button{display: flex;}
.imp-button a{width: 50%; padding:5px 5px; position: relative; text-align: center; display: flex;
    justify-content: center; align-items: center;}
.imp-button a img{margin-right: 5px;}
.imp-button a:first-child{ color: #fff; background: #fbaa1a;     border-top-left-radius: 5px; }
.imp-button a:last-child{ color: #979797; background: #292929;     border-top-right-radius: 5px;  }

.slick-track {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
.slick-track:before,
.slick-track:after {
    display: none !important
}
.slick-track .slick-slide {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    height: inherit;
}
.slick-track .slick-slide .featured-products-single {
    height: 100%;  background-color: #fff;
}
.addcart-inner-flex {
    display: flex;
    justify-content: center;
}
.addcart_row .btn { margin: 0 0 0 5px; border: none;cursor: pointer;padding: 9px;line-height: 1;display: flex;align-items: center;justify-content: center;min-width: 90px; font-weight: 500;    border: 2px solid #523628;}
.addcart_row .btn:hover, .addcart_row .btn:focus { background: #523628; color: #fff;}
.innerproduct-item::after {
    content: "";
    top: 0px;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    width: 100%;
    position: absolute;
    z-index: 1;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    opacity: 0;
    -webkit-transition: 0.5s all ease-in-out;
    transition: 0.5s all ease-in-out;
}
.innerproduct_row .product-price .price_disc {color: #B6B6B6;font-size: 18px;text-decoration: line-through;line-height: 1; margin: 0 0 15px 0;display: block}
.innerproduct_row .product-price .price_disc sup {font-size: 13px;}
.innerproduct-col:hover::after,
.innerproduct-col.open::after {
    opacity: 1;
}

.productlist-main-div .product-category-list .title_desp { max-width: 920px; margin:0 auto 30px; color: #523628;}
.innerproduct-item-text h4 {
    font-size: 20px;
    color: #0a0b10;
    padding: 10px 0 8px 0;
    margin-bottom: 0;
}
.products-single-li .like {position: absolute;top: 13px;right: 8px;}
.active-hart{display: none}
.products-single-li .like.active img{ display: none}
.products-single-li .like.active img.active-hart { display: block}

.innerproduct-item-text p {
    line-height: 20px;
    font-size: 14px;
    color: #949292;
    padding-top: 10px;
    padding-bottom: 10px;
    border-top: 1px dotted #ccc;
    border-bottom: 1px dotted #ccc;
    margin-top: 15px;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.innerproduct-item-text h5 {
    font-size: 20px;
    
    color: #000;
    margin-bottom: 0;
}

.innerproduct-item-text {
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
    background: #fff;
    padding-top: 10px;
    padding-bottom: 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100%;
}
.product-description-tab ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    border-bottom: 4px solid #eaeaea;
}

.product-description-tab {
    padding: 20px 0 25px 0;
}

.product-description-tab ul li {
    width: 50%;
    position: relative;
}
.product-description-tab ul li:after {
    bottom: -4px;
    content: "";
    display: block;
    height: 4px;
    left: 50%;
    position: absolute;
    background: #523628;
    -webkit-transition: width .3s ease 0s,left .3s ease 0s;
    transition: width .3s ease 0s,left .3s ease 0s;
    width: 0;
}
.product-description-tab ul li.active:after, .product-description-tab ul li:hover:after {
    width: 100%;
    left: 0;
}

.product-description-tab ul li a {
    display: block;
    text-align: center;
    font-weight: bold;
    padding: 0px 5px 10px;
    text-transform: uppercase;color: #c8c6c6;
    font-size: 16px;
}
.product-description-tab ul li a:hover, .product-description-tab ul li.active a{  color: #000;}

.menu-section_full {
    overflow: hidden;
}
.delevery_popup_datetime, .vouchergift-popup{
    max-width: 740px; margin:15px auto; width: 100%; background: #fff; position: relative; border: 0px solid #523628;}
.checkout-heading {
    color: #523628;
    font-size: 20px;
    padding: 20px 10px 10px;
    position: relative;  line-height: 1; font-weight: normal; font-family: 'Athelas';}
.controls.two-links a{	color: #000;  text-transform: uppercase; font-size: 15px; display: block; }
.controls.two-links { display: flex; justify-content: center; align-items: center;}
.controls.two-links a:hover{ color: #000; }



.or-seperator {
    margin: 40px 0 15px;
    text-transform: uppercase;
    color: #868686;
    font-size: 15px;
    position: relative;
    z-index: 1;
}
.or-seperator:before {
    height: 2px;
    background: #e1e1e1;
    content: "";
    width: 100%;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;
}
.or-seperator span {
    background: #fff;
    padding: 0 10px;
}
header {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 99;
    margin: auto;
    background: #fff; box-shadow: 0px 0px 9px 0px rgba(0,0,0, 0.10);
}
.logo a {position: relative;}
header .logo a img {transition: all .5s ease-in-out;}
.header-bottom .header-bottom-innersec {position: relative;}
ul.hmenu_list > li > a {
    font-family: 'Lato';
	font-style: normal;
    font-weight: 400;
    font-size: 15px;
    color: #2f2f2f;
    text-transform: uppercase;
    padding: 20px 5px;
    display: block;
    transition: 0.5s linear all;
    position: relative;
    margin: 0 5px;
    line-height: 1;
}
ul.hmenu_list > li.active > a{ font-weight: bold}

.hsign_sec > span {
    font-size: 14px;
    vertical-align: text-bottom;
    position: relative;
    top: 0px;
    color: #525252;
    margin: 0 5px;
}

.hsign_sec a {font-size: 15px;font-weight: 400;color: #525252; text-transform: uppercase;font-family: 'Lato';}
.hsign_sec a:hover, ul.hmenu_list > li > a:hover {color: #523628;}
.haction_sec {text-align: center; position: relative;}
.hmenu_actions {
    position: absolute;
    top:10px;
    right: 0;
    padding: 0;
     display: -webkit-box;
     display: -ms-flexbox;
     display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.htico_search> a > i, .htico_search_mbl > a > i {
    background: url(../images/search-icon.png) no-repeat center; width: 24px; height: 24px; display: block;
}

.header_in {padding-top: 45px; position: relative;}
.hcart_round {
    background: #523628;
    min-width: 20px;
    min-height: 20px;
    font-size: 10px;
    border-radius: 100%;
    position: absolute;
    right:0px;
    top: -4px;
    text-align: center;
    color: #fff;
    line-height: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-weight: bold;
}

.featured-products-full .slick-dots {display: none !important;}
.checkout-terms-and-condition {
    height: 136px; overflow-y: auto; margin: 0 0 15px 0; font-size: 16px; border: 1px solid #d0cfcb; padding: 15px 10px
}
.divide-banner {
    background: #f8f7f7;
    padding: 20px 0;
    box-shadow:0px 1px 18px 0px rgba(0, 0, 0, 0.1);
}
.divide-banner .container{     display: flex; flex-wrap: wrap; }
.parlaxx-img {
    position: relative;
    height: 295px;
}
.parlaxx-img img{ width: 100%; height: 100%; object-fit: cover;}
.db{  width: 50%; position: relative;}
.db-lhs { border-right: 10px solid #f8f7f7;}
.db-rhs { border-left: 10px solid #f8f7f7;}
.banner-content {
    position: absolute;
    top: 50%;
    left: 10px;
    right: 10px;
    margin: auto;
    text-align: center;
    transform: translateY(-50%);
}
.banner-content h2{ color: #fff; margin: 0 0 10px 0; font-family: 'Athelas';font-weight: normal; text-transform: capitalize;text-shadow: 3px -1px 6px rgba(0,0,0,0.50);}
.banner-content .button{
    letter-spacing: 0;
    background: rgba(255,255,255,0.61);
    border:0;
    font-weight: normal;
    margin: 0;
    padding: 14px 32px;
    border-radius: 0;
    font-family: 'Athelas';
    color: #010101; font-size: 20px; text-transform: capitalize
}
.db:hover .button{ background: rgba(255,255,255,1); }

.intro-text {
    max-width: 715px;
    margin: 0 auto;
}

.welcome-home {
    padding: 30px 0;
}

.intro-text h5 {
    margin: 0;
}

.intro-text h3 {
    font-family: 'Athelas';
    font-weight: normal;
    color: #523628;
    font-size: 40px;
    margin: 0px 0px 20px 0px;
}

.intro-text h5 {
    font-family: 'Athelas';
    font-weight: normal;
    color: #523628;
    padding: 15px 0 10px 0;
}
.hg-middle {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.hg-middle-inner {
    width: 49.5%; box-shadow: 0px 1px 18px 0px rgba(0, 0, 0, 0.1);
}
.hg-bottom ul {
    padding: 0;
    margin: 0;
    display: flex;
    list-style: none;
    justify-content: space-between;
}

.hg-bottom ul li {
    width: 24.2%;
    position: relative;
    box-shadow: 0px 1px 18px 0px rgba(0, 0, 0, 0.1);
}
.hg-bottom ul li a, .hg-middle-inner a {
    position: relative;
    display: block;
}

.hg-middle figcaption, .hg-bottom figcaption {
    position: absolute;
    top: 50%;
    left: 25px;
    right: 25px;
    transform: translateY(-50%);
    text-align: center;
    background: rgba(255,255,255,0.61); font-family: 'Athelas'; color: #010101;
    padding: 10px; font-size: 21px; transition: 0.3s linear all;
}

.home-gallery figcaption p {
    margin: 0;
}
.hg-top{ box-shadow: 0px 1px 18px 0px rgba(0, 0, 0, 0.1);}
.hg-top .home-banner{line-height: 0}
.hg-top, .hg-middle{margin: 0 0 15px 0}
.hg-bottom ul li figure, .hg-middle-inner figure, .parlaxx-img{position: relative;background: #000;}
.hg-bottom ul li figure img, .hg-middle-inner figure img, .parlaxx-img img{ opacity: 0.75; transition: 0.3s linear all; }
.hg-bottom ul li:hover figure img, .hg-middle-inner:hover figure img, .db:hover .parlaxx-img img{ opacity: 1 }

.hg-bottom ul li figure:after, .hg-middle-inner figure:after, .hg-bottom ul li figure:before, .hg-middle-inner figure:before, .parlaxx-img:after, .parlaxx-img:before{
position: absolute;
top: 0px;
right: 0px;
bottom: 0px;
left: 0px;
content: '';
opacity: 0;
-webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
transition: opacity 0.35s, transform 0.35s;
pointer-events: none; z-index: 1;
}
.hg-bottom ul li figure:before, .hg-middle-inner figure:before, .parlaxx-img:before {
    border-top: 3px solid #000;
    border-bottom: 3px solid #000;
    -webkit-transform: scale(0,1);
    transform: scale(0,1);
}
.hg-bottom ul li figure:after, .hg-middle-inner figure:after, .parlaxx-img:after {
    border-right: 3px solid #000;
    border-left: 3px solid #000;
    -webkit-transform: scale(1,0);
    transform: scale(1,0);
}
.hg-bottom ul li:hover figure:before, .hg-middle-inner:hover figure:before, .hg-bottom ul li:hover figure:after, .hg-middle-inner:hover figure:after, .db:hover .parlaxx-img:before, .db:hover .parlaxx-img:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

.hg-bottom ul li:hover figcaption, .hg-middle-inner:hover figcaption {
    background: rgba(255,255,255,1);
}

.home-cafe {
    margin: 35px 0;
    position: relative;
 
}
.home-cafe-container{
       display: flex;
    flex-wrap: wrap;
    max-width: 1320px;
    margin: 0 auto;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
}


.hc-left {
    width: 61.25%;
}

.hc-right {
    width: 38.75%;
    background: #f8f7f7;
}

.home-cafe .container {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: flex-end;
}

.homecafe-text {
    background: #fff;
    max-width: 600px;
    padding: 30px;
    box-shadow:0px 1px 29px 0px rgba(0, 0, 0, 0.1);
    margin-right: 20px;
}

.hc-left img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.homecafe-text h3, .story-top-text h3 {
    color: #523628;
    text-transform: uppercase;
    font-family: 'Athelas';
    font-weight: normal;
    font-size: 32px;
}

.homecafe-text p, .story-top-text p {
    font-size: 17px;
}

.homecafe-text .button {
    margin-bottom: 0;
    width: 170px;
}
.promo-banner ul.slick-dots, .hg-top ul.slick-dots {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 20px;
}
.shop-banner .slick-arrow{ font-size: 0; padding: 0; border: 0; margin: 0; position: absolute; top: 50%; transform: translateY(-50%); width: 18px; height: 38px; background: none; left: 10px; line-height: 0.5}
.shop-banner .slick-arrow:before{ content: "\f104"; font-size: 54px; font-family: FontAwesome; position: absolute; left: 0; top: 0; bottom: 0; margin: auto; color: #000000; font-weight: normal; line-height: 0.7}
.shop-banner .slick-arrow.slick-prev:before{ content: "\f105"; }
.shop-banner .slick-arrow.slick-prev {right: 10px; left: inherit;}
.shp-content {
    position: absolute;
    top: 50%;
    left: 100px;
    right: 100px;
    margin: auto;
    z-index: 2;
    text-align: center;
    transform: translateY(-50%);
}
.shp-content h3 {
    font-family: 'Athelas';
    text-transform: uppercase;
    color: #fff;
    font-weight: normal;
    font-size: 44px;
    margin: 0;
}
.shp-content h4 {
    text-transform: uppercase;
    color: #fff;
    font-weight: 400;
    font-size: 28px;
    margin: 0 0 10px 0;
}
.shp-content a{ letter-spacing: 0; background: rgba(82,54,40,0.71); letter-spacing: 0; color: #fff;border-radius: 0}
.shop-banner{ margin: 0 0 60px 0;}
.promo-banner{ margin: 40px 0 40px 0; }
.advance-notice {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
    background: rgb(82 54 40 / 83%);
    color: #fff;
    font-weight: 700;
    font-size: 14px;
    padding: 5px;
}
span.tag-float {
    position: absolute;
    top: 0;
    left: 0;
    background: #3eb4aa;
    color: #fff;
    line-height: 1;
    padding: 4px 7px 6px 7px;
    font-weight: 700;
}
.welcome-banner {
    padding: 50px 0 0 0;
}
.welcome-banner .container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.welcome-lhs {
    width: 45%;
    color: #6f6e6e;
    padding-right: 35px;
}
.welcome-rhs {
    width: 55%;
}
.welcome-lhs h3{
  font-family: 'Athelas';
    text-transform: uppercase; color: #523628;     font-size: 36px;        font-weight: normal; 
}
.welcome-lhs p{font-size: 18px; line-height: 1.4;}
.welcome-lhs ul{list-style: none; padding: 0; display: flex;align-items: flex-end;}
.welcome-lhs ul li{margin: 0 30px 10px 0}
.welcome-rhs .slick-slider .slick-slide img{padding:0 5px 5px 5px;}


.page404-main-div {
    text-align: center;
      height: 100vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
   -webkit-box-orient: vertical;
   -webkit-box-direction: normal;
   -ms-flex-flow: column wrap;
   flex-flow: column wrap;
}
.page404-main-div .gohome {
    display: inline-block;
    line-height: 1;
    font-weight: bold;
    background: #000;
    padding: 15px 50px;
    border-radius: 4px;
    text-align: center;
    background: #000;
    margin: 10px;
    -webkit-transition: 0.3s linear all;
    transition: 0.3s linear all;
    color: #FFF;
}
.page404-main-div h1{margin-bottom: 10px; margin-top: 40px;}
.copyright-section {padding: 25px 10px;border-top: 1px solid #d9d9db; margin-top: 50px}
.innerproduct-button .disabled {background: #d5d1d2 !important; color: #ffa700;}
.innerproducts-list-maindiv {
    display: -webkit-box; display: -ms-flexbox; display: flex; -ms-flex-wrap: wrap; flex-wrap: wrap;
}
.innerproduct-button .btn_minwid {background: #98684a;}
.product-detail-empty {
    padding: 52px 0px 35px 0px;
    text-align: center;
    color: #cb4d36;
    font-weight: bold;
}

.disable-addtocart {
    pointer-events: none;
    cursor: default;
}
.disable-addtocart:after {
    background: hsla(0, 0%, 100%, 0.8) none repeat scroll 0 0;
    bottom: 0;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 99;
    content: '';
}
.modfr-alert-danger {
    display: none;
    background: #ffdfdf;
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    text-align: center;
    padding: 10px;
    font-size: 13px;
    color: #c10606;
}
.modfir_addcart_cls .qty_bx input {
    color: #000;
}
.mdf_common_rows {
    position: relative;
}
.compo_pro_acc {
    background: #f9f9f9;
}
.prd_chosen_row.compo_top_div {
    background: #ffffff;
}

.compoMdfr_item_left {
    width: 100%;
    padding: 0;
    margin-bottom: 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.compoMdfr_item_left .product_chosen_addons {
    width: 30%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.compoMdfr_item_left .product_chosen_hea {
    margin: 0px;
}
.compoMdfr_item_left .form-group {
    width: 70%;
    float: left;
    margin: 0;
}
.compoMdfr_item_left .product_chosen_hea h6 {
    font-size: 14px;
}
.compopro_acc_head {
    padding: 14px 56px 14px 20px;
    cursor: pointer;
        display: flex;
    justify-content: space-between;
    align-items: center;
}
.compopro_acc_head span{font-size: 16px;}
.compopro_acc_head::before, .compopro_acc_head::after {
    background: #000;
    content: "";
    display: block;
    height: 2px;
    margin-top: -2px;
    pointer-events: none;
    position: absolute;
    right: 20px;
    top: 50%;
    transition: transform 0.25s ease-in-out 0s;
    -webkit-transition: transform 0.25s ease-in-out 0s;
    width: 14px;
    z-index: 2;
}
.compopro_acc_head::before {
    content: "";
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);}
.compopro_acc_head::after {
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
}
.compo_acc_active .compopro_acc_head::before {
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
}
.compo_acc_active .compopro_acc_head::after { 
     transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
}

.compo_acc_active .compopro_acc_head::before { transform: rotate(0deg)}
.text-uppercase {text-transform: uppercase;}
.product-rhs .cart_footer {padding: 20px 20px; box-shadow: 0px 1px 16px 0px rgba(1, 1, 1, 0.14); margin-top: 15px;}
.cart_row.grant-total-cls p, .cart_row.grant-total-cls span {font-size: 27px; line-height: 1.2; font-weight: bold}
.cart_row.grant-total-cls sup {font-size: 70%; position: relative; top: -5px;}

.cart_footer .cart_row .row-replace {
    display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-pack: justify;
    -ms-flex-pack: justify; justify-content: space-between;
}
.cart-header-first h4 {font-size: 17px; text-transform: uppercase; margin: 0 0 5px 0;font-weight: bold}
.cart-header-first p {margin: 0}
.cart-header-second h5 {font-size: 17px; text-transform: uppercase; margin: 0 0 10px 0;font-weight: bold}
.cart-header-second h3 {font-size: 17px;}
.cart-header-second p {font-size: 18px;}
.cart_footer .cart_remove {top: 2px; right: -15px;}
.cart_footer .cart_remove:hover {background-color: #000;}
.product-details {padding: 15px 12px; position: relative;}
.order-details-with-clear{	display: flex; justify-content: space-between; padding: 12px 20px;background: #f7f7f7; align-items: center; margin: 0 0 10px 0;}
.order-details-with-clear h5{ color: #000; margin: 0; font-size: 17px; text-transform: uppercase; font-weight: bold}
.cart_body {padding: 20px 20px;}
.cartaction_bottom{ line-height: 0 }
.cartaction_bottom .button {    margin: auto; width: 100%;  border-radius: 0px;  font-weight: 500; background: #523628; color: #fff; }
.cartaction_bottom .button:hover {background: #000;color: #fff}
.cart_row.grant-total-cls {
    padding-top: 10px; margin-bottom: 0px;  font-weight: normal;
}
.Viewreceipt .cart_row.grant-total-cls{ border-top: 0; margin-bottom: 0; padding-top: 0 }
.Viewreceipt .tnk-delivery .cart_footer.tnk_cart_footer {padding: 20px 10px 10px;}
.Viewreceipt .delivery_total::before {
    position: absolute; left: 50px; right: 30px; background: #fff;
    top: 10px; content: ""; margin: auto; border: 2px dashed #dfdad7;
}
.Viewreceipt .tnk-delivery .delivery_total_number:before{display: none;}

#cart-close-span {background: #000;
    width: 22px;
    height: 22px;
    position: absolute;
    padding: 5px 5px;
    top: 10px;
    right: 10px;
    border-radius: 5px;}
#cart-close-span:before, #cart-close-span:after {     position: absolute;
    left: 10px;
    content: '';
    height: 14px;
    width: 2px;
    background-color: #fff;
    top: 4px;}
#cart-close-span:before {transform: rotate(45deg);}
#cart-close-span:after {transform: rotate(-45deg);}

.cart-header-first .row-replace .col-sm-cls, .cart-header-second .row-replace .col-sm-cls { width: 49.5%}
.hcart_add_innr_loca { display: flex; align-items: center; justify-content: flex-end; margin: 10px 0 5px;}
.hcart_add_innr_loca input.form-control { margin: 0; height: 38px; padding: 5px; border-radius: 6px;}
.hcart_add_innr_loca .input_field { width: 100px; margin-left: 8px;}
a.cart-loc-change {
    color: #333;
    font-size: 14px;
    border-bottom: 1px solid #c5c5c5;
    line-height: 1;
    display: inline-flex;
}
a.cart-loc-change:hover{ color: #523628; }
body.cart-items-open{ overflow: hidden !important }
.gst-row {margin-top: 15px;}
.product-category-list {position: relative;text-align: center}
.product_list_div { margin: 0 0 50px 0; background: #fff; padding: 45px 20px 20px; border-radius: 15px;}

.product-title h5{  font-size: 18px;  margin-bottom: 10px; font-weight: 300; }
.product-category-list h2 {margin: 0px 0px 15px 0;text-transform: uppercase;font-weight: normal; font-size: 32px; color: #523628;font-family: 'Athelas';}
.product-category-list p {margin: 0;color: #949292;position: relative;font-size: 14px;}
.product-category-list p.desc:empty {display: none;}
p.total_price.product_details_price {    font-size: 34px;
    color: #000;
     margin:0;
    width: 150px;
    text-align: left; font-weight: bold;  }
p.total_price.product_details_price sup {font-size: 55%;}
.innerproduct {padding-top: 10px;}
.del_address h5{ margin-bottom: 10px;    font-size: 15px; letter-spacing: 0.3px;}
.del_address{ border-bottom: 1px solid #f4f4f4; margin-bottom: 20px; padding-bottom:5px; }
.del_address .form-control{     padding: 10px 10px; height: 38px;}
.form-group.half-fg {display: flex; justify-content: space-between;}
.form-group.half-fg .input_field{ width: 49%; }
.cart_body .hclear_cart:hover {color: #000;}
.compo_minmax_maindiv .combo-input-label {display: block;}
.compo_minmax_maindiv .max-min-bar {padding-bottom: 10px; font-size: 16px; text-align:left;}
.compo_minmax_maindiv .error_combo_div {color: red; padding-bottom: 10px; display: none;}
.compo_minmax_maindiv .combo_pro_price {color: #a7a7a7;}

.alert-success {background-color: #dff0d8; color: #3c763d; border-color: #d6e9c6; justify-content: center; text-align: center;}
.alert-success.alert_fixed a {
    font-size: 22px;
    line-height: 0.6;
    margin-right: 4px; position: static;
}
.custom_close span {color: red;}
.alert-success:before,
.single-danger:before {top: 10px; left: 8px;}

.jquery-success-msg {margin-left: 20px;}
.alert-danger {color: #a94442; background-color: #f2dede; border-color: #ebccd1;}

.btn-medium {min-width: 190px;}
.cart_row.cart-header-second {margin: 15px 0 0 0;}

.cart-direction {
    margin: 0 0 15px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative
}
.tnk-delivery .cart-direction {display:none;}
.tnk-chk-order .button {display: block; margin: 0; width: 100%; padding: 15px 20px 15px; font-size:18px; background: #523628; color: #fff; border-color: #523628}
.tnk-chk-order .button:hover {background: #000;color: #fff;border-color: #000;}
.thank-order-detaildiv { max-width: 620px; margin: 0 auto; border: 2px solid #f2f2f2; border-radius: 6px;}
.mainacc_toptext {
    text-align: center;
    padding: 0 0 20px 0;color: #523628; font-family: 'Athelas';
}
.mainacc_toptext h2 {margin: 20px 0 20px 0; font-family: 'Athelas'; font-weight: normal; color: #523628}

.mainacc_toptext p {font-size: 20px;  margin: 0;padding-top: 20px;}

#CartListMdl .total_amt {
    overflow: hidden;
    padding: 25px;
}
#CartListMdl .total_amt .subtotal {
    font-size: 17px
}
#CartListMdl .order_details .panel-group {
    margin: 0; padding: 0;
}
#CartListMdl .package .title {
    padding-left: 0px
}
#CartListMdl .package .package_cnt {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 0px 0px 5px;
    border-bottom: 1px solid #cecece;
    margin: 0 0 15px 0;
}

#CartListMdl .package .package_cnt .col-sm-cls {
    width: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

#CartListMdl .package .package_cnt .col-sm-cls .hr-flt-cls {
    font-size: 19px;
    margin: 0px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

#CartListMdl .addon {
    padding: 10px 0px 0px 10px;
}

#CartListMdl .package .package-list {
    padding: 0px 0px 5px 10px;
    color: #000;
}

#CartListMdl .buffet_setup {
    padding: 5px 0px 5px;
}

#CartListMdl .buffet_setup .row-replace,
#CartListMdl .addon .row-replace.addon_list,
#CartListMdl .total_amt .subtotal {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

#CartListMdl .buffet_setup h5 .title {
    padding-left: 10px
}

#CartListMdl .buffet_setup p {
    margin: 0px 0px 7px 25px;
}

#CartListMdl .brline {
    display: none
}

#CartListMdl .addon_list p {
    margin: 0px 0px 5px 20px;
}

#CartListMdl .addon_list h5 .title {
    padding-left: 20px;
}

#CartListMdl .order_details .panel-collapse {
    height: 600px;
}

#CartListMdl .Cart-list-mdlheader h2,
#PackageEditMdl .Cart-list-mdlheader h2 {
    text-align: center;
    font-size: 32px;
    margin: 5px 0 20px 0;
}

#CartListMdl button.close,
#PackageEditMdl button.close {
    -webkit-appearance: none;
    padding: 0;
    cursor: pointer;
    background: 0 0;
    border: 0;
    margin: 0;
    position: relative;
    overflow: hidden;
    text-indent: -9999px;
    width: 20px;
    height: 20px;
}

#CartListMdl button.close:before,
#CartListMdl button.close:after,
#PackageEditMdl button.close:before,
#PackageEditMdl button.close:after {
    position: absolute;
    left: 9px;
    content: ' ';
    height: 20px;
    width: 2px;
    background-color: #000;
    top: 0px;
}

#CartListMdl button.close:before,
#PackageEditMdl button.close:before {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

#CartListMdl button.close:after,
#PackageEditMdl button.close:after {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

#PackageEditMdl .bx_in {
    padding: 20px 20px;
}

#PackageEditMdl .package_checkitem {
    padding: 20px
}

#PackageEditMdl .chk-payment-btn {
    margin-bottom: 0
}

#PackageEditMdl .pkselected_filtersec .row-replace {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

#PackageEditMdl .pkselect_tabin {
    padding: 0;
}

#PackageEditMdl .pkselected_package label {
    position: relative;
    padding: 5px 0 0 0px;
}
.myacc_order_details {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}
.myacc_order_details .current_order {
    width: 48.5%;
    margin-bottom: 25px;
    border: 1px solid #f1f1f1;
    padding: 0 0 120px 0;
    position: relative
}
.myacc_head_sec {
    background: #f1f1f1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    position: relative;
    padding: 15px;
    overflow: hidden
}

.head_right .head-group {
    z-index: 2;
    position: relative
}

.head_left h4 {
    color: #000000;
    margin: 0;
    font-size:19px
}

.head_right h4 {
    color: #000;
    margin: 0;
    text-transform: capitalize;
    font-size: 17px
}

.head_right::before {
    content: "";
    display: block;
    background: #ece4e0;
    width: 38%;
    position: absolute;
    right: -30px;
    height: 100%;
    top: 0;
    -webkit-transform: skew(-39deg);
    transform: skew(-39deg);
    z-index: 1;
}

.order_details_footer {position: absolute; bottom: 0; left: 0; right: 0; margin-top: 5px;}
.order_btns,
.order_amt {
    display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-orient: horizontal;
    -webkit-box-direction: normal; -ms-flex-flow: row wrap; flex-flow: row wrap;
}
.order_btns, .order_amt {justify-content: space-between;  padding:16px 8px 8px;}
.order_btns .button {  margin: 0; width: 49%; display: block; padding: 12px 5px 12px; font-size: 16px; }
.order_btns .print_invoice {background: #ffff; }
.order_btns .view_recipt {background: #523628; border: 2px solid #523628; color: #fff;}
.order_btns .view_recipt.button_full_view{width: 100%}
.order_btns .button:hover {background: #000; border-color: #000;color: #fff}
.order_amt {
    -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between;
    background: #f6f6f6; padding: 12px 15px 14px; margin-top: 10px;
}
.order_amt h3 {margin: 0; color: #060203; font-size: 20px;font-weight: 600; text-transform: uppercase;}
.order_amt-right sup {font-size: 75%; position: relative; top: -5px; margin: 0 2px 0 0;}
.order_details_body {padding: 15px 15px 20px;}
.order_no_deatails {margin: 0 0 20px 0; text-align: center; font-weight: 500;}
.order_no_deatails span {display: block;}

.delivery_total_left img {margin: 0 0 10px 0;}
.delivery_total {position: relative;}
.delivery_total_text::before {
    position: absolute;
    left: 50px;
    right: 30px;
    background: #fff;
    top: 10px;
    content: "";
    margin: auto;
    border: 2px dashed #dfdad7;
}
.web_order_typecls {
    list-style: none;
    padding: 0;
    margin: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
     -webkit-box-pack: center;
     -ms-flex-pack: center;
     justify-content: center;
    margin: 0 0 50px 0
}

.web_order_typecls > li {
    width: 50%;
    border-left: 2px solid rgba(255, 255, 255, 0);
}

.web_order_typecls li a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    padding: 17px 10px;
    background: #e4e4e4;
    color: #000
}

.web_order_typecls li.active a,
.web_order_typecls li a:hover {
    background: #ffa700
}

.web_order_typecls li a h5 {
    margin: 0;
    color: #000;
    text-transform: uppercase
}
.web_order_typecls li.active a h5, .web_order_typecls li a:hover h5{ color: #fff; }

.sprite_lst_icon {
    margin: 0 10px 0 0
}

.sprite_lst_icon.order_t {
    max-width: 33px;
}

.sprite_lst_icon.catering_icon_t {
    width: 22px;
}

.sprite_lst_icon.reserve_icon_t {
    width: 22px;
}

.myacc_order_details .reserv-orderlist {
    padding: 0;
    border-bottom: 2px solid #000000
}

.myacc_order_details .reserv-orderlist .order_no_deatails {
    margin: 15px 0px 0;
    border-top: 1px solid #e4e4e4;
    padding: 20px 0 0 0;
}

.myacc_order_details .reserv-orderlist .order_no_deatails h3 {
    margin: 0;
    color: #000;
    font-size: 19px;
    text-transform: uppercase;
}

.myacc_order_details .reserv-orderlist .order_no_deatails p {
    margin: 0;
    color: #98684a;
    font-size: 21px;
    font-weight:bold;
}

.reserv-loader-div {
    display: inline-block
}

.reserv-orderlist .delivery_total.delivery_total_number .delivery_total_left h4 {
    font-size: 19px;
}

.reserv-orderlist .delivery_total.delivery_total_number .delivery_total_left h2 {
    margin-bottom: 5px
}

.reserv-orderlist .delivery_total.delivery_total_number {
    margin: 0;
    padding: 0
}

.web_order_typecls li:hover .count_num_t,
.web_order_typecls li.active .count_num_t {
    background: #ffd7b8;
}

.ord-pagination-col select {
    width: auto;
    height: auto;
    padding: 1px 0 1px 1px;
    border: 0;
    margin: 0 5px;
    background: none;
}

.delivery_submit_div .button {
    width: 100%;
}
.mainacc_menulist #masterCount {
    background: #523628;
    height: 20px;
    display: inline-block;
    border-radius: 50%;
    text-align: center;
    color: #fff;
    margin-left: 5px;
    font-size: 11px;
    line-height: 21px;
    vertical-align: top;
    margin-top: -1px;
    padding: 0 4px;
    min-width: 20px;
    
}
.reward-list-body .delivery_total.delivery_total_number {
    padding: 0; margin: 0;
}
.reward-list-body .delivery_total_left {
    display: inline-block;
    width: auto;
}
.reward-list-body .delivery_total.delivery_total_number .delivery_total_left h4, .expiry_on_full h4 {  font-size: 15px; margin: 0; font-weight: normal; line-height: 1.3}
.reward-list-body .order_amt_div h3 {
    margin: 0;
    font-size: 20px;
    font-weight: 600;
    padding: 20px 0 0 0;
    text-align: center;
}
.reward-list-body .order_amt_div h3 sup {
    font-size: 75%
}
.reward-list-body .order_amt-right h3 {color: #523628;}
.reward-list-body .checkoutDate, .reward-list-body .checkoutTime {position: relative; }
.reward-list-body .myacc_order_details .current_order {padding: 0 0 110px 0;}
.delivery_total_left img, .expiry_on_full img { width: 18px; margin-right: 5px;margin-bottom: 0}

.promo_wrap .both-order-tab {padding: 10px 40px 0 40px;}
.promo-form .myuacc-promo {position: relative; max-width: 490px; margin: 0 auto;}
.promo-form input[type="text"] {
    margin: 0;
    height: 47px;
    padding: 5px 150px 5px 20px;
    background: #fff;
    font-size: 14px;
    color: #615f5f;
}
.promo-form .applyBtn {
    margin: 0;
    border: none;
    position: absolute;
    right: 0;
    top: 0;
    font-size: 16px;
    background: #523628;
    padding: 13px 20px;
    height: 47px;
    min-width: 146px; color: #fff;
}
.promo-form .applyBtn:hover{ background: #523628;  color: #fff;}
.promo-form h3 {
    font-size: 28px;
    margin: 0 0 10px;
    text-transform: uppercase;
    line-height: 1.3; letter-spacing: 0.5px;font-family: 'latoblack';
}
.promo-form h3 span {
    font-size: 16px;
    display: block;
    line-height: 1;
    margin-bottom: 0px;
    letter-spacing: 2px;font-family: 'latoblack';
}
.promo-earned-col-image {position: relative;}
.promo-earned-col-image img{ width: 100%; height: 100%; -o-object-fit: cover; object-fit: cover; object-position: center;}  
.promo-earned-col-item { display: -webkit-box; display: -ms-flexbox; display: flex; -ms-flex-wrap: wrap; flex-wrap: wrap; 
	border: 1px solid #fff; -webkit-box-shadow: 0px 1px 9px 0px rgba(0, 0, 0, 0.14); 	box-shadow: 0px 1px 9px 0px rgba(0, 0, 0, 0.14); background: #fff;}
.promo-earned-info{	-webkit-box-flex: 1;-ms-flex-positive: 1;flex-grow: 1;display: -webkit-box;display: -ms-flexbox;display: flex;-ms-flex-wrap: wrap;flex-wrap: wrap; padding: 20px 10px 10px;	width: calc(100% - 240px); align-items: center; justify-content: center; text-align: center; position: relative; z-index: 1;}

.promo-earned-info:before{  content: "";
    content: "";
    z-index: -1;
    position: absolute;
    top: 0px;
    left: -37px;
    bottom: 0px;
    background: #ffffff;
    width: 80px;
    -webkit-transform: skewX(-20deg);
    transform: skewX(-20deg); 
}
.promo-earned-code{  font-size: 15px;margin: 0 0 5px 0; text-transform: uppercase; }
.promo-earned-code span{display: block; font-size: 20px; margin-top: 5px;font-weight: bold}
span.promo-valid { color: #a6a6a6;  font-size: 15px; padding: 0 0 15px 0;display: block;}
.promation_btn{width: 100%;}
.promation_btn .button{margin: 0; background: #523628; width:47%;  font-size: 15px; padding: 11px 10px 11px; color: #fff; min-width: inherit; }
.promo-earned-info p{ font-size: 20px;  color: #4a4646; }
.promation_btn .promation_btn-one{ background: #fff; margin-right: 10px;color: #523628; border-color:#523628;}
.promation_btn .button:hover{ background: #523628; color: #fff; border-color: #523628; }
.promo-bot-left span {display: block;}
.promo-bot-left p {margin: 0; text-transform: uppercase; font-size: 18px;}
.promo-bot-left p span {display: block; font-size: 14px;}
.promation_btn {line-height: 0;}
.promo-earned-col .tag {
    font-size: 16px;
    text-transform: uppercase;
    color: #fff;
    background: #000000;
    margin: 0;
    padding: 10px 12px 5px;
    position: absolute;
    left: -43px;
    top: 30px;
    transform: rotate(-40deg);
    -webkit-transform: rotate(-40deg);
    width: 220px;
    line-height: normal;
    text-align: center;
}
.promo-earned-col .tag span {
    display: inline-block;
    width: 130px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.mypromo-main-div .myacc_order_details {
    justify-content: space-between;
    padding: 0;
    margin: 0;
    list-style: none;
}
.mypromo-main-div .myacc_order_details .promo-earned-col{width: 49%; background: #fff;margin: 0 0 30px 0}
.mypromo-main-div .myacc_order_details li:nth-child(3n) {
    margin-right: 0
}
.pickup-cart-div{ margin-bottom:30px;}
.pickup-cart-div .cart-direction:after {display: none;}
.pickup-cart-div .cart-direction img {width: 50px; margin: 0 auto;}
.pickup-cart-div .cart-header-first .row {display: block;}
.pickup-cart-div .cart-header-first .cart_left {padding: 0 0 10px 0;}

.mobile-order-nowbtn {
    display: none;
    -webkit-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.12);
}
.mobile_accountdel_row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
.mobile_accountdel_col {
    width: 100%;
    background: #000000;
    position: relative
}
/*.mobile_accountdel_col:last-of-type {
    background: #006E5F;
}*/
.mobile_account_item {
    position: relative;
}
.mobile_account_item a.myacunt_act {
    padding: 12px 55px 12px 15px;
    background: #181818;
    color: #fff;
    display: block;
    font-size: 20px;
    text-transform: uppercase;
}
.mobile_mainacc_menutrigger {
    background: url(../images/aroow_myaccount.png) no-repeat center;
    background-size: 24px auto;
    float: right;
    width: 55px;
    height: 49px;
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}
.mobile_account_item_login > a {
    display: inline-block;
    padding: 14px 55px 10px 15px;
    color: #fff;
    
    font-size: 20px;
    text-transform: uppercase;
}
.mobile_account_item_login > a.signup_btn {
    padding-left: 0;
}
.mobile_mainacc_menulist {
    position: absolute;
    left: 0;
    top: 100%;
    right: 0;
    z-index: 1;
    display: none;
    -webkit-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.12);
}
.mobile_mainacc_menulist > ul {
    margin: 0;
    padding: 0;
}
.mobile_mainacc_menulist li a {
    display: block;
    padding: 12px 15px 12px 15px;
    background: #000000;
    font-size: 20px;
    position: relative;
    color: #ffffff;
    text-transform: uppercase;
    
    border-top: 1px solid rgba(255, 255, 255, 0.1);
}
.mobile_mainacc_menulist li a:hover {
    color: #ffa700;
}
.mobile_account_delivery_item {
    list-style: none;
    padding-left: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: 2;
    position: absolute;
}
.mobile_account_item_login a {
    padding-right: 0;
}
.mobile_account_delivery_item a {
    padding: 12px 15px 12px 15px;
    background: #000;
    display: block;
    font-size: 20px;
    text-transform: uppercase;
    position: relative;
    text-align: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.16);
}
.mobile_account_delivery_item .active a { color: #000;}

.mobile_account_delivery_item li a i {
    position: absolute;
    left: 15px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}
.mobile_account_menu {
    background: #000;
    display: none;
}
.mobile_account_menu h3 {
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    border-bottom: 1px solid #fff;
    padding: 10px 10px;
}
.mobile_account_menu_click {
    padding: 10px 20px 10px 20px;
    display: block;
    font-size: 18px;
    color: #fff;
    text-transform: uppercase;
    position: relative;
    text-align: center;
	    background: #523628;
    color: #fff;
    font-weight: bold; letter-spacing: 2px;
}
.mobile_account_menu_click:hover{ background: #523628; color: #fff; }

span.myaccount_separate {
    width: 2px;
    height: 16px;
    display: inline-block;
    background: #fff;
    margin: 0 7px 0 5px;
}

.load_more_div {
    display: block;
    clear: both;
    width: 100%;
    text-align: center;
    padding: 30px 0 0 0
}
.load_more_div:empty {display: none;}
.load_more_div .load_more_data {
    margin: 0 auto;
    min-width: 160px
}

.make-pay-div h4 {
    font-size: 20px;
}

.pay-cart-header {
    padding: 18px 0px 5px 0px !important;
}

.inner_banner {
    height: 400px;
}

.inner_banner img {
    -o-object-fit: cover;
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.white_bgbx {
    background: #fff;
    padding: 30px 25px;
}

.brand_note {
    margin: 0 0 70px;
}

.about_lirow {
    margin: 0 auto 50px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    max-width: 900px;
    position: relative;
}

.about_lirow:last-of-type {
    margin-bottom: 20px
}

.about_liimg {
    width: 50%;
}

.about_liimg img {
    -o-object-fit: cover;
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.about_liinfo {
    width: 50%;
    padding: 50px 50px;
    background: #000000;
}

.diamond_divider {
    position: absolute;
    height: 80px;
    width: 80px;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
}

.diamond_divider span {
    width: 0;
    height: 0;
    border: 40px solid transparent;
    border-bottom-color: #000;
    position: relative;
    top: -22px;
}

.diamond_divider span:after {
    content: '';
    position: absolute;
    left: -40px;
    top: 60px;
    width: 0;
    height: 0;
    border: 40px solid transparent;
    border-top-color: #000;
}

.award_gallery {
    margin: 40px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.award_galcol {
    width: 32%;
    position: relative;
    margin: 0 0 30px;
    z-index: 1
}

.award_galimg {
    height: 360px;
    position: relative;
    overflow: hidden;
    background: #fff;
    padding: 20px;
    border-style: solid;
    border-width: 15px;
    border-top-color: #333333;
    border-right-color: black;
    border-bottom-color: #333333;
    border-left-color: black;
    -webkit-box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6);
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6);
    z-index: 1
}

.award_galimg img {
    -o-object-fit: cover;
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.award_label {
    font-size: 15px;
    text-transform: uppercase;
    color: #fff;
    background: #333333;
    text-align: center;
    margin: 0;
    padding: 10px 12px 5px;
    position: absolute;
    left: -43px;
    top: 25px;
    transform: rotate(-42deg);
    -webkit-transform: rotate(-45deg);
    width: 180px;
    line-height: normal;
    z-index: 1;
    display: none
}

.award_label span {
    display: inline-block;
    width: 130px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.award_galinfo {
    background: rgba(0, 0, 0, 0.9);
    position: absolute;
    left: 35px;
    right: 35px;
    top: 80px;
    bottom: 35px;
    margin: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: #fff;
    padding: 15px;
    -webkit-transition: all 400ms;
    transition: all 400ms;
    text-align: center;
    z-index: 1;
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transition: 0.4s linear all;
    transition: 0.4s linear all;
}

.award_galcol:hover .award_galinfo {
    -webkit-transform: scale(1);
    transform: scale(1)
}

.hook {
    width: 6px;
    height: 6px;
    background-color: #5c5c5d;
    margin: 0 auto 40px auto;
    border-radius: 50%;
    -webkit-box-shadow: 0 1px 0 1px #000000;
    box-shadow: 0 1px 0 1px #000000;
    position: relative;
}

.hook:before {
    content: '';
    width: 100px;
    height: 100px;
    border: 2px solid #a5a1a1;
    position: absolute;
    left: 50%;
    top: 20px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    z-index: -1;
    margin-left: -50px;
}
.award_galinfo h3 {font-size: 18px; color: #fff;}
.award_galinfo h3 a,
.test-popup-link {
    color: #fff;
}
.test-popup-link:hover,
.award_galinfo h3 a:hover {
    color: #98684a;
}
.award_plus {
    display: block;
    font-size: 40px;
    -webkit-transition: all 400ms;
    transition: all 400ms;
}
.award_galcol:hover .award_plus {-webkit-transform: scale(1.15); transform: scale(1.15);}
.test-popup-link:hover .award_plus {border-color: #98684a;}
.change-pickup-location {margin-bottom: 10px !important;}
.clear:after {content: ""; display: block; clear: both;}
.locate-map {
    margin: 0 0 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
.locate-mapleft {
    width: 360px;
}
.locate-list {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column wrap;
    flex-flow: column wrap;
}
.locate-lirow {
    padding: 15px 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column wrap;
    flex-flow: column wrap;
}
.locate-lirow:last-child {border-bottom: 0;}
.locate-lirow:hover {background: #000000; color: #fff;}
.locate-lirow h4 {font-size: 18px; margin: 0 0 4px;}
.locate-lirow:hover h4 {color: #fff;}
.locate-lirow p {margin: 0;}
.locate-lirow.active {background: #000000; color: #fff;}
.locate-lirow.active h4 {color: #fff;}
.locate-mapright {width: calc( 100% - 360px);}
.locate-mapright iframe {vertical-align: top; width: 100%;}
.contact_col {float: left; width: 50%; padding: 0 15px;}
.contact_form .form-control {width: 100%; display: block;}
.contact_form textarea.form-control {margin: 0; height: 105px;}
.contact_form .btn_sec .btn {width: 100%; margin: 3px 0 0;}
.contact_form .btn_sec .btn:hover {background: #000;color: #fff;}
.our-policy {margin: 30px 0 0 0;}
.our-policy h6 {margin: 0 0 10px 0;}
.service-makkan {margin: 0 auto 30px; text-align: center;}
.service-makkan img {margin-top: -110px; max-height: 182px;}
.err_alt {border: 1px solid #ff1313 !important;}
.err_tarms_chk span {color: #ff1313;}
ul.outletul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}
.outletul > li {
    width: 49%; margin: 0 2% 30px 0; background: #000000; position: relative;
}
.outletul > li:nth-child(even) {margin-right: 0;}
.ourrest_img {width: 100%;}
.ourrest_img img {
    -o-object-fit: cover; object-fit: cover; height: 100%; width: 100%;
}
.ourrest_info {width: 100%; padding: 30px 20px 70px 20px; color: #fff;}
.ourrest_info h4 {margin: 0 0 15px; color: #fff; font-size: 22px;font-weight: 600;}
.ourrest_info p {margin: 0 0 8px;}
.ourrest_info a{ color: #fff; }
.ourrest_info a:hover{ color: #523628; }
.ourrest_infolinks {
    list-style: none;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.ourrest_infolinks li {display: block; width: 50%;}
.ourrest_infolinks a {
    padding: 16px 5px;
    display: block;
    color: #fff;
    -webkit-transition: all 400ms;
    transition: all 400ms;
    font-weight:bold;
    height: 100%; text-transform: uppercase; font-size: 16px;
}
.media-links-a a {background: #333333;}
.media-links-b a {background: #523628;color: #000;}
.ourrest_infolinks a:hover { color: #523628; background: #333 }
.ourrest_infolinks a i {display: inline-block; margin: 0 10px 0 0;}
.news_list {
    margin: 40px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}
.news_list > .news_lirow {
    width: 32%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    overflow: hidden;
    margin: 0 0 40px;
    background: #fff;
}

span.date {
    color: #9c9c9c;
    font-size: 16px;
    display: block;
    margin: 0 0 10px 0;
}

.news_liimg {
    width: 100%;
}

.news_liimg img {
    -o-object-fit: cover;
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.news_liinfo {
    width: 100%;
    padding: 20px 0 0 0;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.news_liinfo h4 {
    margin: 0 0 5px;
    font-size: 19px;
    line-height: 1.3;
}

.news_liinfo h4 a {
    color: #000;
    display: block
}

.news_liinfo h4 a:hover {
    color: #000000
}

.news_liinfo p {
    margin: 0 0 20px;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
}

.mfp-bg {
    background: rgba(0, 0, 0, 0.8);
}

#scrollbutton {
    position: fixed;
    bottom: 30px;
    right: 30px;
    width: auto;
    height: auto;
    z-index: 92;
    opacity: 0;
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
    -webkit-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

#scrollbutton.show {
    display: inline-block;
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
    opacity: 1;
    -webkit-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

#scrollbutton a {
    background: #523628;
    display: inline-block;
    position: relative;
    color: #fff;
    text-decoration: none;
    line-height: 38px;
    padding: 0;
    overflow: hidden;
    height: 38px;
    width: 38px;
    text-align: center;
    font-size: 18px;
}

#scrollbutton a:hover { background: #000;color: #fff;}

#scrollbutton a i.ars {
    -webkit-transition: all 0.2s linear 0.2s;
    transition: all 0.2s linear 0.2s;
}

#scrollbutton a:hover i.ars {
    -webkit-transform: translateY(-25px) rotateY(360deg);
    transform: translateY(-25px) rotateY(360deg);
    opacity: 0;
    -webkit-transition: all 0.3s linear 0.2s;
    transition: all 0.3s linear 0.2s;
}

#scrollbutton a i.ars1,
#scrollbutton a i.ars2 {
    position: absolute;
    bottom: 0%;
    left: 50%;
    width: auto;
    height: auto;
    -webkit-transform: translate(-50%, -50%) rotateZ(360deg);
    transform: translate(-50%, -50%) rotateZ(360deg);
    opacity: 0;
    -webkit-transition: all 0.2s linear 0.2s;
    transition: all 0.2s linear 0.2s;
}

#scrollbutton a i.ars2 {
    -webkit-transform: translate(-50%, -50%) rotateZ(0deg);
    transform: translate(-50%, -50%) rotateZ(0deg);
    -webkit-transition: all 0.2s linear 0s;
    transition: all 0.2s linear 0s;
}

#scrollbutton a:hover i.ars1,
#scrollbutton a:hover i.ars2 {
    bottom: 10%;
    opacity: 1;
    -webkit-transform: translate(-50%, -50%) rotateZ(0deg);
    transform: translate(-50%, -50%) rotateZ(0deg);
    -webkit-transition: all 0.2s linear 0.2s;
    transition: all 0.2s linear 0.2s;
}

#scrollbutton a:hover i.ars2 {
    bottom: 0%;
    -webkit-transform: translate(-50%, -50%) rotateZ(0deg);
    transform: translate(-50%, -50%) rotateZ(0deg);
    -webkit-transition: all 0.2s linear 0.4s;
    transition: all 0.2s linear 0.4s;
}

.reservation-full {
    max-width: 780px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.reservation-counter,
.choose-outlet,
.choose-datetime-full,
.special-instructions {
    background-color: rgba(210, 190, 155, 0.38);
    border-radius: 10px;
    clear: both;
    overflow: hidden;
    margin-bottom: 7px;
    padding-top: 23px;
    padding-bottom: 23px;
}

.reservation-full h2 {
    font-size: 40px;
}

.reservation-counter h3,
.choose-outlet h3,
.choose-date h3,
.choose-time h3,
.special-instructions h3 {
    font-family: 'subway_novellaregular';
    font-size: 26px;
    margin-bottom: 10px;
}

.reservation-counter-left h4 {
    
    font-size: 16px;
    margin-bottom: 0px;
    margin-top: 10px;
    text-transform: uppercase;
    margin-right: 10px;
}

.reservation-counter-left,
.reservation-counter-right {
    float: left;
}

.reservation-counter-full {
    max-width: 420px;
    margin-left: auto;
    margin-right: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.choose-date,
.choose-time {
    max-width: 440px;
    margin-left: auto;
    margin-right: auto;
}

.special-instructions textarea {
    max-width: 440px;
    height: 130px;
    resize: none;
    margin-bottom: 0px;
    padding: 20px;
}

.choose-outlet .custom_select {
    max-width: 350px;
    margin: 0 auto 0px !important;
}

.choose-outlet .custom_select select {
    margin-bottom: 0px;
}

.choose-date .catering_days_ul li dl dt,
.choose-time .catering_days_ul li dl dt {
    font-size: 12px;
}

.choose-date .catering_days_ul li dl.active,
.choose-time .catering_days_ul li dl.active {
    border: 2px solid #000000;
}

.choose-date p,
.choose-time p {
    font-family: 'open_sanslight';
    font-size: 12px;
    text-align: left;
    margin-bottom: 0px;
}

.choose-time {
    margin-top: 30px;
}

.reservation-summary {
    max-width: 425px;
    margin-left: auto;
    margin-right: auto;
    background-color: #fff;
    border: 2px solid #cfbfaa;
    padding: 25px;
    overflow: hidden;
}

.reservation-summary ul {
    padding-left: 0px;
    list-style-type: none;
    padding-bottom: 0px;
}

.reservation-summary ul li span {
    
    font-size: 15px;
    text-transform: uppercase;
    overflow: hidden;
    width: 50%;
    float: left;
    text-align: left;
}

.reservation-summary ul li span:first-child {
    position: relative;
    padding-right: 10px;
}

.reservation-summary ul li span:last-child {
    padding-left: 10px;
}

.reservation-summary ul li span:first-child::after {
    content: ":";
    position: absolute;
    top: 0px;
    right: 0px;
}

.reservation-summary ul li {
    margin-bottom: 15px;
    overflow: hidden;
}

.reservation-summary ul li:last-child {
    margin-bottom: 0px;
}

.reservation-summary ul li span.summary-email {
    text-transform: lowercase;
}

.reservation-summary ul li span.summary-childseat {
    font-style: italic;
    text-transform: lowercase;
    color: #4e4e4e;
    font-size: 14px;
}

.reservation-summary-full {
    max-width: 860px;
    margin-left: auto;
    margin-right: auto;
    background-color: rgba(210, 190, 155, 0.38);
    border-radius: 10px;
    padding-top: 25px;
    padding-bottom: 50px;
    overflow: hidden;
}

.reservation-summary-full h3 {
    font-family: 'subway_novellaregular';
    font-size: 26px;
    margin-bottom: 20px;
}

.reservation-summary-button {
    max-width: 860px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-top: 8px;
}

.reservation-summary-button .go-back,
.reservation-summary-button .continue {
    width: 49.5%;
    float: left;
}

.reservation-summary-button .go-back {
    background-color: #000;
}

.reservation-summary-button .continue {
    background-color: #98684a;
}

.reservation-summary-button .go-back a,
.reservation-summary-button .continue a {
    margin-top: 0px;
    margin-bottom: 0px;
    background: none;
}

.reservation-summary-button .go-back a:hover {
    background: #000000;
}

.reservation-summary-button .continue a:hover {
    background: #333;
}

.go-back a {
    width: 100%;
    display: block;
    margin: 0;
    font-size: 20px;
}

.choose-date .catering_days_ul li dl:hover,
.choose-time .catering_days_ul li dl:hover {
    border: 2px solid #a23c2b;
}

.choose-date .catering_days_ul li dl.cl-next-days,
.choose-time .catering_days_ul li dl.cl-next-days {
    border: none;
}

.choose-outlet select {
    font-size: 15px;
    color: #595656;
    padding-left: 15px;
    padding-right: 15px;
}

.reservation_order_details {
    text-align: center
}

.reservation_outlet,
.reservation_user,
.reservation_Date,
.reservation_Time {
    border-bottom: 1px solid #e4e4e4;
    padding: 0 0 15px 0;
    margin: 0 0 18px 0
}
.reservation_order_details h4 {color: #818181;}
.reservation_order_details p {color: #000; font-weight:bold; font-size: 19px;}
a.add-address-more {
    display: inline-block;
    position: relative;
    font-size: 24px;
    margin: 0 0 0 5px;
}
.address_linfo {margin-bottom: 10px;}
.address_linfo .custom_radio span {color: #555555;font-size: 15px;}
.address-list-cls {max-height: 240px; overflow-y: auto;}
.phone-show-mbl {display: none;}
.phone-show-mbl a {color: #fff;}
.disclaimer {margin: 30px 0 0 0;}
.disclaimer h4 {font-size: 18px; text-transform: uppercase; margin-bottom: 12px;}
.disclaimer ul {padding-left: 20px;}
.horder_tt {padding: 12px 10px 12px 20px;}

.oreder-row-inv {margin: 0;}
.oreder-row-inv .cart_img {padding: 0;}
.oreder_itm_row {padding: 15px 10px !important;}
.order-items-maindiv {background: #f5f5f5;}
.Viewreceipt .order-items-maindiv{background: #fff;}
.Viewreceipt .cart_price{ padding-right: 0 }
.Viewreceipt .tnk-delivery .delivery_total_number{
	border-bottom: 1px solid #e4e4e4; padding-bottom: 20px; margin-bottom: 10px;
}
.oreder-row-inv .cart_left {text-align: left;}
.month-select-div, .choose-date-div {margin: 0 auto 40px auto;}
.tarms-error {display: none; color: #f03011;}
.catering-product-rowlist span.min_pax_spn {
    display: block; font-size: 17px; text-transform: uppercase; margin: 0 0 20px 0;
}
.catering-product-rowlist .price_nor {
    font-size: 22px;
    display: block;
    padding: 10px 0 0 0;
    
    font-weight: bold;
}

.catering-product-rowlist .price_nor sup {
    font-size: 80%;
    text-transform: uppercase;
}
.dotted-line-top {
    border-top: 1px dotted #ccc;
    padding: 20px 0 0 0;
    margin: 10px 0 0 0;
}
.custom-tarms-div .sign_reg {
    padding-left: 30px
}
.custom-tarms-div span:before {
    width: 20px;
    height: 20px;
    border: 2px solid #ffa700;
    margin-top: 2px;
    border-radius: 0;
}
.custom-tarms-div .custom_checkbox input[type=checkbox]:checked + span:before {
    background: none;
    content: "\F00C";
    font-family: FontAwesome;
    text-rendering: auto;
    font-size: 16px;
    line-height: 16px;
    color: #fff;
    text-align: center;
    background: #ffa700;
    border: 2px solid #ffa700;
}

.error_div_cls {
    color: red;
    display: none
}

.single_outlet_info {
    padding-top: 15px;
    text-align: center;
}

.single_outlet_info p {
    margin: 0;
    font-size: 16px;
}

.single_outlet_info .mainacc_toptext p {
    font-size: 20px;
    font-style: normal;
}

.isDisabled {
    cursor: default;
    text-decoration: none;
}

.disableDivCls {
    display: none;
}

.event-type-single {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.rmv-border-bottom {
    border-bottom: 0px !important;
}

.no-stock-product .innerproduct-item::after {
    opacity: 1 !important;
}

.no-stock-product .innerproduct-button {
    opacity: 1 !important;
    visibility: visible !important;
    top: 50% !important;
}

.no-stock-product .innerproduct-button .btn {
    cursor: not-allowed;
    pointer-events: all !important;
}

h3.choose-eventtype {
    position: relative;
}

a.go-bck-icon {
    position: absolute;
    left: 0;
    top: 0;
}

a.go-bck-icon:before {
    content: "\f0a8";
    font-family: Fontawesome;
    font-size: 30px;
}

.hall-select-popup .mfp-close {
    color: #fff
}

.pkg-detail-top-row .pkselected_catgrytext {
    text-align: left;
}

.pkselected_filtersec .pck_selected_ctry {
    
    font-size: 21px;
    padding: 0;
}

.pkg-detail-top-row .pkselected_package:first-of-type {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.guest_btn {
    text-align: center;
}

.pkglist_itemtable .pkglist_img img {
    max-height: 200px;
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
}

.controls.single-link {
    text-align: center;
}

.controls.single-link a {
    font-size: 15px;
    display: inline-block;
    color: #000;
    font-weight: 500;
    text-transform: uppercase;
}
.controls.single-link a:hover, .controls.two-links a:hover{ color: #825a46 }

.package_info_maindiv .package_info_title {
    color: #ffa700;
    text-align: center;
}

.package_info_maindiv .package_info_subtitle {
    color: #bb4d4d;
    text-align: center;
}

.package_info_maindiv .terms_conditions h4 {
    color: #bb4d4d;
    font-size: 16px;
    padding-top: 15px;
}

.back-to-listbtn a {
    width: 100% !important;
}

.package_info_maindiv .text-rgt {
    float: right;
}

.package_info_maindiv .terms_conditions li {
    list-style-type: none;
}

.package_info_maindiv .terms_conditions ul {
    padding-left: 30px;
}

.custom-addon-checkbox .pkgerror_txt {
    background: #FFBABA;
    color: #D8000C;
    padding: 5px 25px;
    font-size: 13px;
    position: relative;
    display: inline-block;
    font-weight: normal;
    z-index: 4;
    width: 100%;
}

.apply-red {
    color: red!important;
}

ul.venue_type_item {
    padding: 20px 0 0 0;
    margin: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    list-style: none;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

ul.venue_type_item li {
    margin: 5px 10px;
}

ul.venue_type_item li a {
    padding: 15px 25px;
    background: #98684a;
    color: #fff;
    border-radius: 5px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    display: block;
}

ul.venue_type_item li.venuetype-active a {
    background: #523628;
}

.venue_popup {
    max-width: 780px
}
.pdpa_trmcont_link {
    font-size: 14px;
}
.pdpa_trmcont_link span{
    display: inline-block
}

.delivery_outletpoup .self_popup_hea_row { background: #523628; }
.delivery_outletpoup .outlet-scooter-img { width: 86px; }

.outleterror { color: #ffa700; }
.delete-act-icon {
	position: absolute;
    right: 9px;
    top: 1px;
}
.act-remove-topdiv {
	position: relative;
	padding-right: 20px;
}

.venue_popup .close-icon-but {background: #98684a;
    color: #fff;
    font-size: 0;
    width: 30px;
    height: 30px;
    line-height: 30px;
    opacity: 1;
    top: -15px;
    right: -15px;
    padding: 0;
    margin: 0;}
.hide_section_cls, .back_click_mbl{display: none}
.back_click_mbl a:before {
    content: "\f104";
    font-family: FontAwesome;
    margin-right: 5px;
    font-size: 18px;
    font-weight: bold;
}
.custom_center_alertcls {
    position: fixed;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 0;
    right: 0;
    max-width: fit-content;
    margin: auto !important;
    padding: 12px 20px 12px 40px;
    font-size: 16px;
    border-radius: 10px;
    display: inline-block;
    text-align: center;
}
.custom_center_alertcls.alert-danger{     
    color: #ca322f;
    background-color: #fdd1d1;}
.mfp-custom-close {
    position: absolute;
}
img.view-img-span {
    width: 20px;
    position: relative;
    top: 3px;
    margin-left: 5px;
}
img.close-img-span {
    width: 19px;
    position: relative;
    top: 1px;
    margin-left: 5px;
    display: none;
}
.chk-payment .form-group.custom-tarms-div {
    padding: 0 20px 20px;
    text-align: left;
}
.text-lbl {
    display: block;
    font-weight:bold;
    text-transform: uppercase;
    margin: 0 0 5px 0;
}
.mk-catering-payment .chk-payment-col-radio li{ margin-right: 0 }
.price_nor dl {
    display: inline-block;
    font-size: 16px;
    vertical-align: text-top;
    
    text-transform: uppercase;
}


.package_info_desc a{ color: #000;}
.back-to-pkglist { 
	background: #ffa700;
    text-align: center;
    color: #fff;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 1;
    position: relative;
    border-radius: 5px;
    padding: 8px 15px;
    margin: 0;
    cursor: pointer;
    letter-spacing: 1px;
    display: inline-block;
	margin-bottom: 5px;
}

.back-list-also {
	-webkit-align-items: flex-start !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.timeslot_info {
    font-size: 13px;
    color: #f20000;
    margin-bottom: 15px;
}
.timeslot_info span {
    display: block;
}
.ordrdatetime_error .error { width: 100%; }
.syd_merge {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}
.syd_date {  width: 48%;}
.syd_time.delivery_submit_cls {  width: 48%;}
.syd_merge .form-group {margin: 0}

.checkout-control-group-middle .react-datepicker-wrapper {
    display: block;
}
.checkout-control-group-middle .react-datepicker__input-container {
    position: relative;
    display: block;
}
.datetime_selt_sec .react-datepicker-wrapper, .datetime_selt_sec .react-datepicker__input-container {
	display: block;
}
.membership_status {margin-top: -33px; position: relative;}
.membership_status span {
    position: absolute;
    left: 0;
    right: 0;
    top: 10px;
    text-transform: uppercase;
    font-size: 18px;
}

/*myaccount - 13 jan 2020*/
.membership-desc { background-color: #ffa700; border: 2px solid #fff; padding: 12px 5px 10px; font-size: 20px; text-transform: uppercase;  margin: 15px auto; max-width: 250px; }
.membership-desc img{ width: 20px; margin-right: 8px; }
.user-id{ margin: 10px 0; }
.user-id span {text-transform: uppercase; font-weight:bold; }
.membership-progress {background: #4f7873; margin: 15px -10px 0; padding: 20px 0 15px; }
.membership-progress p {font-weight:bold; margin: 9px auto 0; max-width: 170px; line-height: 1.2; }
.pg-bar{ display: block; position: relative; width: 100%; height: 15px; border-radius: 25px; background-color: #2a2521; max-width: 175px; margin: 0 auto;}
.pg-bar span{ position: absolute; height: 100%; width: 0%; background-color: #fff; top: 0; left: 0; border-radius: 25px; -webkit-box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.3); box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.3);}
.member-discount-desc { display: inline-block;background: #ffa700;border-radius: 10px;padding: 3px 10px;font-size: 12px;color: #fff;margin: 5px 0;line-height: 1;text-align: center; }
.member-discount-total {color: #98684a; font-weight:bold; font-size: 14px; }


.product-tag-list ul {
    list-style: none;
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    flex-wrap: wrap;
}
.product-tag-list ul li {   margin: 0 5px 5px;  font-weight: normal;  font-size: 13px; background: #dbd0ca; color: #000; line-height: 1; padding: 5px 12px 3px; border-radius: 20px;text-transform: uppercase;}
.product-tag-list ul li:empty {display: none;}
.product-tag-list ul li img{max-width: 16px; max-height: 16px;}

.products-image-div{ overflow: hidden;height: 210px;position: relative}
.products-image-div img{width: 100%;max-height: 210px;height: 100%;object-fit: cover}
.product-info-div{
    padding:15px 12px 5px 12px; display: flex; -webkit-box-flex: 1; flex: 1 1 auto; height: auto;
    -webkit-box-orient: vertical; -webkit-box-direction: normal; flex-direction: column;
}
.product-title-maindiv {position: relative; margin: 0;padding: 0;}
.product-title h3 {text-align: center;font-size: 18px;font-weight: bold;margin: 0 0 10px 0; }
.product-short-description {color: #353535; font-size: 13px; line-height: 1.3; flex: 1 1 auto;padding: 10px 0px 5px;}
.product-short-description p{margin-bottom: 0}
.product-price{margin: 5px 0px 15px 0px;text-align: center;color: #0A0C10;}
.product-price h3{margin: 0; font-size: 26px;font-weight: bold; line-height: 1}
.product-price h3 sup{ font-size: 70%;text-transform: uppercase;}
.product-price h3 .price_disc {color: #B6B6B6;font-size: 20px;display: block;text-decoration: line-through;padding-bottom: 5px;  font-family: 'Lato';}
.product-price h3 .price_disc sup {font-size: 12px;text-decoration: none;} 
.products-ordernow-action .button{ margin-bottom: 0; margin-top: 0;width: 100%;}
.product-info-div .products-ordernow-action {position: absolute;bottom: 15px;left: 15px;right: 15px;margin: auto;display: flex;justify-content: center;}
.product-info-div .products-ordernow-action .products-ordernow-inner {display: flex;flex-direction: column;width: 100%}
.products-ordernow-action{margin: 15px 0 0 0;}
.productlist-main-div .products-list-ulmain > li.products-single-li .products-ordernow-action {    
    position: absolute;
    bottom:10px;
    right: 10px;
    left: 10px;
    margin: auto;
}
.featured-products-main .owl-stage {
    display: flex;
    padding: 20px 0;
}
.featured-products-main .owl-dot span{display: block; width: 12px; height: 12px;background: #000;border-radius: 50%;}
.featured-products-main .owl-dot{margin: 0 4px; display: inline-block;}
.featured-products-main .owl-dots{    text-align: center; margin: 10px 0 10px 0; display: block; width: 100%;}
.featured-products-main .owl-dot.active span, .featured-products-main .owl-dot:hover span{background:#fff;}

.featured-products-section .product-short-description p {    width: 100%; max-width: 100%; margin: 0;  color: #949292;position: relative;height: 60px;overflow: hidden;    font-size: 14px;margin-bottom: 0px;padding: 3px 0 0; text-align: center;}
.featured-products-section .product-short-description p:empty {display: none;}
.featured-products-section .product-short-description p:empty + .product-short-description {display: none;}
.products-single-li{position: relative; z-index: 2; overflow: hidden;background: #fff;padding-bottom: 150px; box-shadow: 0 3px 17px 0 rgba(0,0,0, 0.11);}
.featured-products-section p { font-size: 15px;margin-bottom:0px;}
.products-ordernow-action .button.disabled, .products-ordernow-action .button.disabled:hover{ opacity: .4; cursor: not-allowed; -webkit-box-shadow: none; pointer-events: none; }

.products-single-li:hover .button{color: #fff; background: #523628; border-color: #523628;}
.slick-slider .slick-dots li button{min-width: inherit;}


.hmenu-login-section ul {
    padding: 0;
    list-style: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
li.hsign_sec {
    margin-right: 20px;
}
.doorstep-block-section{position: relative}

.guest-checkout-popupdiv {
    background: #ededed;
    margin: 0 -55px;
    padding: 25px 55px;
}
.guest-checkout-popupdiv .button {
    margin: 0;
    background: #464646;
    display: block;
    width: 100%;
    padding: 17px 5px;
    
}
.signup-gender-selct {
    margin-bottom: 0;
}
span.birthday_info_sp {
    font-size: 12px;
    color: #737373;
}

span.birthday_info_sp b {
    font-weight: 500;
    font-style: italic;
}
.product-menu-listing{padding:0 0 30px 0; position: relative; z-index: 2}
.bo-container{position: relative;z-index: 1; padding: 25px 0 0px 0;}
.innersection_wrap.myadmin_wrap{ padding: 0 0 10px 0;position: relative;  }
.prodetailinner-main-div{  padding: 15px 0 20px 0; position: relative; z-index: 1 }
.product-menu-listing .container:before, .product-menu-listing .container:after{ display: none;}

.mobile-catgegory-nav{display: none}
.product-lhs{  width: 100%;  }
.favourite-main-list .product-lhs{width: 100%}
.product-menu-left { width: 100%; position: sticky; top: 85px; z-index: 9;margin-bottom: 20px;background: #fff}
.productlist-main-div .products-list-ulmain{ display: -webkit-box; display: -ms-flexbox; display: flex; -ms-flex-wrap: wrap; flex-wrap: wrap;padding: 0 0 40px 0;list-style: none}
.productlist-main-div .products-list-ulmain > li .product_innr {width: 100%;height: 100%;}
.productlist-main-div .products-list-ulmain > li {text-align: center; width:23.828125%;margin: 0 1.5625% 20px 0;background: #fff;-webkit-box-shadow: 0px 1px 21px 0px rgba(1, 1, 1, 0.11);box-shadow: 0px 1px 21px 0px rgba(1, 1, 1, 0.11);transition: 0.4s linear all; color: #000}
.productlist-main-div .products-list-ulmain > li:nth-child(4n){ margin-right: 0;}
.productlist-main-div .products-list-ulmain > li.products-single-li{position: relative; padding-bottom: 160px;height: auto;overflow: visible;}
.productlist-main-div .products-list-ulmain > li.productDetailMain{margin: 0 0 20px 0;}

.products-list-ulmain .slick-slider .slick-list .slick-track .slick-slide {position: relative;overflow: hidden;}
.products-list-ulmain .slick-slider .slick-list .slick-track .slick-slide > div {width: 100%;padding: 0 8px;text-align: center;}
.products-list-ulmain .slick-slider .product-title h3 {text-align: center;font-size: 18px;font-weight: 300;margin: 5px 0;}
.products-list-ulmain .slick-slider .slick-dots, .promo-banner .slick-slider .slick-dots, .welcome-rhs .slick-slider .slick-dots, .hg-top .slick-slider .slick-dots{ padding: 0; text-align: center;}
.products-list-ulmain .slick-slider .slick-dots li, .promo-banner .slick-slider .slick-dots li, .welcome-rhs .slick-slider .slick-dots li, .hg-top .slick-slider .slick-dots li {
    list-style: none;
    margin: 0 3px;
    display: inline-flex;
}
.products-list-ulmain .slick-slider li button, .promo-banner .slick-slider .slick-dots li button, .welcome-rhs .slick-slider .slick-dots li button, .hg-top .slick-slider .slick-dots li button {
    padding: 0;
    font-size: 0;
    width: 12px;
    height: 12px;
    overflow: hidden;
    border-radius: 50%;
    min-width: inherit;
    margin: 0; border: 0; background: #e9e6e6;
}
.products-list-ulmain .slick-slider .slick-dots li.slick-active button, .promo-banner .slick-slider .slick-dots li.slick-active button, .welcome-rhs .slick-slider .slick-dots li.slick-active button, .hg-top .slick-slider .slick-dots li.slick-active button { background: #080808;}
.products-list-ulmain .slick-slider .slick-list .slick-track .slick-slide { position: relative;  margin: 30px 0 0 0; padding: 0 0 20px 0;}
.menu-title-link{  padding: 18px 17px 15px; position: relative; cursor: pointer; color: #000; font-size:15px; font-family: 'Lato'; text-transform: uppercase;  display:flex; line-height: 1;  justify-content: center; align-items: center; height: 100%; text-align: center; font-weight: 300; }
.menu-list-intvl.active .menu-title-link{ font-weight: 900; }  
.menu-section-left-inner li{position: relative; z-index: 2}
.menu-section-left-inner > ul {
    display: flex;
    padding: 0;
    margin: 0;
    list-style: none;
    justify-content: center;
    width: 100%;
    border-bottom: 1px solid #e9e8e8;
}
.menu-section-left {
    margin: 0 0 45px 0;
}
.menu-section-left-inner > ul ul{  background: #363636;padding:5px 0;  list-style: none; width: 190px;display: none; position: absolute; top: 100%; left: 0;box-shadow: 0px 7px 5px 0px rgba(0, 0, 0, 0.18);}
.menu-section-left-inner > ul > li:hover ul{ display: block; }
.menu-section-left-inner > ul ul li a{ display: block; color: #fff; padding: 10px 15px; font-weight: 300 }
.menu-section-left-inner > ul ul li a:hover{ color: #dbd0ca }
.menu-section-left-inner > ul ul li.active a{ font-weight: bold}

.search-product {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; 
    width: 100%;    padding-bottom: 10px;
    padding-top: 30px;
}
.search-product > .container{ display: flex; flex-wrap: wrap;justify-content: space-between; }
.search-product > .container:before, .search-product > .container:after, .menu-top-section-inner .container:before, .menu-top-section-inner .container:after{ display: none}

.spm-lhs{position: relative;}
.spm-lhs .search-rest{padding-right: 45px; border: 0; box-shadow: 0px 2px 13px 0px rgba(1, 1, 1, 0.07); height: 52px;padding-left: 18px;    font-size: 17px;  margin-bottom: 0}
.spm-lhs img{
    position: absolute;
    right: 15px;
    top: 15px;
    width: 21px;
}
.spm-lhs{width: 380px;}
.spm-rhs{width: 162px;}
.spm-rhs .filter-area{ padding-right: 45px;
    border: 0;
    box-shadow: 0px 2px 13px 0px rgba(1, 1, 1, 0.07);
    height: 52px;
    padding-left: 18px;
    font-size: 16px;
    padding: 14px 40px 12px 18px;
    position: relative;width: 100%;background: #fff; cursor: pointer; cursor: pointer;}

.spm-rhs {
    width: 162px; margin-left: 10px;}

.filter-area img{
    position: absolute;
    right: 15px;
    top: 18px;
    width: 17px;
}
.filter-area img.filter-clear-image{
    width: 12px;
    top: 20px;
}
.filter-details {
    position: absolute;
    z-index: 9;
    width: 100%;
    left: 0;
    top:105%;
    display: none;
    max-height: 600px;
    overflow-y: auto;
    box-shadow: 0 4px 12px 0 rgba(0,0,0,0.10);
    background: #fff;
    padding: 20px;
}
.filter-details.active {display: block}
.common-filter{padding-bottom: 20px;}
.common-filter:last-child{padding-bottom: 0}
.common-filter ul{padding: 0; list-style: none;    display: flex;  flex-wrap: wrap;}
.common-filter ul li{ width: 23.828125%; margin:0px 1.5625% 15px 0; cursor: pointer;}
.common-filter ul li:nth-child(4n){margin-right: 0}
.common-filter h3{ font-size: 17px;margin: 0 0 15px 0;}
.common-filter.item-offer ul li{
    display: block;
    font-weight: 700;
    border:2px solid #d3334b;
    border-radius: 5px;
    padding: 10px 5px;
    line-height: 1;
    text-align: center;
}
.common-filter ul .custom_checkbox span{ padding-left: 25px }
.common-filter ul .custom_checkbox span img{    vertical-align: text-bottom;margin-right: 3px}

.clear-filter {
    position: absolute;
    right: 20px;
    top: 25px;
    line-height: 1;
    font-size: 12px;
}

.backto-rest{box-shadow: 0px 3px 28px 0px rgba(1, 1, 1, 0.10); background: #fff;
    margin-right: 6px; text-align: center }
.backto-rest a{  display: block;  padding: 10px;display: flex;
    align-items: center;
    justify-content: center;height: 100%; width: 100%; background: #fff; transition: 0.3s linear all;}
.backto-rest a img{width: 28px;}
.backto-rest a:hover{  background: #fdb330; }
.search-product-main{ display: flex; flex-wrap: wrap;position: relative;}
.css-10nd86i{z-index: 4;}

.product-lo-top a {
    display: inline-block;
    padding: 13px 20px;
        
    font-size: 16px;
    color: #b4b3b3;
    line-height: 1.3;
    text-transform: uppercase;
    background: #e7e6e6;
    min-height: 46px;
    position: relative;
    width: calc(50% - 11px);
    text-align: center;
}
.product-lo-top a:first-child {
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    float: left;
    padding-right: 1px;
}
.product-lo-top a:last-child {
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    float: right;
    padding-left: 1px;
}

.product-lo-top a:first-child:after, .product-lo-top a:last-child:before {
    content:'';
    position: absolute;
    top: 0;
    width: 0;
    height: 0;
}
.product-lo-top a:first-child:after {
    left: 100%;
    border-top: 46px solid #e7e6e6;
    border-right: 23px solid transparent;
}
.product-lo-top a:last-child:before {
    right: 100%;
    border-bottom: 46px solid #e7e6e6;
    border-left: 23px solid transparent;
}

.product-lo-top a.active{
    color: #fff;
    background: #fbaa1a;
}
.product-lo-top a.active:last-child:before{ border-bottom-color: #fbaa1a; }
.product-lo-top a.active:first-child:after{    border-top-color: #fbaa1a;}
.product-lo-top { min-width: 330px;}

.progress_bar_div{ margin-top: 10px}
.common-inner-banner{  position: relative; padding: 20px 20px 20px;  display: -webkit-box; display: flex;   align-items: center; -ms-flex-flow: column wrap; flex-flow: column wrap; color: #fff; font-family: 'georgiaregular';  z-index: 1; text-align: center; font-family: 'Athelas'; height: 280px; justify-content: center;margin: 0 0 40px 0} 
.common-inner-banner img {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; z-index: -1; 
}
.common-inner-banner p{margin: 0;  text-transform:capitalize;font-size:50px; line-height: 1;text-shadow: 1px 1px 13px rgba(0,0,0,0.65)}
.menu-section-inner{max-height: 500px; overflow: auto;}
.fxd_lftmenu_div{position: fixed; top: 160px; width: 300px;}
.bottom_abs {  position: absolute; top: auto; bottom: 0; width: 300px;}

.prodet_top{text-align: center; margin-bottom: 30px;}
.prodet_top img{margin: 0 auto}
.prodetailinner-main-div .modal-body{ padding: 0; }
.detail-pg-slider .slick-track .slick-slide > div {
    margin: 30px 7px;
    -webkit-box-shadow: 0px 3px 20px 0px rgba(1, 1, 1, 0.14);
    box-shadow: 0px 3px 20px 0px rgba(1, 1, 1, 0.14);
    width: 100%;
    text-align: center;
        overflow: hidden;
}
.detail-pg-slider .product-title h3{font-size: 21px; margin: 0;}
.addonpro-slider-top{ margin: 70px 0 0px 0}
.chk-addonpro-title h3{font-size: 24px;text-align: center; font-weight: bold; margin-bottom: 0px}
.detail-pg-slider .product-tag-list{bottom: -8px;}
.chk-payment-title{padding-bottom: 30px}
.chk-payment-title h3{ font-size: 20px;text-align: center; font-weight: normal; font-family: 'Athelas'; color: #523628; margin: 0 0 10px 0; text-transform: uppercase; }
.chk-payment-title p{margin: 0; font-weight: 900;text-align: center; font-size: 17px; }

.birthday-info-div{padding: 40px 5px 30px; text-align: center;}
.birthday-info-top h3{ 	font-size: 36px;text-transform: uppercase; margin-bottom: 0; line-height: 1; display: flex; justify-content: center; align-items: center; font-weight: bold}
.birthday-info-top h3 img {margin: 0px 0 0 15px; position: relative; top: 2px;}
.birthday-info-top h3 span{ font-size: 26px;text-transform: capitalize; font-weight: normal}
.birthday-info-middle{  padding: 10px 0 0 0;
    font-size: 17px;
    color: #000;
    text-align: center;
    font-weight: 600;}
.left-input-div, .right-input-div {width: 49%;z-index: 10;}
.birthday-inpt-act {display: flex; justify-content: space-between; -ms-flex-wrap: wrap;flex-wrap: wrap; margin: 0 auto;}
.narml_select { position: relative;}
.narml_select:after {width: 30px; height: 46px; content: ""; position: absolute; right: 1px; background: #fff; top: 1px;}
.normal-popup { max-width: 680px; text-align: center;}
.normal_popup_cont ul{padding: 0; list-style: none}
.normal-popup button.mfp-close{background:#ffa700;opacity: 1; color:#fff; border-radius: 2px; width: 30px; height: 30px; line-height: 1;}
.promopopup-maindiv {display: flex; -ms-flex-wrap: wrap; flex-wrap: wrap;}
#promo-check-popup { max-width: 810px; background: none;}
.promopopup-rhs {width: 53.0864%; border: 1px solid #ffa700;}
.promopopup-lhs {
  width: 46.9135%;  border-top: 30px solid rgba(0,0,0,0);  border-bottom: 30px solid rgba(0,0,0,0);
  display: flex; -webkit-box-align: center; -ms-flex-align: center; align-items: center;
}
.frm-action-div{   background: #ffa700; color: #fff;  padding:40px 30px; }
.promopopup-rhs img{ width: 100%; height: 100%; -o-object-fit: cover; object-fit: cover; }
.promopopup-lhs .frm-top-title h3{ color: #fff; margin-bottom:0px; }
.promopopup-lhs .frm-inputbtn-div { position: relative;}
.promopopup-lhs .frm-inputbtn-div .button {
	position: absolute;
	right: 0;
	top: 0;
	margin: 0;
	height: 48px;
	background:
	#000;
	font-size: 15px;
	padding: 10px 14px 10px;
	
	font-weight: bold;
	border: 1px solid #fff;    
}
.promopopup-lhs .form-group input{border-color: #fff;padding-right: 110px;}
.promopopup-lhs .frm-bottom-text{ text-transform: uppercase; font-size: 14px; font-weight: bold;margin-bottom: 0}
.promopopup-lhs .frm-bottom-text p{margin: 0}
.promopopup-lhs .form-group{margin-bottom: 5px;}
#promomail-error { color:  #b70000; line-height: 1; font-size: 13px}
.promo-check-popup button.mfp-close{background:#ffa700;
	opacity: 1;
	color:#fff;
	border-radius: 6px;
	width: 30px;
	height: 30px;
	line-height: 1;
	top: -15px;
	right: -15px;
}
.prodetailinner-main-div button.close {
    width: 24px;
    height: 24px;
    background: #000;
    color: #fff;
    box-shadow: none;
    top: 5px;
    right: 5px;
    text-indent: 9999px;
    position: absolute;
	border-radius: 5px;min-width: inherit;
}
.prodetailinner-main-div button.close:after{ 
	background: url(../images/plus-white.svg) no-repeat;
	width: 16px;
	height: 16px;
	content: "";
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	margin: auto;
	-webkit-transform: rotate(45deg);   
	transform: rotate(45deg);    
}
#ProductDetailMdl .modal-content{ border-radius: 0 }


.mbl-hiden{overflow-y: hidden !important}
.htico_search_mbl{display: none}

.mobile-menu{ 
    z-index: 1;
    position: fixed;
    top: -100%;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translate3d(0, 0, 0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    overflow: hidden; overflow-y: auto;}
.mobile-menu.active{top: 0}
.mobile-menu:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  background: rgba(0,0,0,0.98);
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  border-radius: 100%;
  transform: scale(0.04), translateY(9999px);
  overflow: hidden;
}

.mobile-menu.active:before {
  -webkit-animation: menu-animation 0.8s ease-out forwards;
          animation: menu-animation 0.8s ease-out forwards;
}

.mobile-menu ul.menu { position: fixed; top: 50%;left: 50%;transform: translate3d(-50%, -50%, 0);-webkit-backface-visibility: hidden;  backface-visibility: hidden;perspective: 1000;color: #fff; padding: 0; list-style: none;width: 80%}
.mobile-menu ul.menu li, .mobile-menu-close { opacity: 0; text-align: center;transform: translate3d(0, 36px, 0);}
.mobile-menu ul.menu li a span:before {
    content: "";
    position: absolute;
    top: 0px;
    left: -100%;
    width: 100%;
    height: 100%;
    background: #fff;
    z-index: 99;
    -webkit-transition: all 0.5s cubic-bezier(0.7, 0, 0.3, 1);
    transition: all 0.5s cubic-bezier(0.7, 0, 0.3, 1);

}
.mobile-menu ul.menu li a span:after {
    content: "";
    position: absolute;
    top: 50%;
    margin-top: -1px;
    left: 100%;
    width: 100%;
    height: 2px;
    background: #fff;
    -webkit-transition: all .3s;
    transition: all .3s;
    -webkit-transition-delay: .4s;
    transition-delay: .4s;
}
.mobile-menu ul.menu li a:hover span:before {
    left: 100%;
}
.mobile-menu ul.menu li a:hover span:after {
    left: 0%;
}
.mobile-menu ul.menu li a span {
    overflow: hidden;
    position: relative;
    display: inline-flex;
}
.mobile-menu.active ul.menu li, .mobile-menu.active .mobile-menu-close {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition: transform 0.2s ease-out, opacity 0.2s ease-out;
}
.mobile-menu.active ul.menu li:nth-child(1), .mobile-menu.active .mobile-menu-close{
  transition-delay: 0.75s;
}
.mobile-menu.active ul.menu li:nth-child(2) {
  transition-delay: 0.85s;
}
.mobile-menu.active ul.menu li:nth-child(3) {
  transition-delay: 0.95s;
}
.mobile-menu.active ul.menu li:nth-child(4) {
  transition-delay: 1.05s;
}
.mobile-menu.active ul.menu li:nth-child(5) {
  transition-delay: 1.15s;
}
.mobile-menu.active ul.menu li:nth-child(6) {
  transition-delay: 1.25s;
}
.mobile-menu.active ul.menu li:nth-child(7) {
  transition-delay: 1.35s;
}
.mobile-menu.active ul.menu li:nth-child(8) {
  transition-delay: 1.45s;
}
.mobile-menu.active ul.menu li:nth-child(9) {
  transition-delay: 1.55s;
}
.mobile-menu.active ul.menu li:nth-child(10) {
  transition-delay: 2.05s;
}


@-webkit-keyframes menu-animation {
  0% {
    opacity: 0;
    transform: scale(0.04) translateY(300%);
  }
  40% {
    transform: scale(0.04) translateY(0);
    transition: ease-out;
  }
  40% {
    transform: scale(0.04) translateY(0);
  }
  60% {
    opacity: 1;
    transform: scale(0.02) translateY(0px);
  }
  61% {
    transform: scale(0.04);
  }
  99.9% {
    height: 0;
    padding-bottom: 100%;
    border-radius: 100%;
  }
  100% {
    transform: scale(2);
    height: 100%;
    padding-bottom: 0;
    border-radius: 0;
  }
}

@keyframes menu-animation {
  0% {
    opacity: 0;
    transform: scale(0.04) translateY(300%);
  }
  40% {
    transform: scale(0.04) translateY(0);
    transition: ease-out;
  }
  40% {
    transform: scale(0.04) translateY(0);
  }
  60% {
    opacity: 1;
    transform: scale(0.02) translateY(0px);
  }
  61% {
    transform: scale(0.04);
  }
  99.9% {
    height: 0;
    padding-bottom: 100%;
    border-radius: 100%;
  }
  100% {
    transform: scale(2);
    height: 100%;
    padding-bottom: 0;
    border-radius: 0;
  }
}

.mobile-menu .toggle-submenu-button {
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;
    display: block;
    height: 60px;
    cursor: pointer
}
.mobile-menu .toggle-submenu-button:before {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg)
}
.mobile-menu .toggle-submenu-button:after,
.mobile-menu .toggle-submenu-button:before {
    background: #000;
    display: block;
    height: 2px;
    margin-top: -2px;
    pointer-events: none;
    position: absolute;
    right: 10px;
    top: 50%;
    transition: -webkit-transform .25s ease-in-out 0s;
    transition: transform .25s ease-in-out 0s;
    transition: transform .25s ease-in-out 0s, -webkit-transform .25s ease-in-out 0s;
    -webkit-transition: -webkit-transform .25s ease-in-out 0s;
    width: 14px;
    z-index: 1;
    content: ""
}
.mobile-menu .toggle-submenu-button:after {
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg)
}
.mobile-menu .toggle-submenu-button.open-submenu:before {
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg)
}
.mobile-menu .toggle-submenu-button.open-submenu:after {
    transform: rotate(1turn);
    -webkit-transform: rotate(1turn)
}

.mobile-menu .mobile-menu-close {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 40px;
    height: 40px;
    background: #c90d2c;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.mobile-menu .mobile-menu-close span {
    cursor: pointer;
    display: block;
    width: 21px;
    height: 21px;
    position: relative
}

.mobile-menu .mobile-menu-close span:before {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg)
}

.mobile-menu .mobile-menu-close span:after,
.mobile-menu .mobile-menu-close span:before {
    background: #fff;
    display: block;
    height: 2px;
    margin-top: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 50%;
    transition: -webkit-transform .25s ease-in-out 0s;
    transition: transform .25s ease-in-out 0s;
    transition: transform .25s ease-in-out 0s, -webkit-transform .25s ease-in-out 0s;
    -webkit-transition: -webkit-transform .25s ease-in-out 0s;
    width: 24px;
    z-index: 2;
    content: ""
}

.mobile-menu .mobile-menu-close span:after {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg)
}

.mobile-menu .menu a {
    display: block;
    font-size: 16px;
    color: #fff ;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    font-weight: bold;
    padding: 12px 0;
    line-height: 1;
    text-transform: uppercase;
}
.mobile-menu .menu a:hover, .mobile-menu .menu li.active > a, .mobile-menu .menu li > a.active {
    color: #523628;
}
.mobile-menu .menu .sub-menu {
    display: none; padding: 0
}
.mobile-menu .menu .sub-menu li {
    padding-right: 0
}
.mobile-menu .menu .sub-menu li:last-child {
    border-bottom: 0
}
.mobile-menu .menu .sub-menu a {
    
}
.mobile-menu .menu li {
    
    padding:0 30px
}

@-webkit-keyframes a {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-80px);
        transform: translateX(-80px)
    }

    to {
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
}

@keyframes a {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-80px);
        transform: translateX(-80px)
    }

    to {
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
}
.pkselect_tabsec .tab-content {
    background: #f9f7f8;
    padding: 0px 25px 20px;
}
.rest-list-cls { 
	margin: 20px 0;
    font-weight: 600;
    display: inline-block;
    border-bottom: 2px solid #000;
}
.li-full-width {
	width: 100% !important;;
}

#delivery-continue-link, #takeaway-continue-link {
	display : none;
}


/* omise */
.omise-form-error input {
    -webkit-box-shadow: inset 0 1px 2px transparent, 0 1px 0 hsla(0,0%,100%,0), 0 0 2px 0 rgba(255,0,0,.5);
    box-shadow: inset 0 1px 2px transparent, 0 1px 0 hsla(0,0%,100%,0), 0 0 2px 0 rgba(255,0,0,.5);
    border-color: #ff7076;
    border-top-color: #ff5c61;
}

.pay-omiseconf-popup { max-width: 340px; border-radius: 10px;background: #f5f5f7;margin-top: 60px;}
span.omise-pay-img {
    display: block;
    width: 78px;
    height: 78px;
    border-radius: 50%;
    background: #fff;
    overflow: hidden;
    margin: 0 auto;
    border: 4px solid #fff;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.45);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.45);
    position: absolute;
    top: -39px;
    left: 0;
    right: 0;
}

img.makisan-logo-img {
    width: 100%;
    height: 100%;
    -o-object-fit: none;
    object-fit: none;
}

.omise_pay_header {
    position: relative;
    padding: 50px 30px 25px;
    text-align: center;
    border-bottom: 1px solid #d2d2d3
}
.omise_pay_body {
    padding: 30px;
    position: relative;
        border-top: 2px solid #fff;
}
.omisepop_in .form-control{ padding: 10px; height: 40px; font-size: 14px;border-radius: 4px; padding-left: 36px; letter-spacing: 0.5px}
.expire_row_inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.expire_row_inner > div {
    width: 32%;
    position: relative;
}
.omisepop_in .alt_btns .button {margin-bottom: 0;
    width: 100%;
    padding: 13px 10px 11px;
    border-radius: 4px;}
.omisepop_in .form_sec i{ position: absolute; left: 10px; color: #888;top:12px;  }
.omisepop_in .form_sec > div{ position: relative }
.omisepop_in .form_sec .uname-box  i{ left: 13px;}
.omisepop_in .form_sec .card-numbr-div i, .omisepop_in .form_sec .expire_left i {font-size: 16px;top:13px;}
.omisepop_in .form_sec .expire_right  i{ font-size: 19px; top: 12px;}
.expire_mdl .form-control{ padding-left: 15px }
.card {
    position: absolute;
    display: block;
    right: 10px;
    width: 28px;
    height: 19px;
    background: url(../images/cards/spritecard.png) no-repeat 0;
    top: 10px;
    -webkit-transition: 0.3s linear all;
    transition: 0.3s linear all;
    background-position: 0 0;
    overflow: hidden;
}
.visa + .card {
    background-position: 0 -19px;
}
.master+ .card {
    background-position: 0 -38px;
}
.discover + .card {
    background-position: 0 -57px;
}
.american + .card {
    background-position: 0 -76px;
}
.pay-omiseconf-popup button.mfp-close {
    padding: 0;
    width: 21px;
    height: 21px;
    display: block;
    color: #fff !important;
    border-radius: 50%;
    line-height: 21px;
    background: #a5a5a5;
    border: 1px solid #929292;
    top: 10px;
    font-size: 20px;
    right: 10px;
    text-align: center; min-width: inherit;
}
.pay-omiseconf-popup button.mfp-close:hover{ background: #000; border-color: #000; }
.omisepay-mode-option {
    position: fixed;
    top: 20px;
    right: 20px;
    background: #ffd025;
    line-height: 1;
    padding:9px 11px 8px;
    font-size: 12px;
    border-radius: 4px;
    font-weight: 600;
    text-transform: uppercase;
}
.omise-error-info-div {
	color: #ffffff;
    background-color: #b12f2f;
    border-color: #ebccd1;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    max-width: 100%;
    margin: 0 auto;
    padding: 10px;
    line-height: 1;
}
.omise-error-icon { font-size: 14px; }
.omise_alert_close {
	position: absolute;
    right: 0px;
    color: #FFF;
    top: 0;
}
.omise-error-msg {
	margin: 0 0 0 11px;
    font-size: 14px;
}
.omise-error-info-div .container {
	display: flex;
    flex-wrap: nowrap;
    align-items: center;
    position: relative;
}
.displayDivCls { display: none; }

/*---Product Details---*/
.prodet_bansec {width: 48%;}
.inn_product_row {width: 52%; padding-left: 40px;}
.inn_product_row .product-tag-list ul {justify-content: left;}
.inn_product_hea > p.total_price {
    font-size: 35px; margin: 10px 0px;  color:#006E5F;
}
.inn_product_hea > p.total_price sup {font-size: 80%; position: relative; top: -5px; margin: 0 2px 0 0;}
.inn_product_hea h4 {font-size: 15px; text-transform: uppercase; margin: 10px 0px; font-weight: 500}
.compo_acc_innerdiv .combo-inc-parent.chosen_adn_right {
    margin-top: 5px; margin-bottom: 5px;
}
.compo_acc_innerdiv .chosen_adn {margin: 0 0 5px 0;}
.prd_chosen_sub_row > p.sel-quality {
    font-size: 15px;color: #535353; max-width: 260px; margin: 10px auto 0;
    padding-left: 26px; text-align: center;  font-weight: 400;height: auto;
}
.common-modal-head {position: relative;}
.prodet_bansec .slick-slide > div {width:100%;}
.prodet_bansec .prodet_top .slick-slide img {display: block; height: 580px; width:100%; object-fit: cover; object-position: center; border: 2px solid #bca9a0;}
/*.add-extra-paired-products-mobile { width: 100%; display: none;}*/

.add-extra-paired-products {
    background: #f2f1f1;
    padding: 40px 30px;
    border-radius: 15px;
    text-align: center;
    margin-top: 35px;
}
.add-extra-paired-products .slick-dots {
    display: flex !important;
    margin: 20px 0 0 0;
    padding: 0;
    background: #ffffff;
    height: 3px;
    width: 100%;
    list-style: none;
    position: relative;
    justify-content: stretch;
}
.add-extra-paired-products .slick-dots li {
    width: 50%;
}
.add-extra-paired-products .slick-dots li button {
    padding: 0;
    font-size: 0;
    margin: 0;
    background: #ffffff;
    height: 3px;
    width: 100%;
    display: block; border: 0
}
.add-extra-paired-products .slick-dots li.slick-active button{ background: #000; }
.add-extra-paired-products h2{ font-size: 20px; font-weight: 600; text-transform: capitalize; }
.add-extra-paired-products .slick-slide > div > div {
    background: #fff;    height: 100%;
}

.add-extra-paired-products .slick-slide > div {
    padding: 0 5px;width: 100%;
}
.add-extra-paired-products .slick-slide > div button {font-size: 15px; padding: 12px 10px 12px; margin: 0 auto; min-width: inherit; width: 100%;}
.add-extra-paired-products .slick-slide .paired-products-price-main{
    position: absolute;
    bottom: 10px;
    left: 10px;
    right: 10px;    
}
.paired-products-image img {
    max-height: 140px;
    width: auto !important;
    margin: 0 auto;
}

.paired-products-image {
    height: 140px;
}
.paired-products-desc {
    padding: 10px 5px 90px 5px;
    height: calc( 100% - 140px );
    position: relative;
}
.paired-products-desc h4{ font-size: 15px; font-weight: 500; text-transform: capitalize; margin: 0 0 5px  }
.pare-mobile-one{display: none}
.prodet_baninfo .product-price {
    font-size: 20px;
    color: #000;
    font-weight: 500;
}
.add-extra-paired-products .slick-slide .paired-products-price-main .paired-products-price{ margin-bottom: 5px; }
.product-go-back{ margin-bottom: 10px;}
.product-go-back a{ font-weight: 500; text-transform: uppercase; font-size: 14px; position: relative; padding-left: 34px;color: #fff;}
.product-go-back a:before { content: ""; position: absolute; top:4px; left: 0; background: url(../images/right-arrow.png) no-repeat; width: 29px; height: 14px;}
.product-go-back a:hover{ opacity: 0.6 }

/*Cart list*/
.inner-cart {position: absolute; right: 40px; top: 23px;display: none}
.inner-cart > a {
    position: relative; z-index: 12; background: #fff; padding: 7px; border-radius: 100%; width: 45px;
    height: 45px; box-shadow: 0px 2px 6px 0px rgba(0,0,0,.3); margin-top: -10px; margin-right: -20px;
	display: block;
}
.inner-cart > a .hcart_round {background: #006E5F; right: -4px;}
.inner-cart > a > i {
    background: url(../images/cartbag.png) no-repeat center; width: 21px; height: 29px; display: block;   
	background-size: contain; width: 20px; height: 26px; margin: 2px auto;
}
.hcart_scrollarea{height:calc(100% - 100px); overflow-y: auto;}
.hcart_dropdown {
    background: #fff; width: 680px; position: fixed; height: 100vh; right: 0; top: 0; z-index: 11;
	-webkit-transform: translateX(100%); transform: translateX(100%); -webkit-transition: -webkit-transform .3s ease-in-out; 
	transition: -webkit-transform .3s ease-in-out; transition: transform .3s ease-in-out; 
	transition: transform .3s ease-in-out,-webkit-transform .3s ease-in-out;    }
.hcart_dropdown.open {-webkit-transform: translateX(0); transform: translateX(0);}
.pickup-cart-div:after {clear: both; content: ''; display: table;}
.cart-header-first .row-replace, .cart-header-second .row-replace, .hcart_tt .row-replace, .product-details .row-replace {
    display: flex; flex-wrap: wrap;
}
.hcart_tt {    background: #79655c; padding: 12px 10px 12px 10px;}
.cart_body .hcart_tt {background: #f1f1f1; padding: 8px 10px 8px 10px; color: #000; }
.cart_body .hcart_tt .row-replace {justify-content: space-between; align-items: center;}
.hcart_tt .cart-hdr-top {width: 100%;}
.hcart_tt h3 {margin: 0px 0px 0px 0px; line-height: 1; font-size: 18px;color: #fff;font-family: 'Athelas';text-transform: uppercase}
.cart_body .hcart_tt h3 { font-size: 17px; margin: 0; font-family: 'Lato';text-transform: uppercase; font-weight: bold;color: #000}
.cart_body .hclear_cart {font-size: 15px; color: #000;  text-decoration: underline;font-family: 'Lato';text-transform: uppercase; font-weight: bold;}
.product-details .row-replace .cart_left { width: calc(100% - 140px); width: -webkit-calc(100% - 140px); padding: 0 5px 0 0;}
.product-details .row-replace .cart_right {width: 140px; padding: 0 0 0 5px;}
.cart_img { width: 100px; display: inline-block; margin-right: 10px; vertical-align: top; overflow: hidden; padding: 2px;}
.cart_info { width: calc(100% - 115px); width: -webkit-calc(100% - 115px); display: inline-block; vertical-align: top; color: #3d3d3d;}
.thank-order-detaildiv .cart_info { width: 100%  }
.product-details .cart_info h4 { font-size: 17px; font-weight: bold }
.product-details .cart_info span.voucher-discount-applied {
    position: relative;
    color: #006e5f;
    font-size: 14px;
    font-weight: 600;
    padding-left: 10px;
}
.product-details .cart_info span.voucher-discount-applied::before {
    content: "\f058";
    font-family: "FontAwesome";
    position: relative;
    left: -3px;
}

.cart_footer {padding: 20px 15px; background: #f7f7f7; padding-left: 20px; padding-right: 20px;}
.hcart_dropdown .cart_action {padding: 0; z-index: 99; background: #fff; position: fixed; bottom: 0; left: 0; right: 0;}


/*My Account*/
.myacc_order_details .delivery_total.delivery_total_number .delivery_total_left h4.reward-check {text-align: left;}

.expiry_on_lhs {
    display: flex;
    align-items: flex-start;
    justify-content: space-between; margin-bottom: 10px;
}
.myacc_order_details .delivery_total.delivery_total_number{padding: 25px 0px 5px;}
.myacc_order_details .head_left h4, .myacc_order_details .head_right h4 {font-size: 16px;font-weight: 600;}
.tnk-delivery .cart_price {padding-right: 0px;line-height: 1}
.chekout_cart_bar .cart_remove img{display: none;}
.contact_wrap {padding: 0px 0px 80px;}

.react-tabs__tab-list {
    border-bottom: 0px solid transparent !important;
    margin: 0 0 30px !important; 
    padding: 0 !important;
    width: 100%;
    display: flex;
    justify-content: center;
}
.voucher-popup-inner h2 {  font-size: 21px; font-weight: 500;}
.react-tabs__tab {
    width: 50%;
    text-align: center;
    margin-bottom: 0;
    text-transform: capitalize;
    border: 0px solid transparent !important;
    border-bottom: 5px solid #e4e4e4 !important;
    cursor: pointer;
    padding: 10px 20px !important;
    display: inline-block !important;
    color: #181818 !important;
    font-size: 22px !important;
    font-weight: 800 !important;
}
.react-tabs__tab--selected {
    background: transparent !important;
    border-bottom: 5px solid #181818 !important;
    color: #181818 !important;
    border-radius: 0 !important;
}
.vouchergift-popup .full-login-new-body{padding: 40px 20px 20px; max-width: 540px;}
.vouchergift-popup .button {width: 100%; margin: 15px 0px 0px 0px;}
.vouchergift-popup .gift-group{margin:0px; }
.howtouse-gift-vouchar {padding: 30px 0px 0px;}
.howtouse-gift-vouchar h3 {font-size: 16px; text-transform: uppercase; margin: 10px 0px 10px;font-weight: 500}
.myvouchar-main-div .promo-earned-col-item{display: flex;flex-direction: column;height: 100%}
.myvouchar-main-div.mypromo-main-div .myacc_order_details .promo-earned-col {width: 33.33%; padding: 0px 10px;}
.myvouchar-main-div .promo-earned-col-image {width: auto; height: auto;}
.myvouchar-main-div .promo-earned-info:before {display: none;}
.myvouchar-main-div .promo-earned-info {width: calc(100% - 0px); padding: 25px 10px 70px 10px;}
.promo-earned-top > span{ font-size: 14px; font-weight: bold}
.myvouchar-main-div .promo-earned-info .promation_btn{ position: absolute; bottom: 10px; left: 10px; right: 10px; width: auto;}
.myvouchar-main-div .promation_btn .promation_btn-one { margin-right: 0px; width: 100%;}
.myvouchar-main-div .promo-earned-code {font-size: 18px; text-transform: uppercase}
.myvouchar-main-div .promo-earned-code span { display: block; margin-top: 5px; margin-bottom: 5px;}
.myvouchar-main-div .promo-earned-info p {font-size: 15px; color: #949292; margin: 15px 0px 15px;}
.vouchar-popup.redeem_popsec {max-width: 560px; background: transparent;}
.vouchar-popup.redeem_popsec .redeem_bansec .promo-popup-image {width: 100%;}
.vouchar-popup.redeem_popsec .redeem_bansec .promo-earned-content {
    width: calc(100% - 0px); padding: 30px 30px 20px; text-align: center; background: #FFF; margin-top: -1px;
}
.vouchar-popup.redeem_popsec .promo-earned-content h4 {
    font-size: 18px; text-transform: uppercase; margin: 0px 0px 8px 0px;
    
}
.vouchar-popup.redeem_popsec .promo-earned-content h3 {font-size: 35px; margin: 0px 0px 8px 0px;}
.vouchar-popup.redeem_popsec .promo-earned-content .expiry-date {
    font-size: 15px; font-weight: 900; 
}
.vouchar-popup.redeem_popsec .promo-earned-content p {margin: 12px 0 0;}
.vouchar-popup.redeem_popsec .btn {max-width: 100%; text-align: center;}
.vouchar-popup.redeem_popsec .mfp-close {top: 12px; right: 12px;}
.vouchar-popup.redeem_popsec .addcart_row {
    display: block; padding: 0px; margin: 50px auto 20px;
}
.vouchar-popup.redeem_popsec p.sel-quality {
    font-size: 15px; color: #535353; margin: 10px auto 10px; text-align: center;
     font-weight: 400;
}
.mypromo-main-div.myvouchar-main-div .myacc_order_details {
    justify-content: normal;
}

.qty_bx.free_product span:after {
display:none;
}



.vouchergift-popup .tab-menu-container {position: relative;display: block;width: 100%;margin: 10px 0 20px;}
.vouchergift-popup .tab-menu-container ul {width: 100%;padding: 0;display: flex;list-style: none}
.vouchergift-popup .tab-menu-container ul li {position: relative;width: 50%;float: left;text-align: center;}
.vouchergift-popup .tab-menu-container ul li a {font-size: 20px;font-weight: bold;text-transform: capitalize;color: #000;display: block;width: 100%;border-bottom: 5px solid #e4e4e4;padding-bottom: 10px;height: 100%;}
.vouchergift-popup .tab-menu-container ul li a.active,
.vouchergift-popup .tab-menu-container ul li a:hover {border-bottom: 5px solid #523628;-webkit-transition: all .2s linear;transition: all .2s linear;}
.vouchergift-popup .product_tab_item {display: none;}
.vouchergift-popup .tab_item_active {display: block;}
.vouchergift-popup .product_choosen_row_inner {padding-top: 10px;}

.review-section{margin: 40px 0 0 0}
.review-section h3{ font-size: 20px;
    font-weight: 600;
    border-bottom: 2px solid #eaeaea;
    margin: 0 0 10px 0;
    padding: 0 0 10px 0;}
.review-full ul {
    list-style: none;
    padding: 30px 0 0 0;
}

.review-full ul li {
    margin-bottom: 30px;
}
.review-full ul li:last-child{margin-bottom: 0}

.review-author {
    position: relative;
    display: flex;
    justify-content: space-between;
}

.review-author-img {
    width: 58px;
    height: 58px;
    border-radius: 50%;
    overflow: hidden;
}

.review-author-content {
    width: calc( 100% - 74px );
}

.review-author-content h4 {
    text-transform: inherit;
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 10px;
}
.review-author-content p{margin: 0; font-size: 15px;}
.product-details-category-section {
    margin: 40px 0 0 0;
    text-align: center; 
}
.category-products-main .owl-stage { display: flex;}
.container-noneed > h3{ text-align: center; font-size: 32px;  margin: 0 0 10px 0; color: #523628;
    font-family: 'Athelas'; font-weight: normal}
.container-noneed > p{ font-size: 15px; max-width: 920px; margin: 0 auto}
.container-noneed .category-products-main{ margin: 20px 0 0 0; }
.category-products-main .products-list-ulmain > ul{ padding: 0; display: flex; flex-wrap: wrap;}
.category-products-main .products-list-ulmain > ul > li{text-align: center;
    width: 23.828125%;
    margin: 0 1.5625% 20px 0;}
.category-products-main .products-list-ulmain > ul > li:nth-child(4n) { margin-right: 0;}

.prodet_top .slick-dots{ padding: 0;}
.prodet_top .slick-dots li{ list-style: none; margin: 0 4px; display: inline-flex; max-width: 25% }
.prodet_top .slick-dots li button{ padding: 0; font-size: 0; width: 12px; height: 12px; overflow: hidden; border-radius: 50%; min-width: inherit; margin: 0; }
.prodet_top .slick-dots li.slick-active button{ background: #000; }
.prodet_top ul.slick-dots {
  position: absolute; bottom: 20px; left: 0; right: 0;
}


.faq-inner-banner{
	text-align: center;
}

.faq-content .accordion {
    border-radius: 0;
    border: 0;
}

.faq-content .accordion .accordion-item.active .title {
    background-color: #333;
    color: #fff;
}

.faq-content .accordion .accordion-item .title{
	background-color: #f6f6f6;
    color: #444;
    cursor: pointer;
    margin: 0;
    width: 100%;
    text-align: left;
    border: none;
    outline: none;
    padding: 17px 50px 17px 25px;
    transition: all .5s ease;
    -ms-transition: all .5s ease;
    -o-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -webkit-transition: all .5s ease; letter-spacing: 0;
}

.faq-content .accordion .accordion-item:first-child .title{
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}

.faq-content .accordion .accordion-item:last-child .title{
	border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}


.faq-content .accordion .accordion-item .panel p{
	padding: 30px 35px 30px 25px;
	border: 1px solid rgb(82 54 40);
	border-radius: 6px;
    margin-bottom: 0px;
    color: #666;
}

.faq-content .accordion .accordion-item .panel{
	margin-bottom: 20px;
	border: 0;
	-webkit-box-shadow: 0 0 25px rgb(0 0 0 / 15%);
    box-shadow: 0 0 25px rgb(0 0 0 / 15%);
}

.faq-content .accordion .accordion-item .title:after {
    content: "\f107";
    font-family: FontAwesome;
    float: right;
    text-align: center;
    margin: 0;
    font-size: 28px;
    transition: all .3s linear;
    display: block!important;
    font-weight: 400;
    position: absolute;
    right: 20px;
    top: 25%;
    transform: translateX(-25%);
}

.faq-content .accordion .accordion-item.active .title:after {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    display: block!important;
    content: "\f107";
    color: #fff;
}

.bd-info-merge {
    background: #fff;
    margin-bottom: 30px; 
}
.no-product {
    padding: 60px 20px;
    text-align: center;
    color: #fff;
}


.contact-all{
    padding: 10px 0 40px;
}

.contact-all-container{
    display: flex;
    justify-content: space-between;
}

.contact-box-left h3,
.contact_form-right h3{
    text-transform: uppercase;
    font-family: 'Athelas';
    color: #523628;
    text-align: center;
}

.contact-box-left ul,
.contact_form-right ul{
    padding: 0;
}

.contact-box-left ul li{
    list-style: none;
    padding-bottom: 30px;
}

.contact_form-right ul li{
    list-style: none;
    padding-bottom: 20px;
}

.contact-box-left ul li.contact-about-order{
    padding-bottom: 40px;
}

.contact-box-left ul li h5,
.contact_form-right ul li h5{
    margin-bottom: 5px; font-size: 18px
}

.contact-box-left ul li p,
.contact_form-right ul li p{
    margin-bottom: 0;
    text-align: center;
}

.contact-box-left ul li p a{
    color: #000;
}

.contact-box-left ul li p a:hover{
    color: #825a46;
}

.contact_form {
    max-width: 800px;
    margin: 0 auto;
    width: 100%;
}

.contact_form-right .form_sec .contact_col{
    width: 100%;
    padding: 0;
}

.contact_form-right .form_sec .contact_col .col-50{
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0;
}

.contact_form-right .form_sec .contact_col .col-50 .form-group{
    width: 49.3%;
}

.contact_form-right textarea.form-control{
    height: 215px;
}

.contact_form-right .btn_sec .btn {
    max-width: 142px;
    margin: 3px 0 0;
    background: #523628;
    border-radius: 0;
    color: #fff;
}
.contact_form-right .btn_sec {
    display: flex;
    justify-content: flex-end;
}
.locations-page .container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.location-lhs {
    width: 39%;
    padding: 0 40px 0 0;
}

.location-rhs {
    width: 61%;
    box-shadow:0px 1px 7px 0px rgba(0, 0, 0, 0.21);
        line-height: 0;
}
.llhs-body ul, .outlet-details-m ul {
    padding: 0;
    list-style: none;
}

.llhs-body > ul > li {
    background: #f3f3f3;
    padding: 20px 30px;
    margin-bottom: 15px;
    border: 1px solid #f3f3f3;
    box-shadow:0px 1px 7px 0px rgba(0, 0, 0, 0.21);
}
.llhs-body > ul > li.active, .llhs-body > ul > li:hover{ 
  border-color: #af7853;
}

.llhs-body > ul > li li, .outlet-details-m ul li {
    margin: 0 0 18px 0;
    position: relative;
    display: flex;
    align-items: flex-start;
}
.llhs-body > ul > li li h5, .outlet-details-m ul li h5{margin-bottom: 5px; font-size: 18px;}
.llhs-body > ul > li li a .outlet-details-m ul li a{ color: #000; }
.llhs-title span {
    width: 100%;
    display: block;
    font-weight: bold;
    padding: 0 0 10px 0;
}

.llhs-title {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 0 15px 0;
}

.llhs-title select {
    width: 49%;
    margin: 0;
}
.llhs-body > ul > li li img, .outlet-details-m ul li img {
    margin-right: 15px; top: 2px; position: relative;
}
.llhs-body > ul > li li:last-child{margin-bottom: 0}
.llhs-body {
    max-height: calc( 780px - 93px );
    overflow-y: auto;
}
.location-micro .llhs-body {
    max-height: inherit;
    overflow-y: inherit;
}
.location-micro .llhs-body ul li img {
    width: 100%;
}
.location-micro .llhs-body ul li {
    padding: 20px;
}
.outlet-micro-title .container:before, .outlet-micro-title .container:after {
    display: none;
}
.outlet-micro-title .container a {
    color: rgb(0, 128, 128);
    text-transform: uppercase;
    text-decoration: underline;
    font-weight: 900;
    font-size: 18px;
}
.outlet-details-m ul li {
    line-height: normal;
}
.outlet-details-m ul li:last-child, .outlet-details-m ul li:last-child p:last-child {
    margin-bottom: 0;
}
.outlet-details-m ul {
    padding: 30px;
}
.location-micro {
    padding-bottom: 40px;
}



.story-top .container, .outlet-micro-title .container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;
    align-items: center;
    padding: 0
}
.outlet-micro-title .container {
    padding: 0 20px 20px 20px;
}
.story-middle .container{padding: 0}

.story-top-img {
    width: 45%;
    order: 2;
}
.story-top-img img{
  width: 100%;
}

.story-top-text {
    width: 55%;
    order: 1;
    z-index: 1;
    position: relative;
}

.story-top-inner {padding: 40px; box-shadow: 0px 1px 29px 0px rgb(0 0 0 / 10%); margin-right: -60px;  background: #fff;}
.story-btm .story-top-img{ order: 1;  }
.story-btm .story-top-text{  order: 2; }
.story-btm .story-top-inner { margin-right: inherit; margin-left: -60px;}
.story-middle-top{  padding: 50px 0; }
.story-middle-top, .story-middle-btm {  display: flex;  flex-wrap: wrap; justify-content: space-between;   }
.story-middle-btm{ padding: 0 0 50px 0;}
.story-middle-top-lhs, .story-middle-btm-lhs { width: 49%;}
.story-middle-top-rhs, .story-middle-btm-rhs { width: 49%;}
.story-middle-btm-lhs img, .story-middle-btm-rhs img{ width: 100%; }
.story-middle-btm-lhs, .story-middle-btm-rhs{ background: #523628; color: #fff; }
.story-middle-btm figcaption { padding: 20px 30px;}
.story-middle-btm figcaption h6 { text-transform: uppercase;  font-family: 'Athelas';  font-weight: normal; font-size: 20px; margin-bottom: 15px;}
.story-middle-btm figcaption p {  margin: 0; font-weight: 300;}
.breadcrumb-main{width: 100%}
.breadcrumb{ padding: 0; background: none;}
.breadcrumb > li + li:before{ content: "\f105"; font-family: FontAwesome; padding: 0 10px }
.breadcrumb > li { text-transform: uppercase}
.cart-warning-alert {
    font-size: 14px;
    color: #ba8c6d;
    margin: 5px 0 0 0;
    text-align: right;
    width: 100%;
}
.top_header_blk {
    background: #b22222;
    text-align: center;
    color: #fff;
    font-weight: 300;
    padding: 11px;
    position: relative;
    z-index: 12;
}
.top_header_blk p{margin: 0;}
.top_header_blk a{ color: #fff; }

.pickup-outlet-details {
    padding: 12px 40px 12px 20px;
    border: 1px solid #cbcbcb;
    width: 100%;
    background-color: #fff;
    border-radius: 0px;
    font-size: 15px;
    color: #555555;
    cursor: pointer
}
.pickup-outlet-details strong {
    color: #523628;
    padding-right: 10px;
}
.pickup-outlet-details:after {
    content: "\f107";
    font-family: 'FontAwesome';
    position: absolute;
    right: 10px;
    font-size: 32px;
    color: #b3b3b3;
    top: 50%;
    transform: translateY(-50%);
    line-height: 0;
}
.select-loc{position:relative} 
.select-loc ul {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 91;
    background: #f5f5f5;
    opacity: 0;
    visibility: hidden; transition: 0.3s linear all;     max-height: 300px;
    overflow: auto;
}
.select-loc.active ul{ visibility: visible; opacity: 1;}
.select-loc ul li {
        padding: 7px 10px;
    border-bottom: 1px solid #e0e0e0;;
    cursor: pointer;
    font-size: 15px;  transition: 0.3s linear all;  
}
.select-loc ul li:hover{ background: #523628; color: #fff; }
body .css-1hwfws3{ padding-left: 16px; padding-right: 16px; font-size: 15px; }

.hcategory_sec {
    position: relative;
    display: none;
}

.hcategory_menu {
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    z-index: 2;
    background: #f2f2f2;
    max-height: 300px;
    overflow-y: auto;
    display: none;
}
.hcategory_menu.open{
    display: block
}
.hcategory_sec > a {
    padding: 13px 15px 13px 15px;
    background: #523628;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 1;
    color: #fff;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.hcategory_trigger img {
    width: 34px;
    margin-left: 15px;
}

.hcategory_trigger {
    font-weight: 100;
    font-style: italic;
    font-size: 15px;
    display: flex;
    align-items: center;
    line-height: 1;
        width: 140px;
    justify-content: flex-end;
}
.hcategory_sec > a strong {
    width: calc( 100% - 140px );
}
.hcategory_menulist{ padding: 0; list-style: none}
.hcategory_menulist > li {  border-bottom: 1px solid rgb(82 54 40 / 8%);}
.hcategory_menulist a { display: block;  padding: 10px 10px;}
.hcategory_menulist > li > a{ font-weight: bold; text-transform: uppercase}
.hcategory_menulist ul {
    list-style: none;
    padding: 0 0 5px 15px;
}

.hcategory_menulist ul a {
    padding: 4px 10px;
}
.product-hg-option {
    margin: 10px 0;
}

.product-hg-option img {
    max-width: 70%;
}

.cart_row.cart-header-second .syd_time.delivery_submit_cls{text-align: right}
.cart_row.cart-header-second .react-datepicker__time ul{display: block}
.cartaction_bottom.cart-checkout-disable {
    position: relative;
    z-index: 2;
}

.cartaction_bottom.cart-checkout-disable:after {
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgb(82 54 40 / 55%);
    z-index: 2;
}
.msc-header {
    text-align: center;
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    padding: 10px 0 60px 0;
}
.msc-header span {
    color: #b3946a;
    font-weight: 900;
    font-size: 14px;
    margin: 7px 0 0 0;
}
.member-ship-content h5{ text-transform: inherit; color: #523628;font-weight: 900; margin-bottom: 20px }
.member-ship-content p{margin: 0 0 10px 0}
.member-ship-content h4 {
    color: #b78c57;
    font-weight: 900;
    padding-top: 20px;
    font-size: 22px;
    margin-bottom: 15px;
}
.member-ship-content ul {
    list-style: none;
}

.member-ship-content ul li {
    padding: 9px 0;
}
.msc-inner{position: relative; padding: 55px 0 0 0}
.member-ship-content{padding: 0 40px 40px 40px; font-weight: 600;}
.memeber-shop-footer {
    background: #ece4e0;
    padding: 40px;
    display: flex;
    align-items: center;
    flex-flow: column wrap;
}
.memeber-shop-footer a{margin: 0; min-width: 270px;}
.memeber-shop-footer a:first-child{margin-bottom: 10px}
a.goback-msc {
    position: absolute;
    top: 20px;
    left: 40px;
    z-index: 1;
    text-transform: uppercase;
    font-weight: 600;padding-left: 20px;
}
a.goback-msc:before {
    content: "\f104";
    font-family: 'FontAwesome';
    font-size: 26px;
    font-weight: 500;
    position: absolute;
    left: 0;
    top: -2px;
    line-height: 0.9;
}
.msc-form {
    max-width: 800px;
    margin: 0 auto;
    padding: 0 40px 40px 40px;
}
.msc-form-header{text-align: center; padding-bottom: 30px}
.msc-form .popup-body .form-group{ max-width: 416px; margin-left: auto;margin-right: auto;margin-bottom: 0}
.css-1wy0on6{  font-size: 14px !important }

.msc-form .css-10nd86i{ z-index: inherit; }
.msc-form .css-10nd86i input{ height: auto; margin: 0 }
.msc-form .css-vj8t7z{ min-height: 48px;}
.msc-form .css-2o5izw{ min-height: 48px; }
.css-2o5izw{ border-color:#523628 !important; box-shadow: none !important }
.msc-form span.error{ z-index: inherit }
.seperate-mob {
    display: flex;
    justify-content: space-between; margin-bottom: 10px;
}

.seperate-mob .css-10nd86i {
    width: 128px;
}

.seperate-mob .form-control {
    width: calc( 100% - 138px );
}
.seperate-mob input{ margin-bottom: 0; }

.member-updates-msg {
    font-style: italic;
    color: #000;
    text-align: center;
    font-size: 15px;
    padding: 20px 0;
}

.msc-form .custom_checkbox {
    display: flex;
    align-items: center;
}

.msc-form .custom_checkbox  .sign_reg {
    width: calc( 100% - 30px );
}
.msc-form .form-group .re_select{margin-bottom: 10px}
.form-group-checkbox {
    display: flex;
    flex-wrap: wrap;
    max-width: 416px;
    margin: 0 auto;
}

.msc-form .form-group-checkbox .form-group {
    margin: 0 25px 10px 0;
}
.info_content {
    max-width: 400px;
}
.location-map-img img{ max-width: 100% }
.location-map-title h4 {
    font-size: 16px;
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
    color: #523628;
    padding: 0 0 10px 0;
    margin: 0 0 0px 0;
}
.location-map-title h4 a {
    color: #3eb4aa;
}
.location-map-title h4 a:hover{ color: #523628; }
.gm-style{  font-family: 'Lato';  }
.gm-style .gm-style-iw{ font-size: 13px; font-weight: 400 }
.location-map-address{margin-bottom: 10px; position: relative; padding-left: 22px}
.location-map-phone{ position: relative; padding-left: 22px }
.location-map-address:before{ content: ""; background: url(../images/list-map.png) no-repeat; position: absolute; left: 0; top: 0px;     background-size: contain !important;  width: 14px;  height: 15px;}

.location-map-phone:before{ content: ""; width: 16px; height: 17px; background: url(../images/list-phone.png) no-repeat; position: absolute; left: 0;  top: 0px;  background-size: contain !important;}

.gm-ui-hover-effect{     top: 14px !important;
    right: 20px  !important;
    width: 20px !important;
    height: 20px !important;
    background: #f3f3f3 !important; opacity: 1 !important; }
.gm-ui-hover-effect img{ margin: 3px auto !important; } 



.store-card.overlay .store-image {
	width: 373px !important;
	height: 261px !important;
}

.store-card.overlay {
	max-width: 373px !important;
}
.store-content{ padding: 20px}
.location-map-img img{    object-fit: cover;width: 100%;
    height: 100%;}

.store-card {
	 box-sizing: border-box;
	 box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	 border-radius: 4px;
	 padding: 10px;
	 margin-bottom: 15px;
	 border: 1px solid transparent;
	 position: relative;
	 margin-left: 5px;
	 margin-right: 5px;
	 overflow: hidden !important;
}

 .store-card.overlay {
	 z-index: 9999;
	 min-width: 250px;
	 padding: 0;
	 margin: 0;
	 background-color: #fff;
	 max-width: 373px !important;
}
 
#mapCanvas .gm-style .gm-style-iw-c {
	 padding: 0;
	 border-radius: 4px;
}
 #mapCanvas .gm-style .gm-style-iw-c .gm-style-iw-d {
	 overflow: auto !important;
}
.gm-style .gm-style-iw-t::after{top: -1px !important}


.birth-lhs {
    width: 400px;
}

.birth-rhs {
    width: calc(100% - 460px);
}

.birth-bonus {
    background: #f3f3f3;
    padding: 60px 0;
}
.birth-bonus .container:before, .birth-bonus .container:after, .greater-reward .container:before, .greater-reward .container:after{ display: none;}
.birth-bonus .container, .greater-reward .container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.birth-lhs ul{ padding: 0; list-style: none}
.birth-lhs ul li{ padding: 15px; background: #fff; display: flex; align-items: center;flex-wrap: wrap; justify-content: space-between; margin: 0 0 20px 0;font-weight:300;    font-size: 15px; max-width: 350px;}
.birth-lhs ul li img{ width: 30px; height: 30px; }
.birth-lhs ul li span{ width: calc( 100% - 40px ) }
.birth-lhs h3, .gr-rhs h3 {  color: #523628;  margin: 0 0 10px 0;}
.birth-lhs p {  margin: 0 0 30px 0;}
.sweet-saving { padding: 40px 0 20px 0;}
.ss-notice h5 { font-size: 18px;  margin: 0 0 15px 0;}
.ss-notice { text-align: center;padding: 50px 0 0 0;}
.ss-perks{ background: url(../images/bg-item.jpg) no-repeat; background-size: cover; padding: 50px 20px;margin: 0}
.ss-perks ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.ss-perks ul li{ width: 19%;  background: #fff; border-radius: 4px; padding: 16px 15px; line-height: 1.4; }
.ss-top h3{
    color: #523628;
    font-weight: 900;
}
.ss-top {
    padding-bottom: 20px;
}
.greater-reward{ padding: 50px 0; }
.gr-lhs{
     width: calc(100% - 470px);
}
.gr-lhs img{ width: 100%; }
.gr-rhs{
    width:440px;
}
.gr-full {
    display: flex;
    width: 100%;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    padding: 40px 0 0 0;
}
.gr-full span{ padding: 15px 0 15px 0; }
.gr-full  .button{    background: #523628;
    border-color: #523628;color: #fff;}
.gr-full .button:hover{ background: #000; color: #fff; border-color: #000; }
.gr-full a{ font-weight: bold;}
.grey-terms {
    background: #f3f3f3;
    padding: 50px 0;
} 
.grey-terms .container{ max-width: 780px; } 
.grey-terms ol li{ padding-bottom: 15px }
.grey-terms a{font-weight: bold; text-decoration: underline} 
.grey-terms h4{ text-align: center; padding-bottom: 10px; font-size: 24px;}
.gr-rhs{ text-align: right;}
.gr-rhs ul {
    padding: 20px 0 0;
    list-style: none;
}

.gr-rhs ul li {
    border: 1px solid #aaa;
    border-radius: 4px;
    margin: 0 0 20px 0;
    display: flex;
    overflow: hidden;
}

.gr-rhs ul li div {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    flex-flow: column wrap;
    text-align: center;
}
.sp-cal span{font-size: 14px;}
.gr-rhs ul li h4{margin-bottom: 5px; font-size: 19px; font-weight: 900;     color: #523628;}
.sp-voc {
    background: #dbccc3;
    font-size: 21px;
    font-weight: 900;
    color: #523628;
}
.voc-notes {
    font-size: 13px;
}
.reward-main footer{margin: 0}
.form-group-inline {
    display: flex;
    padding: 15px 0 0 0;
}
.form-group-inline .custom_checkbox { margin-right: 20px;} 
.product_chosen_col_right .text-box h4 {
    font-size: 15px;
    margin-bottom: 10px;
    text-transform: uppercase;
}


.locations-top ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 10px 0 30px 0;
    list-style: none;
}

.locations-top ul li {
    width: 32%;
    margin: 0 0 30px 0;
    box-shadow: 0px 0px 15px 0px rgb(0 0 0 / 8%);
    border-radius: 8px;
    overflow: hidden;
}

.lt-top-img img {
    width: 100%;
}

.lt-top-desc h4 {
    font-size: 18px;
    margin: 0 0 5px 0;font-weight: 900;
}

.lt-top-desc {
    padding: 20px;
}
.lt-top-desc p{margin-bottom: 0;}
.lt-top-desc span {
    display: block;
    padding-bottom: 20px;
    font-style: italic;
    font-size: 15px;    font-weight: 300;
}

.join-main {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.join-lhs {
    width: 48%;
}

.join-rhs {
    width: 48%;
}

.join-rhs ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 10px 0 0 20px;
}

.join-rhs ul li {
    width: 49%;
    margin-bottom: 5px;
}
.inclisive-gst {
    font-size: 13px;
    color: #666666;
    padding: 5px 0 0 0;
}
.story-control .container-one > .container {
    padding: 20px 0 20px 0;
}
.birth-bonus .container, .greater-reward .container {
    background: none;
}
.rewards-cms .container-one > .container.cms-content{
    max-width: 100%;
    padding: 0 0;
}
.reward-important {
    text-align: center;
    padding: 0 0 10px 0;
}
.reward-important a {
    text-decoration: underline;
    font-weight: bold;
}
.footer-whatsapp {
    position: fixed;
    right: 10px;
    bottom: 10px;
    width: 70px;
    height: 70px;
    z-index: 91;
}
.htico_search_mbl img {
    width: 20px;
}
.membership-form-popupcls .msc-header {
    padding: 10px 0px 5px 0px;
}
.membership-form-popupcls .nav-tabs {
    width: 70%;
    margin: 0px 15% 2% 15%;
}
.membership-form-popupcls .nav-tabs > li {
    margin-bottom: -12px;
}
.msc-form-hide {
    display: none;
}
.memberlogin-popup-body .single-link, .memberlogup-popup-body .single-link {
    margin-top: 14px;
}
.redeem_apply .apply_acentiscrm_points { position: relative; right: 0; top: 0; margin: 0;  padding: 16px 22px;  width: 100%; height: 100%;}
.seperate-mob { z-index: 2000; }
.memberlogup-popup-body .css-10nd86i { z-index: 2000; }
.reward-list-body .myacc_order_details .custom_crmpoints_cls { padding: 0 0 40px 0; }
.underline-cls { text-decoration: underline; text-transform: lowercase !important; }
.noavilablity { border-bottom: 2px solid #f4f3f3; }
.promo-instruction {
    margin: 5px 0 0 0;
    padding: 0 0 0 50px;
}