/**** Responsive ****/
@media (min-width: 1200px){        
.mobile_hmenu_list{ display: none;}
.hmenu_list > li:hover > a > .hdd_arrow{ opacity: 1; visibility: visible;}
}
@media (min-width: 679px){
.cur-ord-rec-mobile{ display: none;}

}   
@media (min-width: 576px){    
.modal-dialog { margin: 1.75rem auto; }
.modal-dialog-centered { min-height: calc(100% - (1.75rem * 2)); min-height: -webkit-calc(100% - (1.75rem * 2));}

}
@media(max-width: 1330px) and (min-width: 991px){
.promation_btn .btn {min-width: auto; width: 48%; float: left; margin: 1%; font-size: 16px; padding: 12px 10px;}

}

/* Max width */

@media (max-width: 1300px){
.redeem-item-hea-inner{width: 100%;}
.featured-products-main .slick-arrow{display: none !important;}        
.featured-products-full .slick-dots{ margin: 0; padding: 40px 0 0 0; list-style: none; display: block !important}   
.featured-products-full .slick-dots li{display: inline-block}    
.featured-products-full .slick-dots li button{ padding: 0; margin: 0 4px; width: 10px; height: 10px; border-radius: 50%; overflow: hidden; text-indent: -9999px;  }    
.featured-products-full .slick-dots li.slick-active button{ background: #d45d38 }
.featured-products-full{ width: 90%}
.featured-products-single{ max-width: inherit;}    
.featured-products-full .slick-track .slick-slide > div{ padding: 5px } 

}
@media (max-width: 1240px){
.header_in::before, .header_in::after, .ourstory-inner-cont:before{display: none;}	
.promo-earned-col-image {width: 180px;}
.promo-earned-info {padding: 20px 10px 10px 5px; width: calc(100% - 180px);}
.promation_btn .button {width: 46%; font-size: 14px; padding: 10px 5px 10px;}
.promo-earned-code span {font-size: 24px;}
ul.hmenu_list > li > a{ padding: 20px 0px;font-size: 14px;}
    .hsign_sec a, .htico_cart > a, .hordertype_btn{font-size: 14px;}    
}
@media (max-width: 1199px){
input{ -webkit-appearance: none; -moz-appearance: none; appearance: none;  border-radius:0;}
.sticky .alert_fixed{ top: 95px;}
.header_in{ padding: 0 }
.header_middle:before, header.small_sticky:before{display: none !important}    
.hmenu_actions{float: right}
.product-view-more {  margin-top: 15px;}    
.tbars header{ height: 153px;}
.breadcrumb{ padding: 25px 0 0;}
.Products .breadcrumb{ padding: 69px 0 0 0;}
.Products .bread_space { margin: 69px 0 0; }
.hmenu_actions > li.hsign_sec{ display: none;}
.mega_menuin [class*='col-']{width: 100%;}
.hmenu_actions li.horder_btn{display: none;}
.hsearch_bx .re_select .Select-control:before{ right: 14px;}
i.hdd_arrow{ position: absolute; right: 0; top: 0; bottom: 0; height: 45px; width: 45px; line-height: 45px; font-size: 16px; text-align: center; background: #ecdfcc; color: #181818; margin: auto 0;}

.hsearch_trigger:before{ top: 36px; right: 12px;}
.hsearch_bx .re_select .Select-control{ height: 49px;}
.hsearch_bx .re_select .Select-placeholder{ line-height: 49px;}
.hsearch_bx .re_select .Select-input{ height: 49px;}
/* Sticky Header */
.bakery_row_fixed{ top: 162px;}
.tbars .bakery_row_fixed{ top: 202px;}
.featured-products-full{ width: 100%}
.delivery_total.delivery_total_number .delivery_total_left h2{font-size: 15px;}
.delivery_total.delivery_total_number .delivery_total_left h4{font-size: 28px;}
.innerproduct-col{width: 32.29061553985873%;margin-right: 1.5136226034308779%;margin-bottom: 15px;}
.innerproduct-col:nth-of-type(3n+3){margin-right: 0;}
.innerproduct-col:nth-of-type(5n+5){margin-right: auto;}
.chk_hea{font-size: 16px;}

.checkout-total .checkout-control-group-top a{margin-top: 0;}
.order-detail-inner-icon{max-width: 100%;}
.order-detail-inner-icon-left.order-detail-inner-center::before{background-repeat: repeat;max-width: 100%;height: 6px;}
.order-detail-inner{padding-top: 27px;}
.main_tabsec_inner> .nav-tabs>li> a{text-align: left;}
.title_sec h2{font-size: 34px;}
.filter_tabsec .nav-tabs>li>a{font-size: 17px;}
.hme-product-inner .filter_tabsec .nav-tabs{margin-bottom: 20px;}
.htopinfo{text-align: center;width: 100%;}
.delivery-item h2{font-size: 32px;}
.delivery-item span{font-size: 17px;}
.hme-product-inner{padding: 20px 15px;}
.outlet-list li{ padding-left: 0;}
.cart_footer_totrow p{font-size: 20px;}
.cart_footer_totrow span{font-size: 20px;}
.cart_footer p{font-size: 14px;}
.cart_footer{padding-bottom: 10px;}
.delivery_info h4{font-size: 14px;}
.delivery_info p{font-size: 14px;}
.cart_action .btn_grey{font-size: 14px;}
.tnk-you{padding: 70px 0;}
.redeem-col{width: 100%;margin: 0 0 10px 0;padding: 20px 10px;}
.redeem-item-hea{padding-left: 0;}
.redeem-item-hea h4{font-size: 23px;}
.tnk-order h3{font-size: 28px;}
.tnk-order p{ font-size: 14px;}
.tick h2{font-size: 34px;margin-top: 10px;}
.tick span{font-size: 16px;}
.delivery-inner{padding: 40px 0;position: relative;}
.delivery-inner-comman{position: relative;z-index: 2;}
.delivery-inner::after{content: "";background: rgba(255, 255, 255, 0.6);top: 0;left: 0;bottom: 0;right: 0;position: absolute;z-index: 1;}
.bts .form-group {min-width: auto;width: calc(100% - 200px) !important;}
/*promotion pages*/
.promo-earned-content{padding: 10px 10px; }
.promo-earned-content h2{ font-size: 30px; }
.promo-earned-content h2 span sup {font-size: 20px; }
.abc_code span{ font-size: 17px; }
.abc_code{ padding: 10px 0; }
.redeem_popsec .promo-earned-content h2 span sup {font-size: 18px; }
.redeem_popsec .promo-earned-content h4{ font-size: 24px; }
.redeem_popsec .abc_code {padding: 6px 0 0px; }
.redeem_popsec .promo-earned-content{ padding: 20px 10px 20px; }
.redeem_popsec .promo-earned-content h2{ font-size: 30px;}
/* Account information page */
.setting_menu_list{ display: none;}
.catogry_row_fixed .bakery_slider{ top: 118px }        
.pkg_ginfo p{ max-height: 75px; overflow: hidden}    
.header-bottom .logo{display: none}    
.mobile-logo-div{display: -webkit-box !important;display: -ms-flexbox !important;display: flex !important;text-align: center; position: absolute;left: 0;right: 0; max-width: 210px; margin: auto;top: 5px;bottom: 5px; -webkit-box-align: center; -ms-flex-align: center; align-items: center}       
.mobile-logo-div a{display: block;width: 100%;}    
.mobile-logo-div a img{ width: 100% }    

.prodet_bansec .slick-slide img {height: 440px;}
.prodet_bansec .slick-dots.slick-thumb li a {height: 100px; width: 80px;}
.prodet_bansec .slick-dots.slick-thumb {margin-top: 10px;}
.prodet_bansec .slick-dots.slick-thumb li {margin: 0 10px 10px 0;}
.mainacc_menucontent {padding: 25px 20px;}  
.accsec_right {padding: 0px 0px;}
.contact_wrap {padding: 0px 0px 40px;}
.addcart-row-child {flex-wrap: wrap;}
.qty_bx {width: 120px;}
.cont_ord .con_ord_txt img {max-height: 60px;}
.main-banner { padding: 120px 0 200px 0; min-height: 600px;}    
}
@media (max-width: 1180px){
.pkselected_package .buffet-label {padding: 15.5px 15px 15.5px 60px;width: 130px;}
.pkselected_package .form-group {/*width: calc(100% - 135px);*/ min-width: auto;}
.pkselected_filtersec .time-label {width: 171px;}
.pkselected_break .form-group {min-width: auto; /*width: calc(100% - 175px);*/}
.row-pgk-list > li, .pkg_gcol { width: 33.333333333333%;}    
ul.hmenu_list > li > a, .hsign_sec a { font-size: 14px; }    
    ul.hmenu_list > li > a{padding: 10px 2px}    
.compo_acc_innerdiv .chosen_adn > div:first-child {max-width: 70%;text-align: left;width: auto;}
.contact_wrap {  padding: 0;}     
}
@media (max-width: 1100px){
.order_delivery_mobile_tooltip, .selfcollect_tooltip .order_delivery_mobile_tooltip{top: -119px; left:-13px; right:-13px; height: 110px; opacity:1; visibility:visible; pointer-events:auto;}
.order_delivery_mobile_tooltip:before, .selfcollect_tooltip .order_delivery_mobile_tooltip:before{ top: auto; right: auto; left: 50%; bottom: -16px; border-color: #181818 transparent transparent transparent; border-width: 10px 7px 10px 7px; margin: 0 0 0 -7px;}
.order_delivery_mobile_tooltip p{ font-size: 13px; }	
.chekout_cart_bar .cart_extrainfo{padding-left: 0px;}
.ord-amount [class*='col-']{width: 100%;}
.earned_points{float: none;}
.ord-rec-sec{float: none;width: 100%; margin-top: 10px;}
.sprite_lst_icon{display: none}
.web_order_typecls li a h5{font-size: 16px;} 
.abt_flx_txt{padding-left: 20px; padding-right: 20px}    
   
}
@media (max-width: 1080px){
.header-top-cls {padding:8px 0px 8px;}	
.small_sticky .header-top-cls{ padding: 5px 0 }    
.mobile_accountdel_body, .mobile-order-nowbtn{display: block;}
.header_bot{display: block; margin: 15px 0 0 0;}       
.hordertype_sec, .header-bottom{display: none}  
.logo-main-section{-webkit-box-align: center;-ms-flex-align: center;align-items: center; padding-left: 33px;padding-bottom: 0}    
.menu_icon{ display: block;  margin: 3px 0px; cursor: pointer;left: -33px; position: relative} 
.menu_icon.trigger_menunav_act {display: flex;}   
.menu_icon .icon-bar{ display: block; background-color: #523628; height: 2px; -webkit-transition: all 400ms; transition: all 400ms;}
.menu_icon .icon-bar+.icon-bar { margin-top: 6px;}
.menu_icon .symbol-merge { width: 28px; height: 20px; margin-right: 10px; }
.menu_icon a { font-size: 14px; color: #525252; }
.icon-bar1{ width: 50%; }    
.icon-bar3{  width: 50%; float: right;}    
.hsign_sec{display: none;}     
.htico_search {display: none;}
.htico_search_mbl{display: block;}    
.htico_search_mbl > a > i { background: none;font-size: 22px; color: #171717;}   
.htico_cart { margin-left: 7px;}    
.hsearch_sec_mbl{right: 30px}    
.hmenu_sec {display: none;}
.compo_acc_innerdiv .chosen_adn > div:first-child {max-width: 60%;text-align: left;}
.container, .home-cafe-container{ padding-left: 15px; padding-right: 15px}    
.footer-nav-info{padding: 40px 0}    
.htico_search > a, .htico_search_mbl > a{top: 0px; margin-right: 4px; }
.htico_cart > a{padding-right: 25px}    
.mailform .button { position: static;  padding: 11px 16px 11px 16px;}    
.mailform input[type="text"] { padding-right: 10px;  margin-bottom: 5px;}    
.mailform { margin-bottom: 20px;}    
.homecafe-text h3{ font-size: 30px; margin-bottom: 10px;}    
.hc-left{min-height: 320px;}
.story-top-inner{ padding: 30px;}    
.homecafe-text h3, .story-top-text h3 { font-size: 28px; margin-bottom: 15px;}    
.story-top-img{ height: 360px; width: 50% }    
.story-top-img img{ height: 100%; object-fit: cover }
.story-top-text{width: 50%}    
.innerproduct > h3, .fproducts-title h3, .welcome-lhs h3{font-size: 28px}    
.productlist-main-div .products-list-ulmain{justify-content: space-between}    
.productlist-main-div .products-list-ulmain > li{width: 49%;margin: 0 0 20px 0;}     
.checkout-total .cdd-details-lhs{width: 100%;margin-bottom: 10px;display: flex;  flex-flow: column;} 
.check_pg_inner{padding: 20px 15px 10px 15px;}
.checkout-total .checkout-control-group-top{padding: 20px 0 20px 0;}
.checkout-total .cdd-details-rhs{width: 100%;}   
.cdd-details-lhs-top { order: 2;}    
.birthday-info-div{order:1;padding-top: 10px}    
    .llhs-body > ul > li{padding: 20px 15px}    
}
@media(max-width: 1112px) and (min-width: 768px){
    /* CSS specific to iOS devices only */ 
    @supports (-webkit-overflow-scrolling: touch) {
        .hcart_scrollarea { max-height: 100%; padding-bottom: 160px;}
        header .hcart_dropdown .cartaction_bottom{ padding: 10px 0px 80px 0px; background: #fff;}
    } 
}
@media (max-width: 1024px){
.footer{padding: 30px 0;}
.copyright{padding: 15px 0;}    
.delivery{margin-top: 30px;}    
.banner-text-box-right .btn_minwid{min-width: 100%;}
.bakery_row .slick-next{right: 0;}
.congrats.redeem-promation-body{padding: 24px 0;}    
.prd-sub{max-width: 100%;}
.prd-sub .slick-prev{z-index: 1; background: rgba(255,255,255,0.75); left: 0;}
.prd-sub .slick-next{z-index: 1; background: rgba(255,255,255,0.75); right: 0;}
.product_chosen_hea h6{font-size: 14px;}
.tnk-you{padding:45px 0 30px 0;}
.number-break .back_bx {left: 15px; width: 55px; height: 60px; font-size: 14px; padding: 40px 10px 10px;}
.number-break .back_bx:after {top: 10px; font-size: 26px;}
.number-break {padding: 25px 30px 25px 45px;}  
.award_galimg{ height: 250px;}  
.head_left h4{font-size: 19px;} 
.head_right h4{font-size: 16px;}    
.order_amt h3{font-size: 21px;}  
.qty_bx span.qty_minus:after {height: 1.5px; background-size: cover;}
.qty_bx span.qty_plus:after{background-size: cover;}
.cont_ord .cont_ord_item  {flex: 1 1 100%;max-width: 100%;margin-bottom: 10px;}
.prodet_bansec {width: 100%;}
.inn_product_row {width: 100%; padding-left: 0px;}     
.add-extra-paired-products-mobile{display: none}
.add-extra-paired-products-mobile.pare-mobile-one{display: block}    
.fproducts-title-img-inner h2{font-size: 45px}    
.location-lhs{padding-right: 20px}    
}
@media (max-width: 991px){
.hbslider_sec h2{ font-size: 20px;}	
.container_720 { max-width: 100%;}
.prodet_top .slick-track .slick-slide{border: 1px solid #e4e4e4;min-height: 450px; line-height: 0}    
.prodet_bansec .prodet_top .slick-slide img { display: block; height: auto; width: auto;border: 0}    
    
/*--category--*/
.pkg_ginfo h4 {font-size: 16px;margin: 0 0 10px;}
.pkgselect_dd .chosen-container {font-size: 14px;}
.pkgselect_dd .chosen-container-single .chosen-single {font-size: 14px;}
.pkg_gbtsec .btn {font-size: 14px; min-width: 170px;height: 42px;}
.pkg_ginfo .btn {font-size: 14px; padding: 9px 20px 7px;height: 34px;}
/*--update your package--*/
.pkg_listsec .bx_in {padding: 15px;}
.pkglist_itemtable > div {display: block;}
.pkglist_img {width: 100%; padding: 0px 0px 10px;}
.pkglist_info {width: 100%; padding: 15px 0px 0px;}
.pkglist_pricebx {width: 100%; border-left: 1px solid transparent; padding: 0px 0px; text-align: left;}
.pkselect_tabin {padding: 15px 0;}
.package_checkbx .bx_in {padding: 15px 15px;}
.package_formtt {font-size: 17px;}
.package_checkitem {padding: 15px 15px;}
.package_checkbx .package_formchecklist .custom_checkbox span {font-size: 14px;padding: 5px 0 0 40px;}
.package_checkbx .package_formchecklist .custom_checkbox span b {font-size: 14px;}
.package_addonbx .bx_in {    padding: 20px 15px;}
.package_setupbx .bx_in { padding: 20px 15px;}
.package_descbx .bx_in { padding: 20px 15px;}
.title4 {  font-size: 16px;}

.form-group [class*="col-md-"]{ margin-bottom: 8px;}
.form-group [class*="col-md-"]:last-child { margin-bottom: 0; }    
.delivery-item span{ font-size: 14px;}
.delivery-item h2{ font-size: 24px;}
.delivery-item p{ font-size: 16px;}
/* Tab sec */
.nav-tabs{ display: none;}
.tab-content>.tab-pane{opacity: 1; margin: 0 0 15px;}
.tab_mobtrigger{ display: block; position: relative; padding-right: 45px; margin: 0; cursor: pointer;}
.tab_mobtrigger i{ position: absolute; width: 45px; height: 25px; line-height: 25px; right: 0; top: 0; bottom: 0; 
margin: auto 0; text-align: center;}
.tab_mobtrigger i:after{ content: "\f067"; font: normal normal normal 14px/1 FontAwesome; font-size: inherit;  
text-rendering: auto; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale;}
.tab_mobtrigger.active i:after{ content: "\f068";}
.tab_mobrow{ display: none; margin: 0px 0 0;}
.tab-content > .tab-pane.active > .tab_mobrow{ display: block; padding-bottom: 0; padding-top: 0px;}
/* Main Tab */
.main_tabsec .nav-tabs{ display: none;}
.main_tabsec .tab_mobtrigger{ background: #b69a6b; padding: 17.5px 45px 17.5px 15px; font-size: 16px;  }
.filter_tabsec .tab_mobtrigger{ padding: 5px 45px 8px 10px; font-size: 16px; }
.filter_tabsec .tab_mobtrigger:after{ 
content: ""; background: #ecdfcc; height: 3px; position: absolute; left: 0; right: 0; bottom: 0;  pointer-events: none; -webkit-transition: all 400ms; transition: all 400ms;}
.tab-content > .tab-pane.promation-tab-section.active > .tab_mobrow{padding-top: 0;}
.small_responsive_row .filter_tabsec .tab_mobtrigger.inner_tab_border{display: none;}
.small_responsive_row .tab-content > .tab-pane.active > .tab_mobrow{display: block;}
.main_tabsec .small_responsive_row  .nav-tabs{display: block; } 
.mobiCategory .nav-tabs{ display:none;}
.sub_tab_mobtrigger.active {background: #0b0405; color: #fff;}
.sub_tab_mobtrigger {background:#b69a6b; padding: 15px 45px 15px 15px; font-size: 16px;  color: #fff; display: block; 
position: relative; margin: 0; cursor: pointer;margin: 0px 15px;}
.sub_tab_mobrow{ display: none; margin: 10px 0px 0px;}
.tab-content > .tab-pane.active > .sub_tab_mobrow {display: block; padding-bottom: 0; padding-top: 10px;}
.sub_tab_mobtrigger i {position: absolute;width: 45px;height: 25px;line-height: 25px;right: 0;top: 0;bottom: 0;
margin: auto 0; text-align: center;}
.sub_tab_mobtrigger i:after {
	content: "\f067"; font: normal normal normal 14px/1 FontAwesome; font-size: inherit;
	text-rendering: auto;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;}
.sub_tab_mobtrigger.active i:after {content: "\f068";}
.myacc_filter.small_responsive_row .filter_tabsec .nav-tabs>li>a, .small_responsive_rewards .myacc_filter .filter_tabsec .nav-tabs>li>a{font-size: 15px;}    
.main_tabsec .small_responsive_rewards  .nav-tabs{display: block;}
.small_responsive_rewards .filter_tabsec .tab_mobtrigger{display: none;}    
.delivery-inner-comman{display: -webkit-box;display: -ms-flexbox;display: flex;-ms-flex-wrap: wrap;flex-wrap: wrap;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;}
.delivery-item{margin-top: 10px;}    
.delivery_total.delivery_total_number .delivery_total_left h4{font-size: 20px;}
.delivery_total.delivery_total_number .delivery_total_left h2{font-size: 14px;}
.delivery_total_left h3{font-size: 15px;}
.delivery_total_left span{line-height: normal;}
.redeem-item-hea .points{font-size: 14px;}
.pop-whole-lhs{padding: 80px 15px;position: static;-webkit-transform: translateY(-0%); transform: translateY(-0%); }
.pop-whole-lhs-inner{max-width: 100%;margin: 0 auto;}    
.signup-corparatetwo-popup .inside-popup-rhs, .guest-popup .inside-popup-rhs{padding: 50px 20px 15px 20px;}
.inside-popup-rhs{width: 100%;max-width: 100%;margin: 0 auto;float: none;}
.popup_equalrw{display: -webkit-box;display: -ms-flexbox;display: flex;-ms-flex-wrap: wrap;flex-wrap: wrap;}
.pop_title{text-align: center;padding-left: 0;margin: 0 auto 10px auto;}
.pop_title i{position: static;text-align: center;margin: auto;}
.pop_title h2{margin-top: 10px;}
.self_pop_item_loc p{padding: 0 10px; }
.product_chosen_item_left{width: 100%;margin: 10px 0;}
.product_chosen_item_left_second {width: 100%;margin: 0;}
.inn_product_row{margin-top: 20px;}
.inn-product-popup-bg, .inn-product-popup-background{background-image: inherit !important;padding-top: 0;text-align: center;}
.inn-product-popup-inner{max-width: 100%;}
.other-add-row li{width: 100%;}
.account_sec{display: -webkit-box;display: -ms-flexbox;display: flex;-ms-flex-wrap: wrap;flex-wrap: wrap;}
.accmenu_sec{width: 100%;}   
.mainacc_menuout{margin: 0 0 10px; }
.mainacc_menuico{ display: block; position: relative; padding: 9px 0 9px 50px; color: #191919; font-size: 17px; 
cursor: pointer; overflow: hidden; z-index: 1;  }
.mainacc_menuico b{ font-weight: normal;}
.mainacc_menuico:after{content: ""; position: absolute; left: 0; top: -100%; width: 100%; height: 100%; background: #ecdfcd; -webkit-transition: all 0.5s; transition: all 0.5s; z-index: -1;}
.mainacc_menuico.active:after{ top: 0;}
.mainacc_menuico i{ position: absolute; left: 1px; top: 1px; font-size: 18px; width: 40px; height: 40px; line-height: 40px; background: #ecdfcd; text-align: center; -webkit-box-shadow: 0 0 1px  0 #555; box-shadow: 0 0 1px  0 #555;}
.mainacc_menuico i:before{ -webkit-transition: all 0.5s; transition: all 0.5s;	display: block;}
.mainacc_menuico.active i:before{ -webkit-transform: rotate(90deg); transform: rotate(90deg);}
.mainacc_menulist{ display: none;}
.mainacc_menulist > li{ display: block; width: auto; border-left: 0; text-align: left; border-top: 1px solid #ddd1c0;}
.mainacc_menulist > li >a{ text-transform: none; padding: 10px 15px 10px 50px;    background: #ecdfcd; color: #191919;}
.mainacc_menulist > li >a:after{ display: none;}
.mbacc_mslidersec{ display: block; padding: 0; position: relative;}
.mbacc_mslidersec .slick-track .slick-slide > div, .mobile-catering-slider .slick-track .slick-slide > div{ width: 100%; padding:0 1px;}

.mbacc_mslide a, .mobile-catering-slider .slick-track .slick-slide a, .mobile-catgegory-nav .slick-track .slick-slide a{ display: flex; text-align: center; color: #000; font-size: 15px; padding: 12px 10px; background: #efeeee; text-transform: capitalize;font-weight: 500; justify-content: center; align-items: center; line-height: 1.2;}
.mbacc_mslide.active a, .mobile-catering-slider .slick-track .active a, .mobile-catgegory-nav .slick-track .active a{ background: #523628; color: #fff; }
.mbacc_mslidersec .slick-arrow, .mobile-catering-slider .slick-arrow, .mobile-catgegory-nav .slick-arrow{ background: #989898;
    border: 0;
    width: 30px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto 0;
    text-align: center;
    overflow: hidden;
    font-size: 0;
    color: #fff;
    z-index: 1;
    padding: 0;
    min-width: inherit; border-radius: 0; letter-spacing: 0}
.mbacc_mslidersec .slick-next, .mobile-catgegory-nav .slick-next{ right: 0px; left: auto;}
.mbacc_mslidersec .slick-arrow:before, .mobile-catering-slider .slick-arrow:before, .mobile-catgegory-nav .slick-arrow:before{ content: "\f104"; display: inline-block; font: normal normal normal 14px/1 FontAwesome;font-size: inherit;text-rendering: auto;-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale; line-height: 25px; font-size: 24px; }
.mbacc_mslidersec .slick-next:before, .mobile-catering-slider .slick-next:before, .mobile-catgegory-nav .slick-next:before{ content: "\f105";}
.mbacc_mslidersec .slick-arrow:hover, .mobile-catering-slider .slick-arrow:hover, .mobile-catgegory-nav .slick-arrow:hover{ background: #333 }
    
.main_tabsec_inner > .tab-content > .tab-pane.active > .tab_mobrow{ padding-top: 0; margin-top: 0;}
.mainacc_moblink{ background: #b69a6b; padding: 17.5px 45px 17.5px 15px; font-size: 16px;  
color: #fff; display: block; position: relative; margin: 0 0 10px; cursor: pointer; display: block;}
.mainacc_moblink:hover, .mainacc_moblink:focus{ color: #fff;}
.mainacc_moblink i{ position: absolute; width: 45px; height: 25px; line-height: 25px; right: 0; top: 0; bottom: 0; 
margin: auto 0; text-align: center;}
.mainacc_moblink i:after{ content: "\f067"; font: normal normal normal 14px/1 FontAwesome; font-size: inherit;  
text-rendering: auto; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale;}
.mainacc_moblink.active i:after{ content: "\f068";}

.filter_tabsec .tab_mobtrigger.inner_tab_border{background: inherit;border: 0;color: #000;text-align: center;
text-transform: uppercase;}
.myacc_filter{padding-bottom: 0;}
.main_tabsec_inner .nav-tabs{ display: block; border: 0;}
.main_tabsec_inner .tab_mobtrigger{ display: none;}
.main_tabsec_inner> .nav-tabs>li{ margin: 0; padding: 0 2.5px;}
.main_tabsec_inner> .nav-tabs>li> a{ text-align: center; font-size: 16px; height: auto; padding: 13px 15px; 
border-radius: 4px;}
.main_tabsec_inner> .nav-tabs>li> a > span > b{ display: none;}
.main_tabsec_inner> .nav-tabs>li > a > i{ display: none;}
.main_tabsec_inner> .nav-tabs>li > em { display: none;}
.acc-inform .form-group [class*="col-md-"]{ margin-bottom: 11px;}
.acc-inform .form-group [class*="col-md-"]:last-child{ margin-bottom: 0;}
.redeem_pro li h2{font-size: 30px;}
.redeem_pro li h3{font-size: 21px;}
.redeem_pro li{margin: 0 15px;}
.promation_btn li{width: 100%;margin-right: 0;margin-bottom: 5px;}
.promo-earned-content h4{font-size: 20px;}
.promo-earned-content h2{font-size: 26px;}
.promo-earned-content p{font-size: 15px;}
.abc_code span{font-size: 18px;}
.bg_top_slider h3{font-size: 17px;}
.banner-inner h5{font-size: 15px;}
.banner-inner span{font-size: 13px;}
.delivery-inner{padding: 20px 0;}
.tnk-delivery{padding: 15px;}
.chk-payment-btn-row .row{display: block;-ms-flex-wrap: wrap;flex-wrap: wrap;}
.chk-payment-btn-row .con_first{width: 100%;-webkit-box-ordinal-group: 2;-ms-flex-order: 1;order: 1;}
.chk-payment-btn-row .go_second{width: 100%;-webkit-box-ordinal-group: 3;-ms-flex-order: 2;order: 2;}
.delevery_popup .btn_sec .row{display: -webkit-box;display: -ms-flexbox;display: flex;-ms-flex-wrap: wrap;flex-wrap: wrap;}

.back_click{position: static;margin-bottom: 10px;}
/* Thank You */
.tick h2{ font-size: 24px;}
.tnk-detail h2{ font-size: 16px;}
.tnk-order h3{ font-size: 18px;}

/* Contact us */
.contactus_wrapper .row{ display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-orient: vertical; -webkit-box-direction: normal; -ms-flex-direction: column; flex-direction: column;}
.contactus_wrapper .contact_left{ -webkit-box-ordinal-group: 3; -ms-flex-order: 2; order: 2;}
.contactus_wrapper .contact_right{ -webkit-box-ordinal-group: 2; -ms-flex-order: 1; order: 1; margin: 0 0 20px;}
.cms-page h3{ font-size: 22px;}
.award_galinfo h3{font-size: 17px}
/*promo*/
.promation_btn{ text-align: center; }
.promo-earned-col {width: 100%; display: block; margin: 0 0 15px; }    
.redeem_popsec .promo-earned-content{ width: 65%; }
.redeem_popsec .promo-earned-col-center .innerproduct-item-image{ width: 35%;}
.mainacc_toptext h2{margin: 10px 0 0; font-size: 30px}
.count_num_t{display: none}    
.web_order_typecls li a{padding: 13px 10px}    
.web_order_typecls{margin-bottom: 30px}    
.web_order_typecls li.active a, .web_order_typecls li a:hover{ background: #986d4b; }
.web_order_typecls li.active a h5, .web_order_typecls li a:hover h5{ color: #fff; }
.promo-form h3 span{font-size: 16px}    
.promo-form h3{font-size: 24px}    
.mypromo-main-div .myacc_order_details{-webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between}    
.mypromo-main-div .myacc_order_details .promo-earned-col{ width: 49%; margin: 0 0 20px 0 }    
    
.mobile-catering-slider{ display: block !important; padding: 0 40px 0; position: relative}  
.row-pgk-list > li, .pkg_gcol {  width: 50%;}    
.order_nav > .nav-tabs {display: none;}    
.mobile-catering-slider button.slick-arrow{ opacity: 1; width: 40px; height: 52px }     
.mobile-catering-slider button.slick-prev { left: -40px;}
.mobile-catering-slider button.slick-next { right: -40px;left: inherit}    
.mobile-catering-slider .slick-track .slick-slide > div{ padding: 0; }    
.mobile-catering-slider .slick-track .slick-slide a{ padding: 10px; }  
.reservation-full{padding: 25px 0px;}
.back_click{display: none}
.back_click_mbl {  position: absolute; display: block; top: 10px; left: 10px;} 
.back_click_mbl a{color: #fff;}    
.chk-payment .form-group.custom-tarms-div {  padding: 0 5px 20px;}    
.membership_status img{ max-height: 72px; }    
.membership_status span{top: 18px;}  

.hcart_dropdown {width: 80%;}
.mainacc_menuout {width: 100%; position: relative; top: 0px;}
.mainacc_menucontent {width: calc(100% - 0px);}
.f-address {width: 230px; padding-right: 10px;}
.product-menu-left .container{padding: 0}
.contact-all-container{flex-direction: column;	}
.contact-all .contact_form {  width: 100%;	}
.contact-all {  padding: 10px 0 70px;}    
.redeem{width: 100%; order: 1; margin-bottom: 20px}    
.chk-payment-main{width: 100%;order: 2}
.bd-info-merge{margin-bottom: 0}    
}
@media (max-width: 900px){ 
img.blue-round, img.green-round{ margin: 0 auto}    
.fproducts-title-img-inner img{display: none} 
.fproducts-title-img-inner{padding: 0; min-width: inherit;}    
.full-login-rhs { padding: 35px 15px; width: calc( 100% - 330px )}  
.full-login-lhs{width: 320px;}      
.product-menu-left{ display: none}     
.product-lhs{padding: 30px 0 0 0}   
.hcategory_sec{display: block}    
.category-products-main .products-list-ulmain > ul{justify-content: space-between}
.category-products-main .products-list-ulmain > ul li{ width: 48.5%; margin: 0 0 20px 0; }    
}   	

@media (max-width: 850px){    		
.hme-product-inner .title_sec{margin-bottom: 5px;}
.delivery_bg{ background-size: cover; }
.popup_ttsec, .news_postal_popup .popup_ttsec{width: 100%; padding: 30px 15px; height: auto;}
.popup_right{width: 100%; padding: 20px 15px;}
.news_postal_popup .popup_right{width: 100%; height: 300px;}
.awesome_del h2{margin-bottom: 0;}
.title_sec h2{font-size: 29px;}
.title_sec small{font-size: 14px;}
.hme-prt-slidecontent{padding: 10px 15px; top: 0; right: 0; bottom: 0; left: 0;}
.hme-prt-slidecontent-inner p{font-size: 13px;}
.innerproduct-col{width: 48.6%; margin-right: 2.5%;}
.innerproduct-col:nth-of-type(3n+3), .innerproduct-col:nth-of-type(5n+5){margin-right: 2.586206896551724%;}
.innerproduct-col:nth-of-type(2n+2){margin-right: 0;}
.tnk-header{max-width: 100%;}
.tnk-you .container{ padding-left: 0; padding-right: 0;}
.tnk-delivery .cart_footer.tnk_cart_footer{margin-top: 10px;}
.order-cat-table table{max-width: none;}
.ord-amount-item{max-width: 97%;}
.htopinfo p{font-size: 10px;}
.htopinfo p span{ font-size: 8px;}
.htopinfo{width: 100%; text-align: center;}
.htmenu_sec{width: 100%;text-align: center;}
.horder_btn .btn{width: 100%;}
.inn-product-popup{padding: 15px;}
.detail-pg-inner{padding: 50px 10px 0;}	
/*upadate package*/
.number-break .back_bx {left: 15px; width: 55px; height: 60px; font-size: 14px; padding: 40px 10px 10px;}
.number-break .back_bx:after {top: 10px; font-size: 26px;}
.number-break {padding: 15px 15px 15px 5px; margin: 20px 0px 30px;}
.number-break .break-time {	width: 100%; padding: 5px 10px; text-align: left; border-right: 1px solid transparent; 
margin-top: 70px;}
.number-break .break-time .title4 {font-size: 16px; margin: 0 0 10px;}
.number-break .making-catering {width: 100%; padding: 5px 10px; border-right: 1px solid transparent;}
.number-break .event-time {width: 100%; padding: 5px 10px;}
.innerproduct-item-image img{width: 100%}    
.featured-products-main > ul{-webkit-box-pack: justify;-ms-flex-pack: justify;justify-content: space-between;}    
.featured-products-main > ul > li {  width: 48%; margin: 0 0 20px 0 !important;}  
    
}
@media (max-width: 780px){

footer{margin-top: 20px}    
.fproducts-title-img-inner h2 { font-size: 35px;}    
.featuredpro-section{padding-bottom: 30px}   
.home-cafe .container { position: static; transform: none; width: 100%;}
.homecafe-text { max-width: 98%; margin: -30px auto 0 auto; padding: 20px}
.hc-left{width: 100%}    
.hc-right{display: none}    
.hg-bottom ul{flex-wrap: wrap}    
.hg-bottom ul li{margin: 0 0 10px 0; width: 49.5%;}    
.hg-bottom ul li figure img, .hg-middle-inner figure img{width: 100%}    
.f-nav > ul > li{ width: 48%; padding: 0 0 20px 0;}
.f-nav > ul > li ul li{margin-bottom: 10px}    
.copyright-section{margin-top: 10px}
.divide-banner{padding: 10px 0;}    
.parlaxx-img{height: 250px;}
.db-lhs { border-right: 5px solid #f8f7f7;}
.db-rhs { border-left: 5px solid #f8f7f7;}
.location-lhs { width: 100%;  padding: 0 0 20px 0;}    
.location-rhs{width: 100%}       
.birth-lhs, .gr-lhs { width:100%;}
.birth-rhs, .gr-rhs { width:100%;}
    .gr-rhs{text-align: center; padding: 30px 0 0 0;}
    .gr-rhs ul{padding: 0}
    .gr-full{padding: 10px 0 0 0;}
    .gr-rhs h3 br{display: none;}
    .birth-bonus, .greater-reward, .grey-terms{padding: 30px 0}
    .gr-full span { padding: 5px 0 7px 0;}
    .grey-terms h4{padding-bottom: 0}
    .ss-perks ul li { width: 49%; line-height: 1.3;    margin-bottom: 10px;}
    .ss-perks{ padding: 30px 20px 20px 20px }
    .locations-top ul li { width: 100%;  margin: 0 0 30px 0;}
    .join-lhs { width: 100%;}
    .join-rhs { width: 100%;}
    .footer-whatsapp {
        width: 50px;
        height: 50px;       
    }
}
@media (max-width: 768px){
h1{ font-size: 30px}
h2{ font-size: 24px}    
h3{ font-size: 22px;}        
h4{ font-size: 20px;} 
h5 {font-size: 18px;}
h6 {font-size: 16px;}   
.home-outlets-list {padding: 40px 0 40px 0;}        
.hmenu_actions, .htico_cart{position: static}        
.date_list {padding: 20px 15px;}
.time_slot {padding: 20px 15px 20px;}
.add_time {margin: 10px 15px 20px 15px;}
.choose_location {padding: 20px 15px 20px; margin-bottom: 20px;}
.cedele-location {padding: 2px 15px 0px 0px;}
.location-list ul li {font-size: 14px; line-height: 1.3; height: auto; padding: 10px 15px;}
.catering_checout .checkout-total .cdd-details-lhs {padding: 0px 15px 20px 15px; margin: 20px 0 0 0;}
.catering .ttsplitter {margin: 0 auto 10px;}
.catering_checout .checkout-total .checkout-control-group-top {padding: 20px 0px 20px 0px;}
.checkout-total .checkout-control-group-middle {padding: 20px 10px 10px 10px;}
.catering_checout .checkout-total .cdd-details-rhs {padding: 0px 15px 20px 15px; margin: 20px 0 0 0;}
/*-------Categories page--------*/
.number-break .back_bx {left: 15px; width: 55px; height: 60px; font-size: 14px; padding: 40px 10px 10px;}
.number-break .back_bx:after {top: 10px; font-size: 26px;}
.number-break {padding: 15px 15px 15px 5px; margin: 20px 0px 30px;}
.number-break .break-time {	
	width: 100%; padding: 5px 10px; text-align: left; border-right: 1px solid transparent; margin-top: 70px;
}
.number-break .break-time .title4 {font-size: 16px; margin: 0 0 10px;}
.number-break .making-catering {width: 100%; padding: 5px 10px; border-right: 1px solid transparent;}
.number-break .event-time {width: 100%; padding: 5px 10px;}
.event-time .form-control { height: 45px;}
.making-place {font-size: 16px;}
.making-place {font-size: 15px;}
.event-time .title4 {font-size: 16px;}
.pkselected_package .form-group { min-width: auto;}
 
.outletul > li{ width: 100%; margin: 0 0 30px 0;}
.outletul > li:last-child{margin-bottom: 0}
.ourrest_info h4{font-size: 19px}    
.locate-mapleft{ width: 100%;-webkit-box-ordinal-group: 3;-ms-flex-order: 2;order: 2}
.locate-mapright{ width: 100%;-webkit-box-ordinal-group: 2;-ms-flex-order: 1;order: 1}        
.contact_col{ width: 100%; padding: 0;  }    
.contact_form h3{font-size: 22px}   
.inner_banner {  height: 250px;}    
.award_galcol{width: 48%}    
.about_lirow{-ms-flex-wrap: wrap;flex-wrap: wrap; margin: 0 0 20px 0}    
.about_lirow:last-of-type { margin-bottom:0px;}    
.about_liimg, .about_liinfo{ width: 100% }    
.about_liinfo{padding: 30px 20px 20px 20px;}
.brand_note {margin: 0 0 30px;}        
.acc-inform .form-group .row .col-md-6 { width: 100%; margin: 0 ;}    
.myacc_order_details .current_order{width: 100%}    
.web_order_typecls{ margin: 0 0 20px 0 }    
.order_delivery_item{ padding: 10px 20px 0 20px;}    
.checkout-total{margin: 0 auto}    
.hmenu-login-section {position: static;top: inherit; transform: none;}
.birthday-info-div{margin-bottom: 20px;}  
.banner-info p {font-size: 20px;}
.product-lhs { width: 100%; }    
.product-menu-promo{ width: 100%; margin: 20px 0 0 0;}    
 
.featured-products-main .owl-dots, .client_riv_slider .owl-dots { margin: 30px 0 10px 0;}    
.product-category-list p { font-size: 12px;}  
.fproducts-title-img{padding: 0 0 10px 0}    

}
@media (max-width: 767px){   
.htico_cart > a.active {padding: 4px; width: 35px; height: 35px; margin-top: -2px;}
.htico_cart > a.active > i {width: 15px; height: 20px;}
#cart-close-span {width: 25px; height: 25px;}
#cart-close-span:before, #cart-close-span:after {left: 12px;height: 15px;}
.cart_body .hcart_tt h3, .cart_body .hclear_cart {font-size: 15px;}  
.htico_search{ position: static;}
.hsearch_sec{ left: 15px; right: 15px; width: auto;}
.banner .container, .delivery .container, .hme-product .container, .outlet .container{ padding-left:0; padding-right: 0; }
.banner-top-text{ padding: 0 36px; margin-bottom: 25px;}
.bg_top_slider:before{ right: -23px; bottom: -12px; width: 46px; height: 37px; background-size: cover;}
.bg_top_slider:after{ left: -23px; bottom: -12px; width: 46px; height: 37px; background-size: cover;}
.bg_top_slider h3{ font-size: 14px;}
.bg_top_slide p{ font-size: 12px;}
.location-mapsec > div{ height: 350px;}
.main_tabsec .nav-tabs{ margin: 0 0 25px; border-width: 1px;}
.main_tabsec .nav-tabs>li>a{ font-size: 14px; padding: 10px 10px;}
.myacc_filter.small_responsive_row .filter_tabsec .nav-tabs>li>a:after{margin: 0 auto;top: 34px;}
.small_responsive_rewards .myacc_filter .filter_tabsec .nav-tabs>li>a:after{margin: 0 auto;top: 34px;}
.video_popup .youtube_player_iframe{ height: 300px;}
.selfpop_mapouter{ height: 280px !important;}
.chekout_cart_bar .order-detail-inner-icon{margin: 0 auto;}
.redeem-col{padding: 15px 10px;}
.redeem-item-hea h4{font-size: 20px;}
.pop-whole-lhs{padding: 40px 15px;}
.inside-popup-rhs, .signup-popup .inside-popup-rhs, .signup-corparateone-popup .inside-popup-rhs{padding: 15px 20px;}
.signup-corparatetwo-popup .inside-popup-rhs{padding:50px 20px 15px 20px;}
.popup-header{padding: 10px 0 10px 0;}
.guest_chk_out{margin-left: 0;margin-right: 0;}
.guest_chk_out .btn{white-space: inherit;height: auto;}
.pop_title h2, .order-body h2{font-size: 25px;}
.pop_title small{font-size: 17px;}
.self_pop_row{ height: auto;}
.self_outlet{margin-top: 15px; height: auto;}	
.self_pop_col{width: 100%;	margin-bottom: 0;}
.self_pop_col.self_pop_col_right{width: 100%;margin-left: 0;max-width: 100%;}
.self_popup_map_col{ height: auto; margin: 0;}
.self_pop_item_map{width: 100%;	margin: 0 auto; display: none;}
.self_popup .self-back{ display: none;}
.self_outlet_inner{height: 330px;}
.self_pop_item_loc .or-seperator-row{display: none;}
.pouup_in{padding: 30px 20px 20px;}
.redeem_popsec .pouup_in{ padding: 30px 20px 20px;}
.redeem_popsec .title3{ font-size: 20px;}
.hme-product-inner .ttsplitter{margin-bottom: 0;}
.product_chosen_col{width: 100%;margin: 10px 0;}
.product_chosen_col_inner{margin-bottom: 15px;margin-left: 0;margin-right: 0;}
.accsec_right h1{font-size: 24px;}
.accsec_right p{font-size: 14px;}
.accsec_right .ttsplitter_md{margin-top: 20px;}
.redeem_pro li.pro-subscribe button{font-size: 16px;}
.outlet_inner{padding: 23px 20px 20px 20px;}
.breadcrumb_left{width: 100%;display: inline-block;}
.breadcrumb_right{width: 100%;float: none;display: inline-block;margin: 10px 0;}
.breadcrumb_right .chosen_left{ left: 0; right: auto;}
.breadcrumb_right .re_select{max-width: calc(100% - 73px);}
.bakery_inner h3{font-size: 23px;}
label{font-size: 14px;}
.process_right p{font-size: 15px;}
.product_tag{float: none;}
.product_chosen_col .text-box{margin-left: 0;}
.cms-page .container{ padding: 25px 15px;}

/*----Venue Details page----*/	
.myacc_hea h3 {font-size: 20px;}
.ttsplitter span {font-size: 14px;}
.order_nav .tab_mobtrigger {font-size: 14px;}
.choose_location h4 {font-size: 14px;}
.choose_location .custom_radio span {font-size: 14px; line-height: 30px;}
.choose_location .custom_radio span:before {width: 26px; height: 26px;}
.cedele-location .form-group {margin: 20px 0 20px;}
.date_list h3 {font-size: 14px;}
.res-datetime-picker ul li dl dd, .catering_days_ul li dl dd {font-size: 17px;}  
.res-datetime-picker ul li dl.time_inner dd {font-size: 20px;}
.res-datetime-picker ul li dl dt {font-size: 12px;line-height: 1;}
.res-datetime-picker ul li dl.cl-next-days span {font-size: 14px;}
.res-datetime-picker ul li dl.cl-next-days {line-height: 1;}
.time_slot .time {padding: 0px 0px 10px 0px;}
.add_time {margin: 10px 15px 10px 15px;}
.time_slot .order-btn {padding: 20px 0px 15px;}
.time_slot h4 {font-size: 14px;}
.time_slot .order-btn .no {height: 42px; font-size: 20px; padding: 7px 50px; width: 49%;}
.time_slot .order-btn .yes {height: 42px; font-size: 20px; padding: 7px 50px; width: 49%;}
.time_slot .add_time .label {float: none; font-size: 14px; display: block; width: auto; text-align: center}
.time_slot .time label {font-size: 14px;}
.location-list ul li {font-size: 13px;}
/*----Checkout page----*/
.order_details .panel-title>a {font-size: 14px;}
.order_details .panel-body {padding:15px 10px;}
.package .package_cnt {padding: 0px 0px 15px;}
.package .package-list {padding: 0px 0px 10px 0px;}
.checkout_time {padding: 0px 0px 10px;}
.checkout_time label {font-size: 14px;} 
.package .title {font-size: 14px;}
.package .package-list .one {font-size: 14px;}
.package .package-list .two {font-size: 14px;}
.package .qty_bx {margin-top: 10px;}  
.package .amt {margin-top: 15px;}
.amt span {font-size: 14px;}
.addon {padding: 15px 0px 15px 0px;}
.addon h4 {font-size: 14px;}
.addon_list h5 {margin: 10px 0px 15px 0px;}
.addon_list h5 .title {font-size: 14px;}
.addon_list p {margin: 0px 0px 0px 0px; font-size: 14px;}
.total_amt {padding: 15px 15px 10px 15px;}
.total_amt .total {font-size: 20px;}
.total_amt .subtotal {font-size: 14px;}
.buffet_setup {padding: 10px 0px 15px;}
.buffet_setup h5 .title {font-size: 14px;}
.buffet_setup p {font-size: 14px; margin: 0px 0px 7px 0px;}
.download-quote .btn {font-size: 14px;height: 30px;}
.catering_checout .redeem-item-hea h4 {font-size: 18px;}
.catering_checout .redeem-item-hea span {font-size: 14px;}
.catering_checout .redeem-item-hea .points {font-size: 13px;}
.catering_checout .redeem_apply .form-control {height: 40px;}
.catering_checout .focus-out .btn_minwid {min-width: 100px; padding-left: 15px; padding-right: 15px;}
.catering_checout .focus-out .btn {font-size: 14px; height: 40px;}
/*-----Thank You page------*/
.catering_thak_you .package .title {padding-left: 0px;}
.catering_thak_you .order_details .qtybxs {font-size: 14px;line-height: 1.6;}
.catering_thak_you .package .amt {margin-top: 0px;}
.catering_thak_you .addon_list h5 .title {padding-left: 0px;}
.catering_thak_you .addon_list h5 {margin: 10px 0px 5px 0px;}
/*-----Categories------*/
.number-break .back_bx {left: 15px; width: 55px; height: 60px; font-size: 14px; padding: 40px 10px 10px;}
.number-break .back_bx:after {top: 10px; font-size: 26px;}
.number-break {padding: 15px 15px 15px 5px; margin: 20px 0px 30px;}
.number-break .break-time {	
	width: 100%; padding: 5px 10px; text-align: left; border-right: 1px solid transparent; margin-top: 70px;
}
.number-break .break-time .title4 {font-size: 15px; margin: 0px 0px 10px;}
.number-break .making-catering {width: 100%; padding: 5px 10px; border-right: 1px solid transparent;}
.number-break .event-time {width: 100%; padding: 5px 10px;}
.event-time .form-control { height: 45px;}
.making-place {font-size: 14px;}
.event-time .title4 {font-size: 15px;margin-bottom: 7px;}
.title4 {font-size: 14px;}
.bts.text-right {text-align: left;}
.pkselected_filtersec label {font-size: 14px;}
.pkg_gimg img{width:100%;}
.pkg_ginfo h4 {font-size: 16px;margin: 0 0 10px;}
.pkgselect_dd .chosen-container {font-size: 14px;}
.pkgselect_dd .chosen-container-single .chosen-single {font-size: 14px;}
.pkg_gbtsec .btn {font-size: 14px; min-width: 170px;height: 42px;}
.pkg_ginfo .btn {font-size: 14px; padding: 9px 20px 7px;height: 34px;}	
/*Addon*/
.pkgaddon_checklist li {width: 100%; padding: 0px 3px;}
.pkgaddon_checklist .custom_checkbox span {padding: 15px 15px 15px 70px; font-size: 16px;}
/*SETUP*/
.package_controltt .custom_radio span {font-size: 14px;}
.pkgsetup_list .package_formchecklist {padding-left: 0px;margin: 20px 0px 0px;}
.pkgsetup_list .package_formchecklist .custom_checkbox span {font-size: 14px;}
.package_form_helptext {font-size: 14px;}
.package_formchecklist .custom_checkbox span b {font-size: 14px;line-height: 1.5;}
.pkselected_package{margin-bottom: 10px;}	
.pkglist_pricebx > b {font-size: 16px;}
.pkselected_package .chosen-container-single .chosen-single {height: 42px;line-height: 42px;}
/*promo*/
.promation_btn .btn {font-size: 14px; height: 35px; padding: 7px 10px; min-width: 100px; }
.prodet_bansec{ display: block;}
.back_arrow{ width: 43px; height: 43px; line-height: 43px; }
.back_arrow img{ max-width: 11px;}
.prodet_banleft{width: auto; padding: 15px 15px 10px 15px;}
.prodet_baninfo{width: auto;}
.product_modifiers_listinner .modifiers_main_list{ width: 100%;}
.package_checkbx .package_formchecklist .custom_checkbox span{ font-size: 16px}    
.pkglist_img img{ width: 100% }
.package_checkrow {margin-bottom: 0px;}    
.pkg-detail-top-row{ -webkit-box-orient: vertical; -webkit-box-direction: normal; -ms-flex-flow: wrap column; 
flex-flow: wrap column; -webkit-box-align: center; -ms-flex-align: center; align-items: center;}    
.pkselected_filtersec .form-control{ padding-right: 46px }    
.datelist_bracktime h3, h3.choose-eventtype { font-size: 20px;margin: 0 0 10px 0;}    
.pkselected_catgrytext {  text-align: center; margin: 10px 0;}    
.checkout-total .checkout-control-group-bottom textarea{height: 110px}    
footer{ padding: 20px 0 0 0 }    
#CartListMdl .package .package_cnt .col-sm-cls .hr-flt-cls{font-size: 16px}    
#CartListMdl .Cart-list-mdlheader h2, #PackageEditMdl .Cart-list-mdlheader h2 {font-size: 26px; margin: 5px 0 10px 0;}    
.locate-lirow{ display: block; }    
.mfp-close-btn-in .order_popup .mfp-close, .mfp-close-btn-in .awesome_popup .mfp-close, .mfp-close-btn-in .self_popup .mfp-close, .mfp-close-btn-in .delevery_popup .mfp-close, .mfp-close-btn-in .error_postal_popup .mfp-close, .mfp-close-btn-in .receipt_popup .mfp-close, .mfp-close-btn-in .receipt_cateringpopup .mfp-close, .changepw_popup .mfp-close, .redeem_popsec .mfp-close, .pay-conf-popup .mfp-close, .warning_popup .mfp-close, .venue_popup .close-icon-but{ top: 10px; right: 10px; }    
.guest-checkout-popupdiv{margin: 0 -20px;padding: 25px 25px;}    
.login-popup .inside-popup-rhs{padding-bottom: 0}    
.common-inner-banner p{font-size: 40px;}    
.chk-payment { padding: 20px;}  
.inn_product_hea p {margin-bottom: 0px;}
.hcart_dropdown {width: 100%;}
.mypromo-main-div .myacc_order_details .promo-earned-col {width: 100%;}
.mainacc_toptext p {font-size: 16px;}	
.full-login-new-header {padding: 20px 20px;}
.full-login-new-body {padding: 40px 15px;}
.full-login-new-header h3 {font-size: 26px;}
.congrats-inner {padding: 20px 15px;}
.congrats-inner h2 {font-size: 30px;}
.compo_acc_innerdiv {padding: 10px 10px 0px 10px;}
.prodet_baninfo {padding: 0px 0 10px 0;}
.inn_product_hea h4 {font-size: 14px;}
.inn_product_hea_left h3 {font-size: 28px; margin: 0 0 10px 0;}
.inn_product_hea > p.total_price {font-size: 28px;}
.vouchar-popup.redeem_popsec .redeem_bansec .promo-earned-content {padding: 20px 20px 20px;}
.vouchar-popup.redeem_popsec .promo-earned-content h4 {font-size: 16px;}
.vouchar-popup.redeem_popsec .promo-earned-content h3 {font-size: 26px;}
.myvouchar-main-div.mypromo-main-div .myacc_order_details .promo-earned-col {
    width: 50%; padding: 0px 10px;}
.vouchergift-popup .full-login-new-body {padding: 40px 20px 20px; max-width: auto;}
.vouchergift-popup .gift-group {margin-left: 0px; margin-right: 0px;}
.featuredpro-section {padding-bottom: 35px;}
.common-inner-banner{padding: 30px 10px; height: 200px}    
.welcome-lhs p { font-size: 16px; line-height: 1.3;}    
.product-details { padding: 10px 0px;}
    .cart_remove{top: 10px; right: 0}    
}
@media (max-width: 680px){ 
.delivery_bg{ width: 100%;}
.innerproduct-col{width: 100%; margin: 0 0 15px;}
.cart_body{padding: 10px;}
.tnk-delivery .cart_row{padding-top: 5px;}
.cart_remove{top: 10px}
.cart_extrainfo{padding-left: 0;}
.logo{transition: none; -webkit-transition: none;}
.menu_icon{margin-right: 0;}    
.delivery-inner{ padding: 0;}
.delivery-item{	width: 100%; max-width: 100%; padding: 20px 15px 16px;  margin-top: 0; border: 0;
border-bottom: 1px solid #f0e8dd;}
.delivery-item:last-of-type{border-bottom: 0;}
.hme-prt-row .slick-slider, .detail-pg-slider .slick-slider{padding: 0 0px;}
.hme-product-inner .slick-prev, .hme-product-inner .slick-next{z-index: 1; background: #f5f5f5;}
.detail-pg-slider-comman .slick-prev, .detail-pg-slider-comman .slick-next{	z-index: 1;background: #f5f5f5; }
.inn-product-popup-inner{max-width: 100%;}
.setting_menu_list{position: static;}
.accpoints_bxin{padding: 20px 13px 55px;}
.accearn_pointtop span{font-size: 35px;}
.accsec_right .ttsplitter span{z-index: 1;}
.ord-amount .row{width: 100%;}
.ord-rec-sec li{width: 100%; max-width: 100%;}
.ord-amount [class*='col-']{padding-right: 0;}
.ord-amount{padding: 10px 0 0 10px;}
.order-cat-table{padding: 0 10px;}   
.order-cat-hea{max-width: 100%; padding: 8px 10px; margin-top: 10px;}
.ord-amount h2{font-size: 22px;}
.order-cat h2{font-size: 18px;}
.ord-amount-item{max-width: 100%;}
.cur-ord-rec{float: none;  padding: 0 10px;   width: 100%;  max-width: 100%; }
.cur-order-del{ max-width: none; padding: 0 10px; }
.cur-ord-rec li{-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;}
.click_view{text-align: center; margin: auto; padding: 0 10px; left: 0; right: 0;}
.cur-order-del li{margin-bottom: 10px; width: 100%; padding-left: 0; font-size: 19px;}
.cur-order-del li.cur-order-del-time{ width: 45%; text-align: right; margin-bottom: 0;}
.cur-order-del li.cur-order-del-date{ width: 55%; margin-bottom: 0;}
.cur-order-del li a.btn{ display: block;}
.cur-ord-rec-desktop, .click_view_desktop{ display: none;}
.cur-ord-rec-mobile{ position: relative; padding: 0 0 10px; border-bottom: 1px solid #efefee; margin: 10px 0;}
.myacc_dropmenu{position: absolute; width: 100%;left: 0;top: 100%; padding: 15px;}
.myacc_dropmenu::after{top: -16px; -webkit-transform: translateY(-50%); transform: translateY(-50%); left: 33px; -webkit-transform: rotate(448deg); transform: rotate(448deg);}
.check-pg-slider .slick-slider{padding: 0;}
.check_pg_inner .slick-prev, .check_pg_inner .slick-next{background: #f5f5f5;}
  
.checkout-total .checkout-control-group-bottom textarea{height: 100px;}
.hme-product-inner .slick-prev::after, .hme-product-inner .slick-next::after, .prd-sub .slick-prev::after, .prd-sub .slick-next::after,.check_pg_inner .slick-prev::after, .check_pg_inner .slick-next::after,.detail-pg-slider-comman .slick-prev::after, .detail-pg-slider-comman .slick-next::after{width: 24px; height: 24px; top: 17%;}
.hme-product-inner .slick-prev::after, .prd-sub .slick-prev::after,.check_pg_inner .slick-prev::after,.detail-pg-slider-comman .slick-prev::after{background-position: -68px -18px;}
.hme-product-inner .slick-prev:hover::after, .prd-sub .slick-prev:hover::after,.check_pg_inner .slick-prev:hover::after,.detail-pg-slider-comman .slick-prev:hover::after{background-position: -160px -18px;}
.hme-product-inner .slick-prev, .hme-product-inner .slick-next, .prd-sub .slick-next, .prd-sub .slick-prev,.check_pg_inner .slick-next, .check_pg_inner .slick-prev,.detail-pg-slider-comman .slick-next, .detail-pg-slider-comman .slick-prev{
	width: 30px; height: 35px;}
.hme-product-inner .slick-next::after, .prd-sub .slick-next::after,.check_pg_inner .slick-next::after,.detail-pg-slider-comman .slick-next::after{background-position: -20px -18px;}
.hme-product-inner .slick-next:hover::after, .prd-sub .slick-next:hover::after,.check_pg_inner .slick-next:hover::after,.detail-pg-slider-comman .slick-next:hover::after{background-position: -112px -18px;}
.web_order_typecls li a{  padding: 15px 5px;}    
.promo-earned-info{ -webkit-box-orient: vertical;  -webkit-box-direction: normal; -ms-flex-flow: column; flex-flow: column; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; -webkit-box-align: center; -ms-flex-align: center; align-items: center;text-align: center;}    
.promation_btn .button {margin: 5px 5px 0 0; padding: 10px 22px 8px;font-size: 14px;}    
.product-details .row .cart_left{width: 100%; padding: 0}    
.product-details .row .cart_right{width: 100%; padding: 0; -webkit-box-pack: end; -ms-flex-pack: end; 
justify-content: flex-end; -webkit-box-align: center; -ms-flex-align: center; align-items: center; }    
  
.function-rooms ul li{ width: 49% }    
.function-rooms ul li:nth-child(3n){ margin-right:2% }    
.function-rooms ul li:nth-child(2n){ margin-right: 0 }    
a.view-rooms img{ min-height: 190px }    
.home-outlets-list .slick-slider, .addonpro-slider-top .slick-slider { padding: 0;}    
.home-outlets-list .slick-arrow, .addonpro-slider-top .slick-arrow{display: none !important}    
.home-outlets-list .slick-dots {padding: 0;margin: 0;list-style: none;text-align: center;}
.home-outlets-list .slick-dots li {width: 12px; height: 12px; overflow: hidden; background: #000; border-radius: 50%;
display: inline-block; margin: 15px 5px 0; position: relative; text-indent: -99999px;}    
.home-outlets-list .slick-dots li button {font-size: 0; background: none; position: absolute; left: 0;right: 0;top: 0;
bottom: 0;margin: auto; padding: 0;width: 100%; height: 100%;}    
.home-outlets-list .slick-dots li.slick-active{ background: #986d4b }    
.birthday-info-top h3 img{ width: 32px}
.birthday-info-top h3{font-size: 24px}    
.checkout-body-section { padding: 10px;}    
.checkout-right-body-section { padding:10px 10px 10px 10px;}   
.product-details {padding: 10px 0;}
.orderitem_body_div {padding: 10px 5px;}
.cart_footer {margin-left: 15px; margin-right: 15px;}	
.cart_footer {padding: 20px 10px;}
.thank-order-detaildiv .cart_footer {margin-left: 0; margin-right: 0;}	   
.chekout_cart_bar .cart_footer {padding: 25px 10px 10px 10px;}
.tnk-delivery {padding: 15px 10px;}
.mainproducts-title-img-inner h2{font-size: 22px;}    
.welcome-lhs{ width: 100%; padding: 0 0 30px 0}   
.welcome-rhs{width: 100%}
}
@media (max-width: 620px){ 
.row-pgk-list > li, .pkg_gcol {width:100%;}  
.location-details {width: 100%;max-width: 48%;}
.copyright-section p{font-size: 13px}    
.mainproducts-title-img-inner{min-width: inherit;padding: 0}    
.mainproducts-title-img-inner img{display: none}
.full-login-rhs { padding: 30px 15px 20px 15px; width: 100%}  
.full-login-lhs{width: 100%;    padding: 30px 10px 20px; }       
.full-login-lhs img { margin-bottom: 10px; }      
}
@media (max-width: 580px){ 
input[type="reset"], input[type="button"], input[type="submit"], .button, button{letter-spacing: 1px; font-size: 15px;    padding: 10px 16px 10px 16px;}       
.tbars header{ height: 140px;}
.htico_cart{ padding-right: 3px;}	
.mobile_account_item > a{ font-size: 15px;padding: 14px 40px 10px 40px;}
.mobile_account_item > a > i{ width: 20px; height: 20px; }	
.mobile_mainacc_menutrigger, .mobile_account_menu_click i.arrow_myaccount{background-size:16px auto; width:45px; height: 49px;}
.mobile_account_menu_click{ padding: 14px 30px 12px 10px; font-size: 15px;}
.mobile_account_delivery_item a, .mobile_account_item_login > a, .mobile_mainacc_menulist li a{ font-size: 15px;}
.mobile_account_item a.myacunt_act{font-size: 15px;padding: 12px 30px 12px 10px;}
.hcategory_selected_text{ font-size: 16px; padding-right: 45px;}
.hcategory_menulist > li > a{ font-size: 16px;}
.hcategory_submenu >li >a{ font-size: 15px; padding-left: 35px;}
.loc-cont-part{ display: none;}
.chk-payment-col-radio li{margin: 0 0 10px 0;width: 100%;max-width: 95%;}
.chk-payment-col-radio li:last-child{margin-bottom: 0;}
.self-back{top: 5px;font-size: 16px;}
.pop-whole-lhs{padding: 40px 15px 20px;}
.pop-whole-lhs h3, .signup-popup .pop-whole-lhs h3{font-size: 30px;}
.pop-whole-lhs p{font-size: 16px;}
.order_delivery_item li{max-width: 95%;}
.order_delivery_item li:last-child{margin-bottom: 0;}
.order-body{padding: 30px 0;}
.video_popup .order-body{padding: 50px 30px 25px 30px;}
.self_outlet h2{font-size: 21px;}
.self_outlet_inner li a{font-size: 14px;}
.self_pop_item_map{width: 100%;}
.self_pop_item_map img{width: 100%;}
.self_pop_item_loc .loc_btn{height: 52px;font-size: 14px;min-width: 100%;}
.self_pop_item_loc p{font-size: 15px;}
.chosen_adn_left span, .product_chosen_col_right .custom_checkbox span{font-size: 14px;}
.inn_product_hea p{margin-bottom: 10px;font-size: 13px;}
.prd_chosen_sub_item_right button{max-width: 100%;}
.earned_points li h2{font-size: 20px;}
.tag span{font-size: 12px;}
.htmenu_sec > li{padding: 0 15px; }
.megaimg_item{width: 100%;  max-width: 364px;}
.pkselect_tabsec > .nav-tabs>li>a {font-size: 15px; padding: 10px 10px;}
.bts .form-group {width: 100% !important;}
.bts label {margin: 5px 0px 10px; display: block;}
.pkselected_filtersec label { display: block;  width: 100% !important; margin:5px 0px 0px;padding: 0; text-align: center;}
.pkselected_package .form-group { width: 100%;} 
.pkselected_filtersec .time-label {display: block;  width: 100% !important;text-align: left; margin:5px 0px 5px;}
.pkselected_break .form-group {width: 100%;} 
.chk-payment-btn .btn {padding: 10px 22px; height: 37px;font-size: 14px;}
.chk-payment-btn .btn_download {font-size: 14px; padding: 10px 20px 10px;height: 35px;} 
.inner_banner {    height: 200px;}
.mbacc_mslide a{ padding: 15px 10px;    font-size: 16px}
.web_order_typecls li a h5 { font-size: 14px;}
  
.upkg_tabsec .chk-payment-btn .chk-payment-btn-row a{font-size: 17px}
span.myaccount_separate{height: 12px}
/*21-5-19*/
.reservation-summary ul li span{font-size: 13px;}
.reservation-summary{padding: 15px;}
.reservation-summary-full{background: none; padding-top: 0px; padding-bottom: 0px;} 
.reservation-full{padding: 20px 0px;}   
.reservation-full h2{margin-bottom: 15px; font-size: 30px;}
.reservation-summary-full h3{font-size: 28px; margin-bottom: 15px;}
.go-back a, .continue a{font-size: 17px;}
.reservation-summary-button{max-width: 425px; margin-left: auto; margin-right: auto;}
.reservation-counter-full{display: block;}
.reservation-counter-left, .reservation-counter-right{float: none; width: 100%;}
.reservation-counter-single:first-child{margin-top: 30px; margin-bottom: 30px;}
.goto-chkpage-cls a, .viewmenu-btn-row a{ width: 100%; margin: 0}
.goto-chkpage-cls a:first-child, .viewmenu-btn-row a:first-child{ margin-bottom: 5px }    
.popup_ttsec, .news_postal_popup .popup_ttsec{ min-height: inherit}    
.two-button-row{-ms-flex-wrap: wrap;flex-wrap: wrap}    
.delevery_popup .con_first{margin-top: 5px}  

/*30-5-19*/
.reservation-summary-button .go-back, .reservation-summary-button .continue{width: 100%;} 
.reservation-summary-button .continue{margin-bottom: 10px; margin-top: 5px;}
.reservation-summary-button{display: -webkit-box;display: -ms-flexbox;display: flex; -ms-flex-wrap: wrap-reverse;
 flex-wrap: wrap-reverse;}
.common-top-div.productpage-main-div {  padding-top: 155px!important;}    
.hcategory_trigger{ font-size: 14px;}    
.featured-products-main > ul > li .product-title h3, .productlist-main-div .products-list-ulmain li .product-title h3 { font-size: 19px; margin: 0;}   
.left-input-div, .right-input-div {max-width: 48.5%;}  
.prd_chosen_sub_row {padding: 0 0px 20px;} 
.prd_chosen_sub_row{ flex-wrap: wrap}    
p.total_price.product_details_price{width: 100%;margin: 20px 0 10px;text-align: center} 
.prd_chosen_sub_col{width: 100%}    
.prodet_top .slick-track .slick-slide{height: 310px; min-height: 310px}    
.prodet_bansec .prodet_top .slick-slide img{max-height: 300px}    
.product-details-category-section{padding: 5px 0 0 0}    
.add-extra-paired-products{ padding: 20px 10px;}
.add-extra-paired-products h2 { font-size: 18px; margin-bottom: 10px;}    
.add-extra-paired-products{margin-top: 25px}   
.checkout-total .checkout-control-group-top { padding: 5px 0 20px 0;}    
.mfp-container:before{height: auto}  
.redeem-item-hea .points { font-size: 14px; position: static; max-width: inherit; text-align: left; padding: 5px 0 0;}    
.product-title h5{font-size: 18px}    
.innerproduct_row .product-price h3{font-size: 24px}    
.story-top-img { height: auto; width: 100%;}    
.story-top-inner{margin: 0;    padding: 25px 15px;}    
.story-btm .story-top-inner{margin: 0;}    
.story-top-text{width: 100%;}    
.story-middle-btm { padding: 0 0 30px 0;}    
.story-middle-top { padding:40px 0 15px;}    
.story-middle-top-lhs, .story-middle-btm-lhs, .story-middle-top-rhs, .story-middle-btm-rhs {width: 100%;}    
.story-middle-btm-lhs{ margin-bottom: 20px }
.story-top-img { order: 1;}    
.homecafe-text p, .story-top-text p{font-size: 16px; margin-bottom: 0}    
.homecafe-text h3, .story-top-text h3, .innerproduct > h3, .fproducts-title h3, .welcome-lhs h3{font-size: 24px;}
.shp-content h3, .shp-content h4{display: none}
.welcome-banner { padding: 0;}
.promo-banner { margin: 10px 0 30px 0;}
.shop-banner {  margin: 0 0 40px 0;}
.top_header_blk { padding: 5px 10px; font-size: 13px;}
    #mapCanvas .gm-style .gm-style-iw-c{ max-width: 253px!important; }
.location-map-img img{
    width: 250px!important;
    height: 200px!important;
} 
.location-micro .llhs-body ul li {
    padding: 10px;
}
.outlet-details-m ul {
    padding: 20px;
}
.outlet-details-m ul {
    padding: 20px;
}
.location-micro #mapCanvas {
    height: 300px !important;
}
}
@media (max-width: 550px){  
.bg_top_slider h3{ font-size: 12px;}
.bg_top_slide p { font-size: 11px; }
.promo-earned-col-center{ display: block; }
.promo-earned-col-center .innerproduct-item-image{ display: block; width: 100%; height: 200px; }
.promo-earned-content{ display: block; width: 100%; }
.promo-earned-content p {font-size: 13px; }
.redeem_popsec .promo-earned-col-center .innerproduct-item-image{ display: block; width: 100%; height: 200px; }
.redeem_popsec .promo-earned-content{ width: 100%; display: block; }
.redeem_popsec .promo-earned-content h4 {font-size: 20px; }
.redeem_popsec .promo-earned-content h2, .redeem_popsec .promo-earned-content h2 span{
	font-size: 25px; max-height: inherit; overflow: visible;}
.promo-earned-col-center .innerproduct-item-image {display: block; width: 100%; height: 250px; }
.award_galcol{width: 100%; padding: 0;}    
.award_galcol:last-of-type{ margin-bottom: 0;}    
.award_galinfo{margin: 0; top: 70px;}
.hmenu_list{ width: 200%; max-width: inherit}
.mypromo-main-div .myacc_order_details .promo-earned-col { width: 100%;  margin: 0 0 15px 0;}    
.filter_tabsec .nav-tabs>li>a:after{max-width: 50%;}    
.hook{margin-bottom: 30px;} 
.choose-outlet, .special-instructions, .choose-datetime-full{padding-left: 10px; padding-right: 10px;}
.featured-products-main > ul > li{width: 100%; margin: 0 0 20px 0;}   
.product-details .cart_info h4 {font-size: 15px;} 
.cart_footer .cart_row p, .cart_footer span {font-size: 16px;}
.cart_row.grant-total-cls p, .cart_row.grant-total-cls span { font-size: 23px;}    
.tnk-chk-order .button { padding: 15px 10px 15px; font-size: 18px;}      
.redeem_popsec .redeem_bansec .promo-popup-image {width: 100%;}
.redeem_popsec .redeem_bansec .promo-earned-content {width: calc(100% - 0px); padding: 30px 30px 30px;} 
.Viewreceipt .tnk-detail h2 {font-size: 15px;}
.mfp-close-btn-in .order_popup .mfp-close, .mfp-close-btn-in .awesome_popup .mfp-close, .mfp-close-btn-in .self_popup .mfp-close, .mfp-close-btn-in .delevery_popup .mfp-close, .mfp-close-btn-in .error_postal_popup .mfp-close, .mfp-close-btn-in .receipt_popup .mfp-close, .mfp-close-btn-in .receipt_cateringpopup .mfp-close, .changepw_popup .mfp-close, .redeem_popsec .mfp-close, .pay-conf-popup .mfp-close, .warning_popup .mfp-close {    width: 25px; height: 25px; line-height: 25px;} 
.prodet_bansec .slick-slide img {height: 360px;}	
.myvouchar-main-div.mypromo-main-div .myacc_order_details .promo-earned-col {width: 100%;}
.cont_ord .con_ord_txt img {max-height: 50px;}
.cont_ord .con_ord_txt .con_btn a {margin: 5px;}
.f-nav > ul {justify-content: space-between;}
}
@media (max-width: 500px){
.cart_img {width: 85px;}
.cart_info {width: calc(100% - 95px); width: -webkit-calc(100% - 95px);}
.mainacc_menucontent {padding: 20px 10px;}
.myacc_head_sec {padding: 15px 10px;}
.myacc_order_details .head_left h4, .myacc_order_details .head_right h4 {font-size: 15px; word-break: break-all;}
.head_right::before {width: 40%;}
.Viewreceipt .cart_info {width: calc(100% - 0px); width: -webkit-calc(100% - 0px);}
.tnk-delivery .cart_info {width: calc(100% - 0px); width: -webkit-calc(100% - 0px);}
.redeem_apply .btn { position: static; padding: 14px 20px; width: 180px;}    
.redeem_apply .focus-out { padding-right: 0;}    
.syd_date {  width: 100%;}    
.syd_time.delivery_submit_cls { width: 100%;}    
.cart-header-second .syd_date, .cart-header-second .syd_time.delivery_submit_cls {  width: 48%;}       
.delevery_popup_datetime input[type="text"]{margin-bottom: 10px}
.redeem{     padding: 20px 12px;}    
.chk-payment-title { padding-bottom: 20px;}    
.checkout-total .cdd-details-rhs .checkout-right-body-section { padding: 10px 10px 20px 10px;}    
}
@media (max-width: 480px){ 
.featured-products-full .slick-track .slick-slide > div{ width: 100%}
.htico_search> a > i{ width: 24px; height: 24px; background-size: 24px auto;}
.htico_cart> a > i{ width: 24px; height: 24px;}
.innerproduct-row{-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center; text-align: center;}
.innerproduct-item-text{margin-bottom: 15px;}
.spilter{text-align: center; margin: 5px auto; vertical-align: middle;}
.two-links a{ margin: 0 2px; vertical-align: middle;}
.popup-body .sign_reg a{white-space: inherit; height: auto; font-size: 14px;}
.btn{font-size: 14px; padding-top: 15px; padding-bottom: 15px;}
.btn-sm{ padding-top: 10px; padding-bottom: 10px;}
.news_subscribe p{font-size: 14px;}
.pop_title h2{font-size: 26px;}
.news_postal_popup .pop_title p{font-size: 14px; line-height: normal;}
.title1{font-size: 20px;}
.text-box textarea{font-size: 14px;}
.popup_social_icon{width: 100%; padding-left: 0;}
.popup_social_icon li a{padding: 0 8px;}
.popup_social span{color: #fff;}
.order-cat-table h4{font-size: 14px;}
.order-cat-table table td{font-size: 14px;}
.main_tabsec_inner> .nav-tabs>li> a{ font-size: 12px;}
.delivery_time [class*='col-']{width: 100%;}
.delivery_total_left{width: 100%; margin-bottom: 20px;text-align: center;}
.delivery_total_left.delivery_total_right{text-align: center;}
.chosen_adn_left{width: 280px; text-align: left;}
.delivery_total.delivery_total_number{padding-bottom: 0;}
.redeem_apply .btn{ min-width: auto; padding-left: 10px; padding-right: 10px;}
.redeem_apply label{ font-size: 12px; }
.redeem_apply.focused label{ top: -7px;}
.redeem_popsec .btn{ height: 46px; }
.ourrest_infolinks{ -ms-flex-wrap: wrap; flex-wrap: wrap }    
.outletul > li{  margin: 0 0 20px 0;}
.ourrest_info{padding: 20px 20px 110px 20px}    
.ourrest_infolinks li{width: 100%}    
.order_delivery_item li .header-ordernow-single-img img { height: 50px;}    
.order_delivery_item h3{font-size: 14px}    
.featured-products-section{padding-bottom: 20px}    
.web_order_typecls li a h5 { font-size: 13px;}    
.delivery_total_text::before{display: none}    
.catering_days_ul li dl dd, .catering_days_ul li dl.time_inner dd {font-size: 14px;}    
.catering_days_ul li dl dt{font-size: 10px}    
.catering_days_ul li dl.cl-next-days span{font-size: 11px}    
.event-type-maindiv.two-button-row a {  font-size: 16px; padding: 15px 10px;}
.ownvenue-inner-div{max-width: inherit; margin: 20px auto 0}   
.order-select-brktime ul .time-cls{-ms-flex-wrap: wrap;flex-wrap: wrap; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center;}    
.order-select-brktime ul .time-cls label{width: 100%; text-align: center; margin: 0 0 5px 0;}    
.more-time-slots{display: -webkit-box;display: -ms-flexbox;display: flex;}    
.more-time-slots a{ -webkit-box-flex: 1; -ms-flex: 1; flex: 1; padding: 10px 10px 10px; font-size: 16px;}    
.checkout-total .form-group.controls-three > div{ width: 100%; margin: 0 0 10px 0; }    
.checkout-total .form-group.controls-three > div:last-of-type{ margin-bottom: 0; }    
.catering_checout .checkout-total .checkout-control-group-top { padding: 10px 0px 20px 0px;}    
.checkout-total .checkout-control-group-middle .form-group .input_area{ width: 100%;margin-bottom: 10px;}    
.chk-payment-btn-row.checkout-continue-cls .row-replace, #PackageEditMdl .chk-payment-btn-row .row-replace{
	-ms-flex-wrap: wrap;flex-wrap: wrap;
}    
.chk-payment-btn-row.checkout-continue-cls .row-replace .col-md-cls, 
#PackageEditMdl .chk-payment-btn-row .row-replace .col-md-cls{ width: 100% }    
.chk-payment-btn-row.checkout-continue-cls .row-replace .go_second{ margin-top: 5px }    
.chk-payment-btn-row.checkout-continue-cls .row-replace .button{ font-size: 18px; padding: 14px 10px;}
#PackageEditMdl .chk-payment-btn-row .row-replace .col-md-cls:first-of-type{
	-webkit-box-ordinal-group: 3;-ms-flex-order: 2;order: 2; margin-top: 5px;
}   
.catering_days_ul .tag{width: 100%; left:auto; height: 40px; top: 13px;}
.phone-show-dskp{ display: none;}
.phone-show-mbl{display: inline-block;}    
.locate-lirow .phone-show-mbl a{ color: #000; }    
.locate-lirow.active .phone-show-mbl a{ color: #fff; } 
.order_amt h3 {font-size: 18px;}
.promo-earned-col-image {width: 100%; height: auto;}
.promo-earned-info {padding: 20px 10px 10px 10px; width: calc(100% - 0px);}
.promo-earned-info:before {display:none;}
.load_more_div {padding: 0;}
.load_more_div .load_more_data1 {margin-top: 30px;}
.tab-content>.tab-pane {margin: 0;}
.filter_tabsec {margin: 0;}
.productlist-main-div .products-list-ulmain > li{ margin: 0 0 20px 0;}    
.advance-notice {
    font-size:11px;
}
.product-title h5 {
font-size: 16px;
}
.product-tag-list ul li {
    font-size: 10px;
    margin: 0 3px 3px;
}
.product-info-div {
    padding: 10px 5px 5px;
    
}
.product-short-description {
    font-size: 12px;
}
.products-ordernow-action .button {
font-size: 11px;
padding: 8px 10px;
}
.innerproduct_row .product-price h3 {
    font-size: 18px;
}
.product-price {
 margin: 5px 0 10px;
}
 .footer-nav-info{display: none}    
.arrowy { width: 16px;}    
.top_header_center strong{font-size: 18px;margin: 0 5px;}    
.product-description-tab ul li a{font-size: 14px;}  
.inn-product-popup { padding: 10px;}    
.expiry_on_lhs { flex-flow: column; align-items: center;}  
.reward-list-body .checkoutDate, .reward-list-body .checkoutTime{padding: 0}    
.reward-list-body .checkoutDate:before, .reward-list-body .checkoutTime:before{margin-right: 5px; left: 0}    
.reward-list-body .delivery_total_left{margin-bottom: 5px;}
.reward-list-body .delivery_total.delivery_total_number .delivery_total_left h4{font-size: 14px;}
.reward-list-body .checkoutDate:before, .reward-list-body .checkoutTime:before{width: 14px; height: 14px;}
.redeem-item-hea .points{ padding: 0; text-align: left; }
   
.logo img{width: 120px;}    
.container, .home-cafe-container { padding-left: 10px; padding-right: 10px;}     
.productlist-main-div .products-list-ulmain > li.products-single-li{padding-bottom: 100px}    
.product_list_div { padding: 45px 10px 20px;}    
.common-inner-banner p { font-size: 30px;}
    .common-inner-banner{height: 150px}    
.expiry_on_lhs{margin-bottom: 3px}    
.expiry_on_full{text-align: center}  
.full-login-new-body .sign_reg p{line-height: 1}    
.full-login-new-body .custom_checkbox span{top: 1}
.signup-popup .custom_checkbox{ justify-content: start;align-items: flex-start;text-align: left;}    
.hcart_dropdown .cart_remove { top: 13px;}
.full-login-new-body { padding: 20px 15px 15px;}    
.contact_form-right .form_sec .contact_col .col-50 .form-group{	width: 100%;}
.contact_form-right .form_sec .contact_col .col-50 .form-group:first-child{margin-bottom: 0;	}
.contact_form-right .form_sec .contact_col .col-50{flex-direction: column;	}
.contact_form-right textarea.form-control {  height: 105px;	}
.contact-all {  padding: 5px 0 45px;}    

.intro-text h3 {  font-size: 30px; margin: 0px 0px 15px 0px;}    
.intro-text h5{ padding: 0px 0 0px 0;}
.hg-middle-inner {width: 100%;margin-bottom: 10px}    
.hg-middle { margin: 0 0 5px 0;}    
.hg-bottom ul li{width: 100%}    
section.home-cafe { margin: 20px 0 30px;}
.f-nav > ul > li {  width: 100%; padding: 0 0 20px 0;  text-align: center;}
.footer-main .footer-logo img {  width: 140px;}    
.f-nav h5, .footer-logo > a {  margin-bottom: 10px; }
.products-image-div{height: auto }    
.products-image-div{max-height: inherit;}
.hcategory_sec > a{ padding: 10px 10px }
.hcategory_trigger img { width: 30px; margin-left: 10px;}
.product-lhs {  padding: 15px 0 0 0;}
.innerproduct > h4{margin-bottom: 15px;}
.innerproduct > h3{margin-bottom: 10px;}
.ss-perks ul li{ width: 100%; margin-bottom: 5px; }    
    
/* CSS specific to iOS devices only */ 
@supports (-webkit-overflow-scrolling: touch) {
    .hcart_scrollarea { max-height: 100%; padding-bottom: 200px;}
    header .hcart_dropdown .cartaction_bottom{ padding: 10px 0px 120px 0px; background: #fff;}
}
} 
@media (max-width: 460px) {
.compo_acc_innerdiv .chosen_adn > div:first-child {text-align: left;max-width: 50%;}
.compo_acc_innerdiv .combo-inc-parent.chosen_adn_right {max-width: 50%;}
.product-details .row-replace .cart_left {display: flex;flex-flow: column;}
.product-details .row-replace .cart_info {width: 100%;padding-top: 5px;}
.hsearch_sec_mbl { width: 100%;  left: 0; right: 0;}
}
@media (max-width: 420px){
.hcart_tt{ padding-left: 20px;}
.hclear_cart{ font-size: 12px;}
.location-list ul li {font-size: 12px;}
.cedele-location P {font-size: 12px;}
.catering_checout .checkout-total .cdd-details-rhs { padding: 30px 10px 25px 10px;}
.res-datetime-picker .tag {left: -7px;width: 125%;}
.number-break .back_bx {left: 10px; width: 42px; height: 42px; font-size: 12px; padding: 26px 6px 6px;}
.number-break .back_bx:after {top: 6px;font-size: 16px;}
.number-break .break-time {margin-top: 50px;}
.pkselect_tabsec > .nav-tabs>li>a {font-size: 13px; padding: 7px 7px;}
.title4 {font-size: 14px;}
.pkglist_infott h3 {font-size: 16px;}
.pkglist_infott p{font-size: 13px;}
.package_checkbx .package_formchecklist .custom_checkbox span {font-size: 13px;}
.package_checkbx .package_formchecklist .custom_checkbox span b {font-size: 13px;}
.pkglist_info p{font-size: 13px;}
.web_order_typecls li a h5 { font-size: 9px;}    
.main_tabsec .nav-tabs>li>a { font-size: 13px; padding: 5px 6px;}        
.function-rooms ul li{ width: 100%; margin:0 0 10px 0 }    
.function-rooms ul li:nth-child(3n){ margin-right:0% }    
.function-rooms ul li:nth-child(2n){ margin-right: 0 }        
.function-rooms ul li:last-child{ margin-bottom: 0 }    
.function-rooms ul li a{height: auto}    
a.view-rooms img{min-height: inherit; max-height: inherit; height: auto}    
.catering_days_ul li dl.cl-next-days span {font-size: 10px;}    
.catering_days_ul li dl dt { font-size: 9px;}    
.catering_days_ul li dl dd, .catering_days_ul li dl.time_inner dd {font-size: 12px;}    
#PackageEditMdl .pkselected_filtersec .row-replace{	-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-flow: column;flex-flow: column;}  
.catering_days_ul .tag{width: 100%; left:auto; height: 40px; top: 13px;}
.cont_ord .con_ord_txt img {max-height: 35px;}
.checkout-billing-address:not(.send-as-gift) label.chk_hea { flex-flow: column; align-items: flex-start;}    
.checkout-billing-address .chk_hea > span { margin: 10px 0 0 0;}       
.cart-header-first .row-replace .col-sm-cls { width: 100%; text-align: center;}   
.cart-header-first .cart_left.text-left{ padding-bottom: 10px }    
.hcart_add_innr_loca{justify-content: center}    
.cart-header-first{ padding-bottom: 10px}    
.hcart_tt h3{font-size: 20px; }    
.hcart_tt{ padding-top: 10px; padding-bottom: 10px;}
    .db{width: 100%; border: 0; margin-bottom: 10px}    
    .db-rhs{margin-bottom: 0}
    .category-products-main .products-list-ulmain > ul li{width: 100%}
}
@media (max-width: 380px){
.tbars header{ height: 154px;}
.mobile_mainacc_menutrigger{ background-size: 12px auto; width: 32px;}
.mobile_account_item > a > i{ left: 5px; width: 22px; height: 22px;}	
.mobile_mainacc_menulist li a{ padding: 12px 5px 12px 35px; font-size: 14px;}
.mobile_mainacc_menulist .setting_menu_list_icon{ left: 7px;}
.mobile_account_delivery_item a{font-size: 14px; padding: 10px 10px;}    
.mobile_account_menu_click i.arrow_myaccount{ background-size: 12px auto; width: 32px;}
	
.delivery_total_left{float: none;}
.cart_left{width: 100%;}
.cart_right{text-align: left;}
.cart_price{ margin: 5px 0;}
.ttsplitter span{z-index: 1;}
.myacc_filter.small_responsive_row .filter_tabsec .nav-tabs>li>a, .small_responsive_rewards .myacc_filter .filter_tabsec .nav-tabs>li>a{font-size: 14px;}
.mobile-logo-div{ max-width: 160px }
.addon_poptop{padding: 0}    
.addon_popbot{ margin: 0 }    
.addon_popbot .row-replace{ -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center }    
.addon_linfo, .addon_lright, .pkselected_package {width: 100%;}    
#CartListMdl .total_amt { padding: 15px 15px;}    
.number-break { padding: 15px 5px 15px 5px;}    
.number-break .making-catering { padding: 60px 0 0;}   
.number-break .event-time{padding: 5px 0}    
h3.choose-eventtype { padding-left: 20px; }  
.prd_chosen_sub_item_right {width: 80px;} 
.cont_ord .con_ord_txt h3 {margin: 5px 0;}
.cont_ord .con_ord_txt {padding: 5px;}
.cont_ord .con_ord_txt .con_btn a {padding: 10px;}
.myacc_order_details .head_left h4, .myacc_order_details .head_right h4 {font-size: 14px;}
span.spilter { margin: 0px 10px;}    
.controls.two-links a:first-child{width: 50%;}    
.controls.two-links a:last-child { width: 50%;}
.controls.two-links a { font-size: 14px; font-weight: 500; line-height: 1.2;}    
}
@media (max-width: 360px){ 
.reservation-summary ul li span{font-size: 11px;}
.reservation-summary{padding: 10px;}
.reservation-full h2{margin-bottom: 10px; font-size: 30px;}
.reservation-summary-full h3{font-size: 26px; margin-bottom: 12px;}
.head-group {max-width: 150px;}
.head_right::before {width: 48%;}
.prd_chosen_sub_item_left {margin: 0 auto 5px;}
}

.close-icon-but {background: #000;}